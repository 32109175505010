import React from 'react';

import Sidebar from '../../components/Sidebar/Sidebar';
import MainHeaderBar from '../../components/MainHeaderBar/MainHeaderBar';

import './MainLayout.scss';

export default class MainLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className="MainLayout">
        <Sidebar />
        <section className="app contentContainer">
          <MainHeaderBar />
          <div id="appContent" className="app content">
            {children}
          </div>
        </section>
      </div>
    );
  }
}
