import React from 'react';

import {
  Card,
  HeaderBar,
  HeaderInfoTooltip,
  FormSelect,
  FormDate,
} from '../../components/';

import { Button, Icon } from 'semantic-ui-react';
import { format, startOfMonth } from 'date-fns';

import { ErrorHandler, Reports as ReportsAPI } from '../../api';

import './Reports.scss';

function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

const RETRY_LIMIT = 60;
const COOLDOWN = 5000; // 5s * 60 tries = 5mins

export default class Reports extends React.Component {
  retryCount = 0;
  pageFilters = {};
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      selectedType: '',
      endDate: new Date(),
      startDate: startOfMonth(new Date()),
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  _fetch = async () => {
    this.safeSetState({ isLoading: true });

    const { selectedType, startDate, endDate } = this.state;
    let start = format(startDate, 'YYYY-MM-DD');
    let end = format(endDate, 'YYYY-MM-DD');

    let Request;

    switch (selectedType) {
      case 'Notes':
        Request = ReportsAPI.notes;
        break;
      case 'Applications':
        Request = ReportsAPI.applications;
        break;
      case 'Clients':
        Request = ReportsAPI.clients;
        break;
      case 'Households':
        Request = ReportsAPI.households;
        break;
      default:
        Request = () => null;
    }

    let res;
    try {
      res = await Request(start, end);
    } catch (err) {
      ErrorHandler.error(err);
    }
    if (res?.status === 202 && this.retryCount <= RETRY_LIMIT) {
      // we need to retry;
      await sleep(COOLDOWN);
      this.retryCount += 1;
      this._fetch();
      return;
    }

    if (res?.text) {
      const csv = res.text;

      const csvContent = `BPT ${selectedType} Report\n${format(
        new Date(),
        'MMMM Do YYYY h:mma'
      )}\n\n${csv}`;

      const filename = `BPT_${selectedType}_Report_${format(
        startDate,
        'YYYY-MM-DD'
      )}_through_${format(endDate, 'YYYY-MM-DD')}.csv`;

      const blob = new Blob([csvContent], {
        type: 'text/csv',
      });

      if (window.navigator.msSaveOrOpenBlob) {
        // brwoser hack to specify IE and Edge
        window.navigator.msSaveBlob(blob, filename);
      } else {
        // Everything other than IE and Edge

        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob, {
          type: 'text/csv',
        });
        downloadLink.download = filename;
        downloadLink.click();
      }
    }

    this.safeSetState({ isLoading: false });
  };

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  fileNameify = (str) => {
    str = str || '';
    return str.replace(/([^a-z0-9 -]+)/gi, '').replace(/ /g, '_');
  };

  handleSelectType = (e, { value }) => {
    this.safeSetState({ selectedType: value });
  };

  setStartDate = (newStartDate) => {
    this.safeSetState({ startDate: format(newStartDate, 'MM/DD/YYYY') });
  };

  setEndDate = (newEndDate) => {
    this.safeSetState({ endDate: format(newEndDate, 'MM/DD/YYYY') });
  };

  handleFieldChange = (field, value) => {
    this.safeSetState({
      [field]: value,
      [`${field}Error`]: '',
    });
  };

  render() {
    const { isLoading, startDate, endDate, selectedType } = this.state;

    return (
      <div className="Reports">
        <HeaderBar title="Reports" />
        <Card className="header-widget">
          <div className="main-content">
            <FormSelect
              autoFocus
              className="report-type"
              options={[
                { text: 'Applications', value: 'Applications' },
                { text: 'Clients', value: 'Clients' },
                { text: 'Households', value: 'Households' },
                { text: 'Notes', value: 'Notes' },
              ]}
              placeholder="Select a report"
              label="REPORT TYPE"
              onChange={this.handleSelectType}
              value={selectedType}
            />
            <div className="date-pickers">
              <FormDate
                label="START DATE"
                onChange={this.setStartDate}
                value={startDate}
                disabled={false}
              />
              <Icon color="grey" name="arrow right" />
              <FormDate
                alignRight
                label="END DATE"
                onChange={this.setEndDate}
                value={endDate}
                disabled={false}
              />
            </div>
          </div>
          <div className="filter-footer">
            <HeaderInfoTooltip content="Download CSV">
              <Button
                onClick={this._fetch}
                primary
                disabled={isLoading || !selectedType}
                loading={isLoading}
                basic>
                RUN REPORT
              </Button>
            </HeaderInfoTooltip>
          </div>
        </Card>
      </div>
    );
  }
}
