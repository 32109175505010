import React from 'react';

import './FormField.scss';

export const FormField = ({
  title,
  label,
  value,
  error,
  errorMessage,
  disabled = false,
  component,
  className,
  ...otherProps
}) => {
  return (
    <div
      className={`FormField ${className || ''}${error ? ' error' : ''}`}
      {...otherProps}>
      <div className="label">{title || label}</div>
      {disabled ? (
        <div className="value">{value}</div>
      ) : (
        component || <div className="value">{value}</div>
      )}
      {!!errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};

export default FormField;
