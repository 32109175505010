import React from 'react';

import { Checkbox } from 'semantic-ui-react';
import { FormField } from '../../components';

import './FormMultiSelect.scss';

export default class FormMultiSelect extends React.Component {
  handleChange = (e, row) => {
    const { value } = this.props;
    // dont be alarmed. keep value immutable by spreading to get a 'new' array to modify, but also handle a null 'value'
    let selectedOptions = [...(value || [])];
    if (selectedOptions.includes(row.value)) {
      selectedOptions = selectedOptions.filter(v => v !== row.value);
    } else {
      selectedOptions = [...selectedOptions, row.value];
    }

    this.props.onChange(e, { value: [...selectedOptions] });
  };
  render() {
    const { label, options, value, className, ...otherProps } = this.props;

    return (
      <FormField
        {...otherProps}
        className={`FormMultiSelect ${className || ''}`}
        label={label}
        value={
          <div className="multi-select-row">
            {options &&
              options.length > 0 &&
              options.map((row, i) => (
                <Checkbox
                  key={i}
                  label={row.text}
                  onChange={e => this.handleChange(e, row)}
                  checked={
                    value &&
                    typeof value.includes === 'function' &&
                    value.includes(row.value)
                  }
                />
              ))}
          </div>
        }
      />
    );
  }
}
