import React from 'react';

import {
  AppContext,
  FormDate,
  FormInput,
  HeaderBar,
  FormSelect,
  FormTextArea,
  Card,
  FormField,
  DropdownSetting,
  DeleteConfirmModal,
  DropdownNavigator,
} from '../../components/';
import { Button, Checkbox } from 'semantic-ui-react';

import { ErrorHandler, Households, Admin } from '../../api';

import './EditApplication.scss';

const closedStatuses = ['approved', 'suspended', 'denied', 'withdrawal'];

class EditApplication extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    const { household_id, application_id } = this.props.match.params;

    const today = new Date();
    const isAdmin = this.props.user && this.props.user.role === 'Admin';

    this.state = {
      // === route params
      isVisibleDeleteModal: false,
      householdId: household_id,
      applicationId: application_id,
      // === config
      isLoadingTable: false,
      tableData: [],
      isAdmin: isAdmin,
      // === fields
      status: '',
      applicationAidCategory: '',
      applicationRedetermDate: today,
      applicationRetroDate: today,
      applicationAptc: '',
      applicationSepEndDate: today,
      closeOutDate: '',
      applicationCsr: false,
      applicationMpEnrolled: false,
      applicationMpPlanName: '',
      applicationMpPlanId: '',
      applicationMpMoPremium: '',
      denialReasonId: '',
      // -- main section
      applicationDate: today,
      applicationNumber: '',
      dfrFaxDate: '',
      referralCategoryId: '',
      referralSourceId: '',
      appointmentSiteId: '',
      healthPlanId: '',
      citizenshipStatusId: '',
      magiHouseholdSize: '',
      currentProvider: '',
      percentFplId: '',
      hasPrimaryCare: false,
      fastTrackPaymentOption: false,
      fasttrackConfirmationNum: '',
      // -- pregnancy details
      pregnancyStatus: 'Not Pregnant',
      pregnancyDueDate: today,
      pregnancyCurTrimester: '',
      pregnancySawObgyn: '',
      pregnancyScheduledFirstappt: false,
      // -- coverage details
      currentlyInsured: false,
      uninsuredLength: '',
      // -- Incarcerated details
      incarceratedRecently: false,
      incarceratedReleaseDate: today,
      incarceratedFacilityId: '',
      allDocumentsReceived: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoadingTable: true });
    const { householdId, applicationId } = this.state;

    let res;

    try {
      res = await Households.applicationGet({ householdId, applicationId });
    } catch (err) {
      ErrorHandler.error(err);
    }

    let newState = { isLoadingForm: true };
    let extAppLocId = null;
    if (res && res.body) {
      let data = this.snakeToCamel(res.body);
      data.applicationAidCategory = data.aidCategory && data.aidCategory.id;
      data.firstName = data.client && data.client.first_name;
      data.lastName = data.client && data.client.last_name;
      data.pregnancyStatus = data.pregnancyStatus || 'Not Pregnant';
      data.uninsuredLength =
        data.uninsuredLength && typeof data.uninsuredLength === 'string'
          ? parseInt(data.uninsuredLength)
          : data.uninsuredLength;
      data.applicantName = [data.firstName, data.lastName]
        .filter((f) => !!f)
        .join(' ');
      newState = { isLoadingForm: true, ...data };
      extAppLocId = data.applicationExternalLocationId;
    }

    if (extAppLocId) {
      try {
        const locSettings = await Admin.getSetting({ settingId: 15 });
        if (locSettings?.body?.setting_options?.length) {
          const selectedLocation = locSettings.body.setting_options.find(
            (s) => s.id === extAppLocId
          );
          if (selectedLocation?.value) {
            newState.extAppLocation = selectedLocation.value;
          }
        }
      } catch (err) {
        ErrorHandler.error(err);
      }
    }

    this.safeSetState(newState);
  };

  /* takes an object with keys in snake case, and returns the same object with camel case keys */
  snakeToCamel = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach((k) => {
      let newKey = k;
      if (newKey.indexOf('_') !== -1) {
        newKey = newKey.split('_');
        newKey = newKey
          .map((word, i) => {
            if (i > 0 && word.length > 0) {
              word = `${word.substr(0, 1).toUpperCase()}${word.substr(1)}`;
            }
            return word;
          })
          .join('');
      }
      newObj[newKey] = obj[k];
    });
    return newObj;
  };

  validateForm = () => {
    const { status, incarceratedRecently, currentlyInsured } = this.state;

    const requiredFields = [
      'applicationDate',
      'referralCategoryId',
      'referralSourceId',
      'appointmentSiteId',
      'citizenshipStatusId',
      'navigatorId',
    ];

    if (incarceratedRecently) {
      requiredFields.push('incarceratedReleaseDate');
      requiredFields.push('incarceratedFacilityId');
    }

    if (closedStatuses.includes(status)) {
      requiredFields.push('closeOutDate');
    }

    if (currentlyInsured === true) {
      requiredFields.push('currentProvider');
    }
    let isFormValid = true;
    let newState = { isFormValid: false };
    requiredFields.forEach((field) => {
      if (!this.state[field]) {
        newState[`${field}Error`] = 'This field is required';
        isFormValid = false;
      }
    });
    if (!isFormValid) {
      this.safeSetState(newState);
    }
    return isFormValid;
  };

  handleTicketSelect = (row) => {
    this.props.history.push(`/households/${row.id}`);
  };

  handleAddHousehold = () => {
    this.props.history.push('/households/add');
  };

  handleFieldChange = (field, value) => {
    this.safeSetState({
      [field]: value,
      [`${field}Error`]: '',
    });
  };

  showDeleteModal = () => this.safeSetState({ isVisibleDeleteModal: true });
  hideDeleteModal = () => this.safeSetState({ isVisibleDeleteModal: false });

  handleDelete = async () => {
    const { householdId, applicationId, firstName, lastName } = this.state;

    let res;
    try {
      res = await Households.applicationRemove({ householdId, applicationId });
    } catch (err) {
      ErrorHandler.error(err);
      this.props.addToast({
        type: 'error',
        message:
          'Application could not be deleted due to an error. Please try again.',
      });
    }

    if (res) {
      this.props.addToast({
        type: 'success',
        message: `The Application for ${firstName || ''} ${lastName ||
          ''} was deleted successfully!`,
      });
      this.props.history.push(`/households/${householdId}`);
    }
  };

  handleUpdateClick = async () => {
    const { householdId, applicationId } = this.state;
    const {
      // === fields
      status,
      applicationAidCategory,
      applicationRedetermDate,
      applicationRetroDate,
      applicationAptc,
      applicationSepEndDate,
      closeOutDate,
      applicationCsr,
      applicationMpEnrolled,
      applicationMpPlanName,
      applicationMpPlanId,
      applicationMpMoPremium,
      denialReasonId,
      // -- main section
      applicationDate,
      applicationNumber,
      dfrFaxDate,
      referralCategoryId,
      referralSourceId,
      appointmentSiteId,
      healthPlanId,
      citizenshipStatusId,
      magiHouseholdSize,
      currentProvider,
      percentFplId,
      hasPrimaryCare,
      fastTrackPaymentOption,
      fasttrackConfirmationNum,
      // -- pregnancy details
      pregnancyStatus,
      pregnancyDueDate,
      pregnancyCurTrimester,
      pregnancySawObgyn,
      pregnancyScheduledFirstappt,
      // -- coverage details
      currentlyInsured,
      uninsuredLength,
      // -- Incarcerated details
      incarceratedRecently,
      incarceratedReleaseDate,
      incarceratedFacilityId,
      applicationCategory,
      applicationType,
      allDocumentsReceived,
      missingDocuments,
      //
      navigatorId,
    } = this.state;

    let payload = {
      application_category: applicationCategory,
      application_type: applicationType,
      application_date: applicationDate,
      application_number: applicationNumber,
      dfr_fax_date: dfrFaxDate,
      referral_category_id: referralCategoryId,
      referral_source_id: referralSourceId,
      appointment_site_id: appointmentSiteId,
      health_plan_id: healthPlanId,
      citizenship_status_id: citizenshipStatusId,
      percent_fpl_id: percentFplId,
      magi_household_size: magiHouseholdSize,
      current_provider: currentProvider,
      has_primary_care: hasPrimaryCare,
      fast_track_payment_option: fastTrackPaymentOption,
      fasttrack_confirmation_num: fasttrackConfirmationNum,
      currently_insured: currentlyInsured,
      pregnancy_status: pregnancyStatus,
      pregnancy_scheduled_firstappt: pregnancyScheduledFirstappt,
      incarcerated_recently: incarceratedRecently,
      all_documents_received: allDocumentsReceived,
      missing_documents: missingDocuments,
      status: status,
      close_out_date: closeOutDate,
      navigator_id: navigatorId,
    };
    if (status === 'approved') {
      if (applicationCategory === 'MP') {
        payload = {
          ...payload,
          application_aptc: applicationAptc,
          application_csr: applicationCsr,
          application_sep_end_date: applicationSepEndDate,
          application_mp_enrolled: applicationMpEnrolled,
        };
        if (applicationMpEnrolled) {
          payload = {
            ...payload,
            application_mp_plan_name: applicationMpPlanName,
            application_mp_plan_id: applicationMpPlanId,
            application_mp_mo_premium: applicationMpMoPremium,
          };
        }
      } else {
        payload = {
          ...payload,
          aid_category_id: applicationAidCategory,
          application_redeterm_date: applicationRedetermDate,
          application_retro_date: applicationRetroDate,
        };
      }
    } else {
      payload = { ...payload, denial_reason_id: denialReasonId };
    }

    if (pregnancyStatus !== 'Not Pregnant') {
      payload = {
        ...payload,
        pregnancy_due_date: pregnancyDueDate,
        pregnancy_cur_trimester: pregnancyCurTrimester,
        pregnancy_saw_obgyn: pregnancySawObgyn,
      };
    }

    if (!currentlyInsured) {
      payload = { ...payload, uninsured_length: uninsuredLength };
    }

    if (incarceratedRecently) {
      payload = {
        ...payload,
        incarcerated_release_date: incarceratedReleaseDate,
        incarcerated_facility_id: incarceratedFacilityId,
      };
    }

    const shouldSubmit = this.validateForm();
    if (!shouldSubmit) {
      this.safeSetState({ isLoading: false });
      this.props.addToast({
        type: 'error',
        message: 'Please fill in the required fields.',
      });
      return;
    }
    this.safeSetState({ isLoading: true });

    let res;
    try {
      res = await Households.applicationEdit({
        householdId,
        payload,
        applicationId,
      });
    } catch (err) {
      ErrorHandler.error(err);
      this.props.addToast({
        type: 'error',
        message:
          'Application updates could not be saved due to an error. Please try again.',
      });
    }
    if (res) {
      this.props.addToast({
        type: 'success',
        message: 'Application updated successfully!',
      });
      this.props.history.push({
        pathname: `/households/${this.state.householdId}`,
        state: { activeIndex: 1 },
      });
    }
    this.safeSetState({ isLoading: false });
  };

  handleBackClick = () =>
    this.props.history.push({
      pathname: `/households/${this.state.householdId}`,
      state: { activeIndex: 1 },
    });

  handleAllDocsToggle = () => {
    this.setState((prevState) => ({
      ...prevState,
      allDocumentsReceived: !prevState.allDocumentsReceived,
    }));
  };

  render() {
    const {
      extAppLocation,
      applicantName,
      isAdmin,
      firstName,
      lastName,
      isLoading,
      isVisibleDeleteModal,
      // === fields
      status,
      applicationAidCategory,
      applicationRedetermDate,
      applicationRetroDate,
      applicationAptc,
      applicationSepEndDate,
      closeOutDate,
      applicationCsr,
      applicationMpEnrolled,
      applicationMpPlanName,
      applicationMpPlanId,
      applicationMpMoPremium,
      denialReasonId,
      // -- main section
      applicationDate,
      applicationDateError,
      closeOutDateError,
      currentProviderError,
      applicationNumber,
      applicationCategory,
      applicationType,
      dfrFaxDate,
      referralCategoryId,
      referralCategoryIdError,
      referralSourceId,
      referralSourceIdError,
      appointmentSiteId,
      appointmentSiteIdError,
      healthPlanId,
      citizenshipStatusId,
      citizenshipStatusIdError,
      magiHouseholdSize,
      currentProvider,
      percentFplId,
      hasPrimaryCare,
      fastTrackPaymentOption,
      fasttrackConfirmationNum,
      // -- pregnancy details
      pregnancyStatus,
      pregnancyDueDate,
      pregnancyCurTrimester,
      pregnancySawObgyn,
      pregnancyScheduledFirstappt,
      // -- coverage details
      currentlyInsured,
      uninsuredLength,
      // -- Incarcerated details
      incarceratedRecently,
      incarceratedReleaseDate,
      incarceratedReleaseDateError,
      incarceratedFacilityId,
      incarceratedFacilityIdError,
      missingDocuments,
      allDocumentsReceived,
      // Other Stuff
      navigatorId,
      navigatorIdError,
    } = this.state;

    const optionsApplicationStatus = [
      { text: 'Incomplete', value: 'incomplete' },
      { text: 'Suspended', value: 'suspended' },
      { text: 'Pending', value: 'pending' },
      { text: 'Approved', value: 'approved' },
      { text: 'Denied', value: 'denied' },
      { text: 'Withdrawal', value: 'withdrawal' },
      { text: 'UnKnown', value: 'unknown' },
    ];

    const optionsPregnancyStatus = [
      { text: 'Miscarried (3 months)', value: 'Miscarried (3 months)' },
      { text: 'Not Pregnant', value: 'Not Pregnant' },
      { text: 'Pregnant', value: 'Pregnant' },
      {
        text: 'Recently Delivered (3 months)',
        value: 'Recently Delivered (3 months)',
      },
    ];

    const optionsPregnancyTrimester = [
      { text: '1st', value: '1st' },
      { text: '2nd', value: '2nd' },
      { text: '3rd', value: '3rd' },
    ];

    const optionsSawObgyn = [
      { text: '1st', value: '1st' },
      { text: '2nd', value: '2nd' },
      { text: '3rd', value: '3rd' },
      { text: 'Not Seen', value: 'Not Seen' },
    ];

    return (
      <div className="EditApplication">
        <DeleteConfirmModal
          isVisible={isVisibleDeleteModal}
          onClose={this.hideDeleteModal}
          onDelete={this.handleDelete}
          message={
            <p>
              <strong>
                You are about to delete an Application for {firstName || ''}{' '}
                {lastName || ''}.{' '}
              </strong>
              Please confirm your request.
            </p>
          }
        />
        <HeaderBar
          title={`Application ${applicationNumber || ''} - ${applicantName ||
            ''}`}
          actions={
            <React.Fragment>
              <Button className="gray ghost" onClick={this.handleBackClick}>
                BACK
              </Button>
              <Button
                className="primary"
                loading={isLoading}
                onClick={this.handleUpdateClick}>
                UPDATE
              </Button>
            </React.Fragment>
          }
        />
        <div className="column col-1">
          <Card>
            <FormField autoFocus label="APPLICANT" value={applicantName} />
            <FormField
              label="APPLICATION CATEGORY"
              value={applicationCategory}
            />
            <FormField label="TYPE" value={applicationType} />
            {(applicationType === 'new-external' ||
              applicationType === 'redeterm-external') && (
              <FormField label="EXT APP LOCATION" value={extAppLocation} />
            )}
          </Card>
          <Card>
            <div className="form-row">
              <FormSelect
                fluid
                label="APPLICATION STATUS"
                options={optionsApplicationStatus}
                value={status}
                onChange={(e, { value }) =>
                  this.handleFieldChange('status', value)
                }
              />
            </div>
            {closedStatuses.includes(status) && (
              <div className="form-row">
                <FormDate
                  required
                  fluid
                  label="CLOSE OUT DATE"
                  error={closeOutDateError}
                  errorMessage={closeOutDateError}
                  value={closeOutDate}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('closeOutDate', value)
                  }
                />
              </div>
            )}
            {status === 'approved' && applicationCategory !== 'MP' && (
              <React.Fragment>
                {!(applicationCategory === 'PEPW') && (
                  <div className="form-row">
                    <DropdownSetting
                      fluid
                      settingId={17}
                      label="AID CATEGORY"
                      value={applicationAidCategory}
                      onChange={(e, { value }) =>
                        this.handleFieldChange('applicationAidCategory', value)
                      }
                    />
                  </div>
                )}
                <div className="form-row">
                  <FormDate
                    allowFuture
                    fluid
                    label="REDETERM DATE"
                    value={applicationRedetermDate}
                    onChange={(e, { value }) =>
                      this.handleFieldChange('applicationRedetermDate', value)
                    }
                  />
                </div>
                <div className="form-row">
                  <FormDate
                    allowFuture
                    fluid
                    label="RETRO COVERAGE DATE"
                    value={applicationRetroDate}
                    onChange={(e, { value }) =>
                      this.handleFieldChange('applicationRetroDate', value)
                    }
                  />
                </div>
              </React.Fragment>
            )}
            {status === 'approved' && applicationCategory === 'MP' && (
              <React.Fragment>
                <div className="form-row">
                  <FormInput
                    fluid
                    label="APTC"
                    value={applicationAptc}
                    onChange={(e, { value }) =>
                      this.handleFieldChange('applicationAptc', value)
                    }
                  />
                </div>
                <div className="form-row">
                  <FormDate
                    allowFuture
                    fluid
                    label="SEP END DATE"
                    value={applicationSepEndDate}
                    onChange={(e, { value }) =>
                      this.handleFieldChange('applicationSepEndDate', value)
                    }
                  />
                </div>
                <div className="form-row">
                  <Checkbox
                    label="CSR"
                    checked={!!applicationCsr}
                    onChange={() =>
                      this.handleFieldChange('applicationCsr', !applicationCsr)
                    }
                  />
                </div>
                <div className="form-row">
                  <Checkbox
                    label="ENROLLED"
                    checked={!!applicationMpEnrolled}
                    onChange={() =>
                      this.handleFieldChange(
                        'applicationMpEnrolled',
                        !applicationMpEnrolled
                      )
                    }
                  />
                </div>
                {applicationMpEnrolled && (
                  <React.Fragment>
                    <div className="form-row">
                      <FormInput
                        fluid
                        label="PLAN NAME"
                        value={applicationMpPlanName}
                        onChange={(e, { value }) =>
                          this.handleFieldChange('applicationMpPlanName', value)
                        }
                      />
                    </div>
                    <div className="form-row">
                      <FormInput
                        fluid
                        label="PLAN ID"
                        value={applicationMpPlanId}
                        onChange={(e, { value }) =>
                          this.handleFieldChange('applicationMpPlanId', value)
                        }
                      />
                    </div>
                    <div className="form-row">
                      <FormInput
                        fluid
                        label="MONTHLY PREMIUM"
                        value={applicationMpMoPremium}
                        onChange={(e, { value }) =>
                          this.handleFieldChange(
                            'applicationMpMoPremium',
                            value
                          )
                        }
                      />
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {status === 'denied' && (
              <div className="form-row">
                <DropdownSetting
                  fluid
                  settingId={18}
                  label="DENIAL REASON"
                  value={denialReasonId}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('denialReasonId', value)
                  }
                />
              </div>
            )}
          </Card>
          <Card>
            <div className="form-row">
              <FormTextArea
                fluid
                label="MISSING DOCUMENTS"
                rows={6}
                value={missingDocuments}
                onChange={(e, { value }) =>
                  this.handleFieldChange('missingDocuments', value)
                }
              />
            </div>
            <div className="form-row">
              <Checkbox
                label="All Documents Received"
                checked={!!allDocumentsReceived}
                onChange={this.handleAllDocsToggle}
              />
            </div>
          </Card>
          {!!isAdmin && (
            <Button
              fluid
              className="ghost danger"
              onClick={this.showDeleteModal}>
              DELETE APPLICATION
            </Button>
          )}
        </div>
        <div className="column col-2">
          <Card>
            <h2>General Details</h2>
            <div className="form-row">
              <FormDate
                autoFocus
                className="half-width"
                allowFuture
                label="DATE OF APPLICATION"
                value={applicationDate}
                error={applicationDateError}
                errorMessage={applicationDateError}
                onChange={(e, { value }) =>
                  this.handleFieldChange('applicationDate', value)
                }
              />
              {applicationCategory === 'MP' && (
                <FormInput
                  className="half-width"
                  label="APPLICATION NUMBER"
                  value={applicationNumber}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('applicationNumber', value)
                  }
                />
              )}
              <DropdownNavigator
                error={navigatorIdError}
                errorMessage={navigatorIdError}
                className="half-width"
                required
                label="NAVIGATOR"
                value={navigatorId}
                onChange={(e, { value }) =>
                  this.handleFieldChange('navigatorId', value)
                }
              />
            </div>
            {applicationCategory !== 'MP' && applicationCategory !== 'PEPW' && (
              <div className="form-row">
                <FormDate
                  allowFuture
                  className="half-width"
                  label="DFR FAX DATE"
                  value={dfrFaxDate}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('dfrFaxDate', value)
                  }
                />
                <FormInput
                  className="half-width"
                  label="APPLICATION NUMBER"
                  value={applicationNumber}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('applicationNumber', value)
                  }
                />
              </div>
            )}
            <div className="form-row">
              <DropdownSetting
                className="half-width"
                settingId={6}
                label="REFERRAL CATEGORY"
                value={referralCategoryId}
                error={referralCategoryIdError}
                errorMessage={referralCategoryIdError}
                onChange={(e, { value }) =>
                  this.handleFieldChange('referralCategoryId', value)
                }
              />
              <DropdownSetting
                className="half-width"
                settingId={7}
                label="REFERRAL SOURCE"
                value={referralSourceId}
                error={referralSourceIdError}
                errorMessage={referralSourceIdError}
                onChange={(e, { value }) =>
                  this.handleFieldChange('referralSourceId', value)
                }
              />
            </div>
            <div className="form-row">
              <DropdownSetting
                className="half-width"
                settingId={8}
                label="APPOINTMENT SITE"
                value={appointmentSiteId}
                error={appointmentSiteIdError}
                errorMessage={appointmentSiteIdError}
                onChange={(e, { value }) =>
                  this.handleFieldChange('appointmentSiteId', value)
                }
              />
              {!(applicationCategory === 'PEPW') && (
                <DropdownSetting
                  className="half-width"
                  settingId={9}
                  label="HEALTH PLAN"
                  value={healthPlanId}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('healthPlanId', value)
                  }
                />
              )}
              {applicationCategory === 'PEPW' && (
                <DropdownSetting
                  settingId={10}
                  className="half-width"
                  label="CITIZENSHIP STATUS"
                  value={citizenshipStatusId}
                  error={citizenshipStatusIdError}
                  errorMessage={citizenshipStatusIdError}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('citizenshipStatusId', value)
                  }
                />
              )}
            </div>
            <div className="form-row">
              {!(applicationCategory === 'PEPW') && (
                <DropdownSetting
                  settingId={10}
                  className="third-width"
                  label="CITIZENSHIP STATUS"
                  value={citizenshipStatusId}
                  error={citizenshipStatusIdError}
                  errorMessage={citizenshipStatusIdError}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('citizenshipStatusId', value)
                  }
                />
              )}
              <FormInput
                className={
                  applicationCategory === 'PEPW' ? 'half-width' : 'third-width'
                }
                label="MAGI HOUSEHOLD SIZE"
                value={magiHouseholdSize}
                onChange={(e, { value }) =>
                  this.handleFieldChange('magiHouseholdSize', value)
                }
              />
              <DropdownSetting
                className={
                  applicationCategory === 'PEPW' ? 'half-width' : 'third-width'
                }
                settingId={11}
                label="% FPL"
                value={percentFplId}
                onChange={(e, { value }) =>
                  this.handleFieldChange('percentFplId', value)
                }
              />
            </div>
            <div className="form-row">
              <FormSelect
                className="half-width"
                label="HAS PRIMARY CARE PROVIDER"
                value={hasPrimaryCare}
                options={[
                  { text: 'Yes', value: true },
                  { text: 'No', value: false },
                ]}
                onChange={(e, { value }) =>
                  this.handleFieldChange('hasPrimaryCare', value)
                }
              />
              {applicationCategory === 'HIP' && (
                <FormSelect
                  className="half-width"
                  label="Fast-Track Payment Option"
                  value={fastTrackPaymentOption}
                  options={[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('fastTrackPaymentOption', value)
                  }
                />
              )}
              {fastTrackPaymentOption && (
                <FormInput
                  className="half-width"
                  label="FAST-TRACK PAYMENT CONFIRMATION NUMBER"
                  value={fasttrackConfirmationNum}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('fasttrackConfirmationNum', value)
                  }
                />
              )}
            </div>
            <h2>Pregnancy Details</h2>
            <div className="form-row float-left">
              <FormSelect
                label="PREGNANCY STATUS"
                value={pregnancyStatus}
                options={optionsPregnancyStatus}
                onChange={(e, { value }) =>
                  this.handleFieldChange('pregnancyStatus', value)
                }
              />
              {!!pregnancyStatus && pregnancyStatus !== 'Not Pregnant' && (
                <FormDate
                  allowFuture
                  label="DUE/PREGNANCY END"
                  value={pregnancyDueDate}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('pregnancyDueDate', value)
                  }
                />
              )}
            </div>
            <div className="form-row">
              {!!pregnancyStatus && pregnancyStatus !== 'Not Pregnant' && (
                <FormSelect
                  className="third-width"
                  label="CURRENT TRIMESTER"
                  options={optionsPregnancyTrimester}
                  value={pregnancyCurTrimester}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('pregnancyCurTrimester', value)
                  }
                />
              )}
              {!!pregnancyStatus && pregnancyStatus !== 'Not Pregnant' && (
                <FormSelect
                  className="third-width"
                  label="TRIMESTER SAW OBGYN"
                  value={pregnancySawObgyn}
                  options={optionsSawObgyn}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('pregnancySawObgyn', value)
                  }
                />
              )}
              {!!pregnancyStatus && pregnancyStatus !== 'Not Pregnant' && (
                <FormSelect
                  label="SCHEDULED FIRST APPOINTMENT"
                  className="third-width"
                  value={pregnancyScheduledFirstappt}
                  options={[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('pregnancyScheduledFirstappt', value)
                  }
                />
              )}
            </div>
            <h2>Current Coverage Details</h2>
            <div className="form-row float-left">
              {
                <FormSelect
                  label="CURRENTLY INSURED"
                  value={currentlyInsured}
                  options={[
                    { text: 'Yes', value: true },
                    { text: 'No', value: false },
                  ]}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('currentlyInsured', value)
                  }
                />
              }
              {currentlyInsured === false && (
                <DropdownSetting
                  settingId={12}
                  label="LENGTH OF TIME UNINSURED"
                  value={uninsuredLength}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('uninsuredLength', value)
                  }
                />
              )}
              {currentlyInsured === true && (
                <FormInput
                  required
                  className="third-width"
                  label="CURRENT PROVIDER"
                  value={currentProvider}
                  error={currentProviderError}
                  errorMessage={currentProviderError}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('currentProvider', value)
                  }
                />
              )}
            </div>
            <h2>Incarceration Details</h2>
            <div className="form-row float-left">
              <FormSelect
                label="RECENTLY INCARCERATED (LAST 90 DAYS)"
                value={incarceratedRecently}
                options={[
                  { text: 'Yes', value: true },
                  { text: 'No', value: false },
                ]}
                onChange={(e, { value }) =>
                  this.handleFieldChange('incarceratedRecently', value)
                }
              />
              {incarceratedRecently && (
                <React.Fragment>
                  <FormDate
                    allowFuture
                    label="RELEASE DATE"
                    value={incarceratedReleaseDate}
                    error={incarceratedReleaseDateError}
                    errorMessage={incarceratedReleaseDateError}
                    onChange={(e, { value }) =>
                      this.handleFieldChange('incarceratedReleaseDate', value)
                    }
                  />
                  <DropdownSetting
                    settingId={14}
                    label="NAME OF FACILITY"
                    value={incarceratedFacilityId}
                    error={incarceratedFacilityIdError}
                    errorMessage={incarceratedFacilityIdError}
                    onChange={(e, { value }) =>
                      this.handleFieldChange('incarceratedFacilityId', value)
                    }
                  />
                </React.Fragment>
              )}
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const WrappedEditApplication = (props) => (
  <AppContext.Consumer>
    {({ user, addToast }) => (
      <EditApplication {...props} addToast={addToast} user={user} />
    )}
  </AppContext.Consumer>
);

export default WrappedEditApplication;
