import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

import './Card.scss';

const Card = ({
  title,
  subtitle,
  headerActions,
  className,
  isLoading,
  bodyStyle,
  children,
  footer,
  style,
  emptyStateMessage = 'No data found.',
  isEmpty,
}) => {
  return (
    <div className={`Card ${className ? className : ''}`} style={{ ...style }}>
      {title || headerActions ? (
        <header>
          {title && (
            <h2>
              {title} {subtitle && <small>{subtitle}</small>}
            </h2>
          )}
          {headerActions && (
            <div className="headerActions">{headerActions}</div>
          )}
        </header>
      ) : null}

      <div className="cardBody" style={bodyStyle}>
        {isLoading ? (
          <Loader
            style={{
              position: 'relative',
              top: 'initial',
              left: 'initial',
              transform: 'none',
              width: '100%',
            }}
            active
          />
        ) : isEmpty ? (
          <span className="emptyStateMessage">{emptyStateMessage}</span>
        ) : (
          children
        )}
      </div>
      {footer && !isEmpty && <footer>{footer}</footer>}
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.string,
  headerActions: PropTypes.node,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  bodyStyle: PropTypes.object,
  children: PropTypes.node,
  footer: PropTypes.node,
  style: PropTypes.object,
  emptyStateMessage: PropTypes.string,
  isEmpty: PropTypes.bool,
};

export default Card;
