import React from 'react';
import { Modal, Button, Message } from 'semantic-ui-react';
import { ErrorHandler, Users } from '../../api';
import { FormInput, FormSelect } from '../../components/';
import { AppContext } from '../../components/AppContext/AppContext';
import { email as validateEmail } from '../../validation';

import './EditUserModal.scss';

class EditUserModal extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      firstName: props.firstName || '',
      lastName: props.lastName || '',
      email: props.email || '',
      role: props.role || 2,
      id: props.id || '',
      emailError: '',
      firstNameError: '',
      lastNameError: '',
      roleError: '',
      formError: '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.id !== this.props.id) {
      const { firstName, lastName, email, role, id } = this.props;
      this.setState({ firstName, lastName, email, role, id });
    }
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  validateForm = () => {
    const { email, firstName, lastName, role } = this.state;

    let update = false;
    let newState = {};
    if (!firstName) {
      newState.firstNameError = 'First name is required';
      update = true;
    }
    if (!lastName) {
      newState.lastNameError = 'Last name is required';
      update = true;
    }
    if (!role) {
      newState.roleError = 'Role is required';
      update = true;
    }
    if (!email) {
      newState.emailError = 'Email address is required';
      update = true;
    }
    if (!validateEmail(email)) {
      newState.emailError = 'Please enter a valid email address';
      update = true;
    }
    if (update) {
      this.setState(newState);
    }
    return !update;
  };

  handleSendInvite = async () => {
    this.safeSetState({ isLoading: true });
    const { firstName, lastName, email, role } = this.state;
    let res;
    if (!this.validateForm()) {
      this.safeSetState({isLoading: false});
      return;
    }

    try {
      res = await Users.new({ firstName, lastName, email, role });
    } catch (err) {
      let msg = 'An error has occurred. Please try your request again.';
      if (err && err.response && err.response.body) {
        const errRes = err.response.body;
        if (errRes.email_address) {
          msg = `Email address ${errRes.email_address}`;
        }
      }
      this.safeSetState({ formError: msg, isLoading: false });
      ErrorHandler.error(err);
    }
    if (res && res.body) {
      this.props.addToast({
        type: 'success',
        message: 'User invite sent successfully',
      });
      this.handleClose();
    }
    this.safeSetState({ isLoading: false });
  };

  handleUpdateUser = async () => {
    this.safeSetState({ isLoading: true });
    const { firstName, lastName, email, role, id } = this.state;
    let res;
    if (!this.validateForm()) {
      this.safeSetState({isLoading: false});
      return;
    }

    try {
      res = await Users.update({
        userId: id,
        firstName,
        lastName,
        email,
        role,
      });
    } catch (err) {
      let msg = 'An error has occurred. Please try your request again.';
      /*
      if(err && err.response && err.response.body){
        const errRes = err.response.body;
      }
      */
      this.safeSetState({ formError: msg });
      ErrorHandler.error(err);
    }
    if (res && res.body) {
      this.props.addToast({
        type: 'success',
        message: 'User was updated successfully',
      });
      this.handleClose();
    }
    this.safeSetState({ isLoading: false });
  };

  handleClose = () => {
    this.safeSetState(
      {
        firstNameError: '',
        lastNameError: '',
        emailError: '',
        roleError: '',
        firstName: '',
        lastName: '',
        email: '',
        formError: '',
      },
      () => this.props.onClose()
    );
  };

  handleFirstNameChange = e =>
    this.safeSetState({ firstName: e.target.value, firstNameError: '' });
  handleLastNameChange = e =>
    this.safeSetState({ lastName: e.target.value, lastNameError: '' });
  handleEmailChange = e =>
    this.safeSetState({ email: e.target.value, emailError: '' });
  handleRoleChange = (e, opt) =>
    this.safeSetState({ role: opt.value, roleError: '' });

  render() {
    const {
      firstName,
      lastName,
      email,
      role,
      isLoading,
      emailError,
      roleError,
      firstNameError,
      lastNameError,
      formError,
    } = this.state;
    const { isVisible, modalType } = this.props;
    return (
      <Modal
        open={isVisible}
        onClose={this.handleClose}
        size="small"
        className="EditUserModal">
        <Modal.Header>
          {modalType === 'edit' &&
            `Edit User: ${firstName || ''} ${lastName || ''}`}
          {modalType === 'invite' && 'Invite New User'}
        </Modal.Header>
        <Modal.Content>
          {!!formError && <Message negative>{formError}</Message>}
          <div className="form-row">
            <FormInput
              autoFocus
              className="half-width"
              label="FIRST NAME"
              onChange={this.handleFirstNameChange}
              value={firstName}
              error={!!firstNameError}
              errorMessage={firstNameError}
            />
            <FormInput
              className="half-width"
              label="LAST NAME"
              onChange={this.handleLastNameChange}
              value={lastName}
              error={!!lastNameError}
              errorMessage={lastNameError}
            />
          </div>
          <div className="form-row">
            <FormInput
              className="full-width"
              label="EMAIL ADDRESS"
              onChange={this.handleEmailChange}
              value={email}
              error={!!emailError}
              errorMessage={emailError}
            />
          </div>
          <div className="form-row">
            <FormSelect
              className="full-width"
              label="ROLE"
              options={[
                { text: 'Admin', value: 1 },
                { text: 'User', value: 2 },
              ]}
              onChange={this.handleRoleChange}
              value={role}
              error={!!roleError}
              errorMessage={roleError}
            />
          </div>
          <div className="button-box">
            <Button className="ghost dark" onClick={this.handleClose}>
              CANCEL
            </Button>
            {modalType === 'edit' && (
              <Button
                primary
                loading={isLoading}
                onClick={this.handleUpdateUser}>
                UPDATE USER
              </Button>
            )}
            {modalType === 'invite' && (
              <Button
                primary
                loading={isLoading}
                onClick={this.handleSendInvite}>
                SEND INVITE
              </Button>
            )}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const WrappedEditUserModal = props => (
  <AppContext.Consumer>
    {({ addToast }) => <EditUserModal {...props} addToast={addToast} />}
  </AppContext.Consumer>
);

export default WrappedEditUserModal;
