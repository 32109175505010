import React from 'react';

import { Card, HeaderBar, FormField } from '../../components/';
import { Tab, Button } from 'semantic-ui-react';

import { ErrorHandler, Clients } from '../../api';
import { AppContext } from '../../components/AppContext/AppContext';
import TabNotes from './TabNotes';
import EditNoteModal from './EditNoteModal';
import { format } from 'date-fns';

import TabApplications from './TabApplications';

import './ClientDetails.scss';

class ClientDetails extends React.Component {
  _isMounted = false;
  _tempIDCounter = 1;
  constructor(props) {
    super(props);

    const clientId = this.props.match.params.client_id;
    this.state = {
      clientId,
      caseNumber: '',
      activeIndex: 0,
      isLoading: false,
      fold: true,
      // === formfields
      firstName: '',
      middleName: '',
      lastName: '',
      sex: '',
      dob: '',
      race: '',
      preferredLang: '',
      hohRelationship: '',
      bptAuthorizedRep: '',
      ridNumber: '',
      consentNotes: '',
      household: '',
      isEditModal: false,
      isBptEmployee: '',
      isBptEmployeeFamily: '',
      bptRamilyRelationship: '',
      ssn: '',
      editNote: null,
      hasPendingApps: '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params.client_id !== this.props.match.params.client_id
    ) {
      this.setState(
        { clientId: this.props.match.params.client_id },
        this._fetch
      );
    }
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });
    const { clientId } = this.state;
    let res;
    try {
      res = await Clients.get(clientId);
    } catch (err) {
      ErrorHandler.error(err);
    }

    let newState = { isLoading: false };
    if (res && res.body) {
      const data = res.body;

      Object.assign(newState, {
        firstName: data.first_name,
        middleName: data.middle_name,
        lastName: data.last_name,
        sex: data.sex,
        dob: data.dob,
        race: data.race && data.race.value,
        preferredLang: data.preferred_lang && data.preferred_lang.value,
        hohRelationship: data.hoh_relationship && data.hoh_relationship.value,
        bptAuthorizedRep: data.bpt_authorized_rep,
        ridNumber: data.rid_number,
        consentNotes: data.consent_notes,
        household: data.household_id,
        isBptEmployee: data.is_bpt_employee,
        isBptEmployeeFamily: data.bpt_employee_family,
        bptRamilyRelationship: data.bpt_family_relationship,
        ssn: data.ssn,
        hasPendingApps: data.has_pending_apps,
        caseNumber: data.case_number,
      });
    }
    this.safeSetState(newState);
  };

  handleBack = () => this.props.history.push('/clients');

  handleEdit = () =>
    this.props.history.push(`/clients/${this.state.clientId}/edit`); //'may update

  handleTabChange = (e, { activeIndex }) => this.safeSetState({ activeIndex });

  handleToggleFold = () =>
    this.setState((prevState) => ({ ...prevState, fold: !prevState.fold }));

  handleAddNote = () => this.handleShowNoteModal();

  handleShowNoteModal = (editNote) => {
    this.safeSetState({
      editNote,
      isEditModal: !!editNote,
      isVisibleModal: true,
    });
  };

  handleHideNoteModal = (success) => {
    // if we pass success, then redirect to NoteTab
    if (success) {
      // lol this is a little odd...
      // 1. if we added a note with the add note button, then we want to go to the notes tab (tab 2) afterwards
      // 2. if we edited a note on the note tab, then we want to reload the note tab, but react is efficient,
      //    so if we are already on the note tab, it wont reload it... so quick switch from 0 tab to 2 to
      //    force it... lol... i could add a refetch function up on their shared ancestor, but thats also kinda jank...
      this.setState({ activeIndex: 1 }, () => {
        this.setState((prevState) => ({
          ...prevState,
          activeIndex: 0,
          editNote: null,
        }));
      });
    }
    this.safeSetState({ isVisibleModal: false, editNote: null });
  };

  handleHouseholdSelect = (householdId) => {
    this.props.history.push(`/households/${householdId}`);
  };

  render() {
    const {
      activeIndex,
      isLoading,
      clientId,
      firstName,
      middleName,
      lastName,
      sex,
      dob,
      race,
      preferredLang,
      hohRelationship,
      bptAuthorizedRep,
      ridNumber,
      consentNotes,
      household,
      ssn,
      editNote,
      isEditModal,
      isVisibleModal,
      caseNumber,
    } = this.state;
    const { addToast } = this.props;

    return (
      <div className="ClientDetails">
        <EditNoteModal
          addToast={addToast}
          isEdit={isEditModal}
          clientId={clientId}
          note={editNote}
          isVisible={isVisibleModal}
          handleClose={this.handleHideNoteModal}
          household={household}
        />
        <HeaderBar
          title={`Client: ${firstName} ${lastName}`}
          actionsLeft={
            <Button className="btn-secondary" onClick={this.handleAddNote}>
              ADD NOTE
            </Button>
          }
          actions={
            <React.Fragment>
              <Button className="ghost dark" onClick={this.handleBack}>
                BACK
              </Button>
              <Button className="primary" onClick={this.handleEdit}>
                EDIT CLIENT
              </Button>
            </React.Fragment>
          }
        />
        <Card title="Overview" isLoading={isLoading} className="overview">
          <div className="form-row">
            <FormField
              autoFocus
              title="Name"
              value={
                [firstName, middleName, lastName]
                  .filter((f) => !!f)
                  .join(' ') || '-'
              }
            />
            <FormField className="upcase" title="Sex" value={sex || '-'} />
            <FormField
              className="upcase"
              title="Date of Birth"
              value={(dob && format(dob, 'M/D/YYYY')) || '-'}
            />
            <FormField
              className="upcase"
              title="SSN"
              value={
                ssn && typeof ssn === 'string'
                  ? ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3')
                  : '-'
              }
            />
            <FormField className="upcase" title="Race" value={race || '-'} />
            <FormField
              className="upcase"
              title="Preferred Language"
              value={preferredLang || '-'}
            />
            <FormField
              className="upcase"
              title="Relationship to HOH"
              value={hohRelationship || '-'}
            />
          </div>
          <div className="form-row">
            {household && (
              <FormField
                className="upcase multiline link"
                title="Current Household"
                value={household || '-'}
                onClick={(e) => {
                  e.stopPropagation();
                  this.handleHouseholdSelect(household);
                }}
              />
            )}
            {!household && (
              <FormField
                className="upcase multiline"
                title="Current Household"
                value={household || '-'}
              />
            )}
            <FormField
              className="upcase"
              title="Brightpoint Authorized Rep?"
              value={(!!bptAuthorizedRep ? 'Yes' : 'No') || '-'}
            />
            <FormField
              className="upcase"
              title="Case Number"
              value={caseNumber}
            />
            <FormField
              className="upcase"
              title="RID Number"
              value={ridNumber}
            />
            <FormField
              className="upcase"
              title="Consent Notes"
              value={consentNotes || '-'}
            />
          </div>
        </Card>
        <Card className="nested-no-style">
          <Tab
            style={{ width: '100%' }}
            menu={{ secondary: true, pointing: true }}
            activeIndex={activeIndex}
            onTabChange={this.handleTabChange}
            panes={[
              {
                menuItem: 'NOTES',
                render: () => (
                  <TabNotes
                    handleShowEditModal={this.handleShowNoteModal}
                    handleViewClient={this.handleViewClient}
                    clientId={clientId}
                    handleGoToNewClient={this.handleGoToNewClient}
                    addToast={addToast}
                    user={this.props.user}
                  />
                ),
              },
              {
                menuItem: 'APPLICATIONS',
                render: () => (
                  <TabApplications
                    history={this.props.history}
                    handleViewClient={this.handleViewClient}
                    householdId={household}
                    clientId={clientId}
                    handleGoToNewClient={this.handleGoToNewClient}
                    addToast={this.props.addToast}
                  />
                ),
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

const WrappedClientDetails = (props) => (
  <AppContext.Consumer>
    {({ addToast, user }) => (
      <ClientDetails {...props} user={user} addToast={addToast} />
    )}
  </AppContext.Consumer>
);

export default WrappedClientDetails;
