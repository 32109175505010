import React from 'react';
import logo from '../../assets/img/BrightPoint_4C_white.svg';
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { checkIsAdmin } from '../../api';

import './Sidebar.scss';

export const Sidebar = () => {
  return (
    <section id="sidebar" className="app sidebar">
      <div className="brand">
        <img alt="BrightPoint" src={logo} />
        <nav id="main">
          <SidebarFeature link="/dashboard" title="Dashboard" icon="th" />
          <SidebarFeature link="/clients" title="Clients" icon="users" />
          <SidebarFeature
            link="/households"
            title="Households"
            icon="briefcase"
          />

          <SidebarSection isAllowed={checkIsAdmin()} title="Admin">
            <SidebarFeature link="/admin/users" title="Users" icon="user" />
            <SidebarFeature
              link="/admin/reports"
              title="Reports"
              icon="clipboard list"
            />
            <SidebarFeature
              link="/admin/settings"
              title="Settings"
              icon="setting"
            />
          </SidebarSection>
        </nav>
      </div>
      <div className="background-image"></div>
      <div className="bottom-logo"></div>
    </section>
  );
};

export default Sidebar;

const SidebarSection = ({ isAllowed = true, title, children, ...otherProps }) =>
  isAllowed ? (
    <section {...otherProps}>
      <label>{title}</label>
      {children}
    </section>
  ) : null;

const SidebarFeature = ({ permission, title, link, icon }) => {
  return (
    <NavLink className="SidebarFeature" to={link}>
      <Icon name={icon} />
      {title}
    </NavLink>
  );
};
