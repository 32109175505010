import React from 'react';

import { Chip, Card, DataTable } from '../../components/';
import { format } from 'date-fns';

import { ErrorHandler, Dashboard as DashAPI } from '../../api';

import './Dashboard.scss';

const tableColumns = [
  {
    name: 'Name',
    dataKey: 'clientFullName',
    description: '',
  },
  {
    name: 'DOB',
    dataKey: 'client.dob',
    description: '',
    formatter: (val) => val && format(val, 'M/D/YYYY'),
  },
  {
    name: 'SSN',
    dataKey: 'client.ssn',
    description: '',
  },
  {
    name: 'Type',
    dataKey: 'application_category',
    description: '',
  },
  {
    name: 'Application #',
    dataKey: 'application_number',
    description: '',
  },
  {
    name: 'Case #',
    dataKey: 'client.case_number',
    description: '',
  },
  {
    name: 'Status',
    dataKey: 'status',
    description: '',
    formatter: (val) => val && <Chip value={val} />,
  },
  {
    name: 'Application Date',
    dataKey: 'application_date',
    description: '',
    formatter: (val) => val && format(val, 'M/D/YYYY'),
  },
];

export default class Dashboard extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isLoadingTable: false,
      tableData: [],
      approvalRating: 0,
      submitted: 0,
      assists: 0,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetchMetrics();
    this._fetchTableData();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetchMetrics = async () => {
    this.safeSetState({ isLoading: true });
    let res;
    try {
      res = await DashAPI.metrics();
    } catch (err) {
      ErrorHandler.error(err);
    }

    let approvalRating = 0;
    let submitted = 0;
    let assists = 0;
    if (res && res.body) {
      const data = res.body;
      approvalRating = data.app_approval_rating;
      submitted = data.apps_submitted;
      assists = data.total_assists;
    }
    this.safeSetState({ approvalRating, submitted, assists });
  };

  _fetchTableData = async () => {
    this.safeSetState({ isLoadingTable: true });
    let res;
    try {
      res = await DashAPI.applications();
    } catch (err) {
      ErrorHandler.error(err);
    }

    let tableData = [];
    if (res && res.body) {
      tableData = res.body.map((row) => {
        row.clientFullName = [];
        if (row.client) {
          row.clientFullName.push(row.client.last_name);
          row.clientFullName.push(row.client.first_name);
        }
        row.clientFullName = row.clientFullName.join(', ');
        return row;
      });
    }
    this.safeSetState({ tableData, isLoadingTable: false });
  };

  handleApplicationSelect = (household_id, application_id) => {
    this.props.history.push(
      `/households/${household_id}/applications/${application_id}`
    );
  };

  render() {
    const {
      isLoadingTable,
      tableData,
      approvalRating,
      submitted,
      assists,
    } = this.state;

    let currentYear = new Date().getFullYear();

    return (
      <div className="Dashboard">
        <div className="header-row">
          <Card className="header-widget">
            <div className="left-box">
              <div className="title">Applications Submitted</div>
              <div className="subtitle">in {currentYear}</div>
            </div>
            <div className="right-box">{submitted}</div>
          </Card>
          <Card className="header-widget">
            <div className="left-box">
              <div className="title">Application Approval Rating</div>
              <div className="subtitle">in {currentYear}</div>
            </div>
            <div className="right-box">{approvalRating}%</div>
          </Card>
          <Card className="header-widget">
            <div className="left-box">
              <div className="title">Total Client Assists</div>
              <div className="subtitle">in {currentYear}</div>
            </div>
            <div className="right-box">{assists}</div>
          </Card>
        </div>
        <DataTable
          tableName="Dashboard"
          isLoading={isLoadingTable}
          data={tableData}
          onRowClick={(row) => {
            let household_id = row && row.household_id;
            let application_id = row && row.id;
            this.handleApplicationSelect(household_id, application_id);
          }}
          defaultSort="application_date"
          defaultSortDirection="ASC"
          emptyStateMessage="You do not currently have any open applications."
          title="My Open Applications"
          //onRowClick={this.handleTicketSelect}
          searchColumns={[
            'clientFullName',
            'application_number',
            'client.case_number',
          ]}
          columns={tableColumns}
        />
      </div>
    );
  }
}
