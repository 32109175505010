import React from 'react';

import BaseEntry from './views/BaseEntry/BaseEntry';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // you can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      return (
        <BaseEntry>
          <h2>The app encountered an error, please refresh the page.</h2>
        </BaseEntry>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
