import React, { Component } from 'react';

const AppContext = React.createContext({
  toasts: [],
  addToast: () => {},
  removeToast: () => {},
  user: {},
  setUser: () => {},
  logout: () => {},
  flipShouldFetchNotes: () => {},
});

class WithAppContext extends Component {
  lastId = 1;
  constructor(props) {
    super(props);

    this.state = {
      toasts: [],
      addToast: this.addToast,
      removeToast: this.removeToast,
      user: this.user,
      setUser: this.setUser,
      logout: this.logout,
      shouldFetchNotes: false,
      flipShouldFetchNotes: this.flipShouldFetchNotes
    };
  }

  get user() {
    let user = localStorage.getItem('user');
    user = user ? JSON.parse(user) : user;
    return user;
  }

  logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  setUser = user => {
    localStorage.setItem('user', JSON.stringify(user));
    this.setState({ user });
  };

  addToast = ({ type, message }) => {
    this.setState({
      toasts: [...this.state.toasts, { type, message, id: (this.lastId += 1) }],
    });
  };

  removeToast = toastId => {
    const newToasts = [];
    this.state.toasts.forEach(toast => {
      if (toast.id !== toastId) {
        newToasts.push(toast);
      }
    });
    this.setState({ toasts: newToasts });
  };

  flipShouldFetchNotes = () => {
    this.setState(prevState => ({
      shouldFetchNotes: !prevState.shouldFetchNotes
    }));
   };

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export { WithAppContext, AppContext };
