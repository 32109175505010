import React from 'react';

import { Loader } from 'semantic-ui-react';

import { FormFieldBase } from './FormFieldBase';
import { BaseDateSelector } from '../';

import './FormDate.scss';

export const FormDate = ({
  placeholder,
  value,
  onChange,
  disabled,
  loading,
  className,
  alignRight,
  allowFuture,
  autoFocus,
  ...otherProps
}) => (
  <FormFieldBase
    component={
      <React.Fragment>
        <BaseDateSelector
          autoFocus={autoFocus}
          alignRight={alignRight}
          placeholder={placeholder}
          date={value || ''}
          onDateChanged={date => onChange(date, { value: date })}
          disabled={disabled}
          dayPickerProps={allowFuture ? {} : null}
          label=""
        />
        {loading && (
          <Loader size="tiny" style={{ margin: '8px 7px 0 0' }} inline active />
        )}
      </React.Fragment>
    }
    className={`FormDate ${className || ''}`}
    disabled={disabled}
    {...otherProps}
  />
);

export default FormDate;
