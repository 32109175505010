import React from 'react';
import { Clients, ErrorHandler, Households } from '../../api';

import isEqual from 'lodash/isEqual';

import {
  AppContext,
  FormTextArea,
  FormSelect,
  FormInput,
  FormDate,
  DropdownSetting,
  DropdownNavigator,
  DropdownAssistType,
  DropdownReferralCategory,
  DropdownReferralSource,
  DropdownCounty,
} from '../../components/';
import { Checkbox, Modal, Button } from 'semantic-ui-react';

import './EditNoteModal.scss';

class EditNoteModal extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      selectedMemberIds: [],
      navigator: props.user.id || null,
      noteType: '',
      timeSpent: '',
      provider: 271,
      noteDetails: '',
      pregnantClient: false,
      assistCategory: 'Internal',
      assistType: '',
      referralCategory: '',
      referralSource: '',
      serviceDate: '',
      county: '',
    };
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.note, this.props.note) && !!this.props.note) {
      const { note } = this.props;

      this.safeSetState({
        assistCategory: note.assist_category,
        assistType: note.assist_type && note.assist_type.id,
        selectedMemberIds: note.clients.map((n) => n.id),
        noteId: note.id,
        navigator: note.navigator.id,
        noteDetails: note.note_details,
        noteType: note.note_type,
        pregnantClient: note.pregnant_client,
        referralCategory: note.referral_category && note.referral_category.id,
        referralSource: note.referral_source && note.referral_source.id,
        serviceDate: note.service_date,
        timeSpent: note.time_spent,
        provider: (note.provider && note.provider.id) || 271,
        county: note.county_of_residence_id,
      });
    }
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  clearErrors = () => {
    this.safeSetState({
      navigatorError: '',
      noteTypeError: '',
      timeSpentError: '',
      providerError: '',
      noteDetailsError: '',
      assistCategoryError: '',
      assistTypeError: '',
      referralCategoryError: '',
      referralSourceError: '',
      serviceDateError: '',
      countyError: '',
    });
  };

  validateForm = () => {
    const { timeSpent, noteType } = this.state;
    const newState = {};
    let isValid = true;
    // timespent must be a float with max of 2 decimal places
    if (
      isNaN(timeSpent) ||
      (String(timeSpent).indexOf('.') !== -1 &&
        String(timeSpent).split('.')[1].length > 2)
    ) {
      newState.timeSpentError =
        'Must be a number with no more than 2 decimal places.';
      isValid = false;
    }
    // check required fields
    let requiredFields = [
      'noteType',
      'serviceDate',
      'navigator',
      'timeSpent',
      'noteDetails',
      'provider',
    ];
    if (noteType === 'Assist') {
      const additionalFields = [
        'assistCategory',
        'assistType',
        'referralSource',
        'referralCategory',
      ];
      requiredFields = [...requiredFields, ...additionalFields];
    }
    requiredFields.forEach((field) => {
      if (!this.state[field]) {
        newState[`${field}Error`] = 'This field is required';
        isValid = false;
      }
    });
    if (!isValid) {
      newState.isValid = isValid;
      this.safeSetState(newState);
    }
    return isValid;
  };

  handleSubmit = async () => {
    if (!this.validateForm()) {
      return;
    }
    this.safeSetState({
      addingNote: true,
    });
    const { isEdit } = this.props;
    const {
      navigator,
      noteType,
      timeSpent,
      provider,
      noteDetails,
      pregnantClient,
      assistCategory,
      assistType,
      referralCategory,
      referralSource,
      serviceDate,
      county,
    } = this.state;

    let countyToSend;
    // if county is empty string, then client is associated with a household
    // so query the household data to get the county for said household
    if (county === '') {
      let householdId = this.props.household;
      let res;
      try {
        res = await Households.get(householdId);
        countyToSend = res.body.county_of_residence.id;
      } catch (err) {
        ErrorHandler.error(err);
      }
    } else {
      countyToSend = county;
    }

    const { clientId, note } = this.props;
    const clients = [];
    clients.push(clientId);

    let payload = {};
    if (noteType === 'Assist') {
      payload = {
        clients: clients,
        note_type: noteType,
        service_date: serviceDate,
        navigator_id: navigator,
        time_spent: timeSpent,
        provider_id: provider,
        assist_category: assistCategory,
        referral_category_id: referralCategory,
        referral_source_id: referralSource,
        assist_type_id: assistType,
        pregnant_client: pregnantClient,
        note_details: noteDetails,
        county_of_residence_id: countyToSend,
      };
    } else {
      payload = {
        clients: clients,
        note_type: noteType,
        service_date: serviceDate,
        navigator_id: navigator,
        time_spent: timeSpent,
        provider_id: provider,
        pregnant_client: pregnantClient,
        note_details: noteDetails,
        county_of_residence_id: countyToSend,
      };
    }

    let res;
    try {
      if (isEdit) {
        res = await Clients.noteUpdate({
          clientId,
          noteId: note.id,
          payload,
        });
      } else {
        res = await Clients.noteAdd({ clientId, payload });
      }
    } catch (err) {
      ErrorHandler.error(err);
      const message = `An error occurred and the Note could not be ${
        isEdit ? 'updated' : 'added'
      } at this time. Please try again later.`;
      this.props.addToast({
        type: 'error',
        message,
      });
    }

    if (res) {
      const message = `${noteType} note ${
        isEdit ? 'updated' : 'added'
      } successfully!`;
      this.props.addToast({
        type: 'success',
        message,
      });
      this.handleClose(true);
    }
    this.safeSetState({
      addingNote: false,
    });
  };

  handleClose = (success) => {
    this.safeSetState(
      {
        clients: [],
        navigator: this.props.user.id,
        noteType: '',
        timeSpent: '',
        provider: 271,
        noteDetails: '',
        pregnantClient: false,
        assistCategory: 'Internal',
        assistType: '',
        referralCategory: '',
        referralSource: '',
        serviceDate: '',
      },
      () => {
        this.clearErrors();
        const res = typeof success === 'boolean' ? success : false;
        this.props.handleClose(res);
      }
    );
  };

  handleSelectChange = (field, value) => {
    this.safeSetState({ [field]: value, [`${field}Error`]: '' });
  };

  handleChangeTimeSpent = (e) =>
    this.safeSetState({ timeSpent: e.target.value, timeSpentError: '' });
  handleChangeNoteDetails = (e) =>
    this.safeSetState({ noteDetails: e.target.value, noteDetailsError: '' });
  handleChangePregnantClient = () =>
    this.setState((prevState) => ({
      ...prevState,
      pregnantClient: !prevState.pregnantClient,
    }));

  handleDateSelect = (date) =>
    this.safeSetState({ serviceDate: date, serviceDateError: '' });
  render() {
    const { isVisible, household } = this.props;
    const {
      navigator,
      noteType,
      timeSpent,
      provider,
      noteDetails,
      pregnantClient,
      assistCategory,
      assistType,
      referralCategory,
      referralSource,
      serviceDate,
      addingNote,
      county,
      // Errors below ----
      navigatorError,
      noteTypeError,
      timeSpentError,
      providerError,
      noteDetailsError,
      assistCategoryError,
      assistTypeError,
      referralCategoryError,
      referralSourceError,
      serviceDateError,
    } = this.state;

    const { isEdit } = this.props;

    const noteTypeOptions = [
      { text: 'Assist', value: 'Assist' },
      { text: 'Follow-Up', value: 'Follow-Up' },
      { text: 'Data Entry', value: 'Data Entry' },
    ];

    const assistCategoryOptions = [
      { text: 'Internal', value: 'Internal' },
      { text: 'External', value: 'External' },
    ];

    return (
      <Modal
        open={isVisible}
        onClose={this.handleClose}
        size="small"
        className="EditNoteModal">
        <Modal.Header>{isEdit ? 'Edit Note' : 'Add Note'}</Modal.Header>
        <Modal.Content>
          <div className="form-row">
            <FormSelect
              autoFocus
              required
              error={noteTypeError}
              errorMessage={noteTypeError}
              label="NOTE TYPE"
              options={noteTypeOptions}
              value={noteType}
              onChange={(e, { value }) =>
                this.handleSelectChange('noteType', value)
              }
            />
            <FormDate
              error={serviceDateError}
              errorMessage={serviceDateError}
              label="SERVICE DATE"
              value={serviceDate}
              onChange={this.handleDateSelect}
              required
            />
            <DropdownNavigator
              error={navigatorError}
              errorMessage={navigatorError}
              required
              value={navigator}
              onChange={(e, { value }) =>
                this.handleSelectChange('navigator', value)
              }
            />
          </div>
          <div className="form-row">
            {this.props.household === null ? (
              <DropdownCounty
                required
                value={county}
                error={noteTypeError}
                errorMessage={noteTypeError}
                onChange={(e, opt) =>
                  this.handleSelectChange('county', opt.value)
                }
              />
            ) : null}
            <FormInput
              error={timeSpentError}
              errorMessage={timeSpentError}
              required
              label="TIME SPENT (IN MINUTES)"
              value={timeSpent}
              onChange={this.handleChangeTimeSpent}
              style={household ? { width: `48%` } : null}
            />
            <DropdownSetting
              required
              settingId={8}
              label="PROVIDER"
              className="field-site"
              value={provider}
              error={providerError}
              errorMessage={providerError}
              onChange={(e, { value }) =>
                this.handleSelectChange('provider', value)
              }
              style={household ? { width: `48%` } : null}
            />
          </div>
          {noteType === 'Assist' && (
            <React.Fragment>
              <div className="form-row">
                <FormSelect
                  error={assistCategoryError}
                  errorMessage={assistCategoryError}
                  required
                  style={{ width: '48%' }}
                  label="ASSIST CATEGORY"
                  options={assistCategoryOptions}
                  value={assistCategory}
                  onChange={(e, { value }) =>
                    this.handleSelectChange('assistCategory', value)
                  }
                />
                <DropdownAssistType
                  required
                  error={assistTypeError}
                  errorMessage={assistTypeError}
                  style={{ width: '48%' }}
                  value={assistType}
                  onChange={(e, { value }) =>
                    this.handleSelectChange('assistType', value)
                  }
                />
              </div>
              <div className="form-row">
                <DropdownReferralCategory
                  error={referralCategoryError}
                  errorMessage={referralCategoryError}
                  required
                  style={{ width: '48%' }}
                  value={referralCategory}
                  onChange={(e, { value }) =>
                    this.handleSelectChange('referralCategory', value)
                  }
                />
                <DropdownReferralSource
                  error={referralSourceError}
                  errorMessage={referralSourceError}
                  required
                  style={{ width: '48%' }}
                  value={referralSource}
                  onChange={(e, { value }) =>
                    this.handleSelectChange('referralSource', value)
                  }
                />
              </div>
            </React.Fragment>
          )}
          <div className="form-row">
            <FormTextArea
              error={noteDetailsError}
              errorMessage={noteDetailsError}
              style={{ width: '100%' }}
              label="NOTE DETAILS"
              rows={5}
              value={noteDetails}
              onChange={this.handleChangeNoteDetails}
            />
          </div>
          <div className="form-row">
            <Checkbox
              label="Note Pertains to Pregnant Client"
              checked={pregnantClient}
              onChange={this.handleChangePregnantClient}
            />
          </div>
          <div className="button-box">
            <Button className="ghost dark" onClick={this.handleClose}>
              CANCEL
            </Button>
            <Button primary loading={addingNote} onClick={this.handleSubmit}>
              {isEdit ? 'SAVE EDITS' : 'ADD NOTE'}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const WrappedEditNoteModal = (props) => (
  <AppContext.Consumer>
    {({ user }) => <EditNoteModal {...props} user={user} />}
  </AppContext.Consumer>
);

export default WrappedEditNoteModal;
