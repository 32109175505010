import React from 'react';
import { Popup, Table, Button } from 'semantic-ui-react';
import { FormInput } from '../';
import { Clients, ErrorHandler } from '../../api';
import { debounce } from '../../util';

//import { AppContext } from '../AppContext/AppContext';

import './HeadOfHousehold.scss';

export default class HeadOfHousehold extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
      clientData: null, // specifically null so that it doesn't show the popup on load
      isVisible: false,
      searchToken: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value }, () => this.setSelectedHOH());
    }
  }

  _fetch = async () => {
    this.setState({ isLoading: true });

    const { searchToken } = this.state;
    let res;
    try {
      res = await Clients.all({
        limit: 50,
        search: searchToken,
        offset: 0,
        sortBy: 'last_name',
        sortDirection: 'ASC',
      });
    } catch (err) {
      ErrorHandler.error(err);
    }

    let clientData = [];
    if (res?.body?.data?.length) {
      clientData = res.body.data;
    }

    this.setState({ isLoading: false, clientData, isVisible: true });
  };

  setSelectedHOH = () => {
    const { value, clientData } = this.state;
    let searchToken = '';
    if (value && clientData?.length > 0) {
      const selected = clientData.find((cd) => cd.id === value);
      if (selected) {
        searchToken = [
          selected.last_name || '',
          selected.first_name || '',
        ].join(', ');
        this.setState({ searchToken });
      }
    }
  };

  onShow = () => {
    // only show the popup if there is data
    if (this.state.clientData) {
      this.setState({ isVisible: true, searchToken: '' });
    }
  };
  onHide = () => this.setState({ isVisible: false });

  debouncedFetch = debounce(this._fetch, 1000);

  handleSearchChange = (e) =>
    this.setState({ searchToken: e.target.value }, this.debouncedFetch);

  handleSelect = (row) => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(row.id);
    }
    this.setState(
      {
        value: row.id,
        searchToken: `${row.last_name}, ${row.first_name}`,
      },
      () => this.onHide()
    );
    // empty the search bar
    this.setState({
      searchToken: '',
    });
  };

  render() {
    const { clientData, isVisible, isLoading, searchToken } = this.state;
    const {
      handleNewClient,
      placeholder,
      icon = 'search',
      width = '300px',
      label,
    } = this.props;

    return (
      <Popup
        trigger={
          <FormInput
            placeholder={placeholder || 'Client Quick Search'}
            label={label || null}
            onChange={this.handleSearchChange}
            value={searchToken}
            icon={icon}
            type="search"
            loading={isLoading}
            style={{ width }}
          />
        }
        on="click"
        basic
        position="bottom left"
        open={isVisible}
        onOpen={this.onShow}
        onClose={this.onHide}
        content={
          <ClientTable
            handleNewClient={handleNewClient}
            handleSelectHOH={this.handleSelect}
            searchToken={searchToken}
            clientData={clientData}
          />
        }
      />
    );
  }
}

const ClientTable = ({
  handleNewClient,
  searchToken,
  clientData,
  handleSelectHOH,
}) => {
  return (
    <div className="HeadOfHousehold">
      <div className="scroll-table">
        <Table basic="very" selectable singleLine>
          <Table.Header style={{ position: 'sticky !important' }}>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'right' }}>
                DOB
              </Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'right' }}>
                SSN
              </Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: 'right' }}>
                Case #
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {!!clientData &&
              clientData.length > 0 &&
              clientData.map((row, i) =>
                row.household_id ? (
                  <Popup
                    key={i}
                    trigger={
                      <Table.Row className="taken" key={i}>
                        <Table.Cell>
                          {[row.last_name, row.first_name].join(', ')}
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: 'right' }}>
                          {row.dob}
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: 'right' }}>
                          {row.ssn}
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: 'right' }}>
                          {row.case_number}
                        </Table.Cell>
                      </Table.Row>
                    }
                    content={`Client currently assigned to Household ${row.household_id}`}
                  />
                ) : (
                  <Table.Row
                    key={i}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSelectHOH(row)}>
                    <Table.Cell>
                      {[row.last_name, row.first_name].join(', ')}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: 'right' }}>
                      {row.dob}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: 'right' }}>
                      {row.ssn}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: 'right' }}>
                      {row.case_number}
                    </Table.Cell>
                  </Table.Row>
                )
              )}
          </Table.Body>
        </Table>
      </div>
      <Button className="ghost" fluid onClick={handleNewClient}>
        CREATE NEW CLIENT
      </Button>
    </div>
  );
};
