import React from 'react';

import { FormSelect } from '../';
import { Users, ErrorHandler } from '../../api';

class DropdownNavigator extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      options: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };
  _fetch = async () => {
    this.safeSetState({ loading: true });

    let res;
    try {
      res = await Users.all();
    } catch (err) {
      ErrorHandler.error(err);
    }

    let options = [];
    if (res && res.body && res.body) {
      options = res.body
        .filter((nav) => nav.status === 'active')
        .map((opt) => {
          let navigator_id = opt.id;
          return {
            text: [opt.first_name, opt.last_name].filter((f) => !!f).join(' '),
            value: navigator_id,
          };
        });
    }
    this.safeSetState({ options, loading: false });
  };

  render() {
    return (
      <FormSelect
        label={this.props.label || 'NAVIGATOR'}
        {...this.state}
        {...this.props}
      />
    );
  }
}
export default DropdownNavigator;
