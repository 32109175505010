import React from 'react';
import { Popup, Table, Button } from 'semantic-ui-react';
import { FormInput } from '../';
import { Clients, ErrorHandler } from '../../api';
import { format } from 'date-fns';
import { debounce } from '../../util';

import './GlobalClientSearch.scss';

export default class GlobalClientSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
      clientData: null, // specifically null so that it doesn't show the popup on load
      isVisible: false,
      searchToken: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value }, () => this.setSelectedHOH());
    }
  }

  _fetch = async () => {
    this.setState({ isLoading: true });

    const { searchToken } = this.state;
    let res;
    try {
      res = await Clients.all({
        limit: 50,
        search: searchToken,
        offset: 0,
        sortBy: 'last_name',
        sortDirection: 'ASC',
      });
    } catch (err) {
      ErrorHandler.error(err);
    }

    let clientData = [];
    if (res?.body?.data?.length) {
      clientData = res.body.data;
    }

    this.setState({ isLoading: false, clientData, isVisible: true });
  };

  onShow = () => {
    // only show the popup if there is data
    if (this.state.clientData) {
      this.setState({ isVisible: true });
    }
  };
  onHide = () => this.setState({ isVisible: false });

  debouncedFetch = debounce(this._fetch, 1000);

  handleSearchChange = (e) =>
    this.setState({ searchToken: e.target.value }, this.debouncedFetch);

  handleSelect = (row) => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(row.id);
    }
    this.setState(
      {
        value: row.id,
        searchToken: `${row.last_name}, ${row.first_name}`,
      },
      () => this.onHide()
    );
    // empty the search bar
    this.setState({
      searchToken: '',
    });
  };

  setSelectedHOH = () => {
    const { value, clientData } = this.state;
    let searchToken = '';
    if (value && clientData.length > 0) {
      const selected = clientData.find((cd) => cd.id === value);
      if (selected) {
        searchToken = [
          selected.last_name || '',
          selected.first_name || '',
        ].join(', ');
        this.setState({ searchToken });
      }
    }
  };

  render() {
    const { clientData, isVisible, isLoading, searchToken } = this.state;
    const {
      handleNewClient,
      placeholder,
      hasNewClientLink = false,
    } = this.props;

    return (
      <Popup
        className="GlobalClientSearch"
        trigger={
          <FormInput
            placeholder={placeholder || 'Search by Client Name or Case #'}
            onChange={this.handleSearchChange}
            value={searchToken}
            icon="search"
            type="search"
            loading={isLoading}
            style={{ width: '300px', ...(this.props.style || {}) }}
          />
        }
        on="click"
        basic
        position="bottom left"
        open={isVisible}
        onOpen={this.onShow}
        onClose={this.onHide}
        content={
          <ClientTable
            hasNewClientLink={hasNewClientLink}
            handleNewClient={handleNewClient}
            handleSelectHOH={this.handleSelect}
            searchToken={searchToken}
            clientData={clientData}
          />
        }
      />
    );
  }
}

const ClientTable = ({
  handleNewClient,
  searchToken,
  clientData,
  handleSelectHOH,
  hasNewClientLink,
}) => {
  return (
    <div>
      <Table basic="very" selectable singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell style={{ textAlign: 'right' }}>
              DOB
            </Table.HeaderCell>
            <Table.HeaderCell style={{ textAlign: 'right' }}>
              SSN
            </Table.HeaderCell>
            <Table.HeaderCell style={{ textAlign: 'right' }}>
              Case #
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {!!clientData?.length > 0 &&
            clientData.map((row, i) => (
              <Table.Row
                key={i}
                style={{ cursor: 'pointer' }}
                onClick={() => handleSelectHOH(row)}>
                <Table.Cell>
                  {[row.last_name, row.first_name].join(', ')}
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }}>
                  {format(row.dob, 'M/D/YYYY')}
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }}>
                  {row.ssn}
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }}>
                  {row.case_number}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {hasNewClientLink && (
        <Button className="ghost" fluid onClick={handleNewClient}>
          CREATE NEW CLIENT
        </Button>
      )}
    </div>
  );
};
