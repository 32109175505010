import React from 'react';

import { Modal, Button } from 'semantic-ui-react';

import './DeleteConfirmModal.scss';

const DeleteConfirmModal = ({ isLoading, isVisible, onClose, message, onDelete }) => (
  <Modal
    open={isVisible}
    onClose={onClose}
    size="tiny"
    className="DeleteConfirmModal">
    <Modal.Header>Are you sure?</Modal.Header>
    <Modal.Content>
      {message}
      <div className="button-box">
        <Button className="ghost dark" onClick={onClose}>
          CANCEL
        </Button>
        <Button
          loading={isLoading}
          negative
          onClick={onDelete}>
          YES, DELETE
        </Button>
      </div>
    </Modal.Content>
  </Modal>
);

export default DeleteConfirmModal;
