import React from 'react';

import { Card, HeaderBar, FormField } from '../../components/';
import { Tab, Button, Icon, Popup } from 'semantic-ui-react';

import { ErrorHandler, Households } from '../../api';
import { AppContext } from '../../components/AppContext/AppContext';
import { formatAddress, formatPhone } from '../../util';

import TabComposition from './TabComposition';
import { WrappedTabNotes } from './TabNotes';

import EditNoteModal from './EditNoteModal';

import TabApplications from './TabApplications';

import './HouseholdDetails.scss';

class HouseholdsDetails extends React.Component {
  _isMounted = false;
  _tempIDCounter = 1;
  constructor(props) {
    super(props);

    const householdId = this.props.match.params.household_id;
    const activeIndex =
      (this.props.history.location.state &&
        this.props.history.location.state.activeIndex) ||
      0;
    this.state = {
      householdId,
      activeIndex: activeIndex,
      isLoading: false,
      fold: true,
      // === formfields
      preferredLanguage: '',
      pAddress: '',
      pAddress2: '',
      pCity: '',
      pState: '',
      pZip: '',
      mAddress: '',
      mAddress2: '',
      mCity: '',
      mState: '',
      mZip: '',
      county: '',
      district: '',
      phone: '',
      phone2: '',
      email: '',
      email2: '',
      canText: false,
      canText2: false,
      // === EditModalStuff
      isEditModal: false,
      editNote: null,
      isVisibleModal: false,
      countyId: '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params.household_id !==
      this.props.match.params.household_id
    ) {
      this.setState(
        { householdId: this.props.match.params.household_id },
        this._fetch
      );
    }
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });
    const { householdId } = this.state;
    let res;
    try {
      res = await Households.get(householdId);
    } catch (err) {
      ErrorHandler.error(err);
    }

    const data = (res && res.body) || {};
    const { first_name, last_name, preferred_lang } = data.hoh || {};

    const newState = {
      isLoading: false,
      preferredLanguage: (preferred_lang && preferred_lang.value) || '',
      pAddress: data.physical_address_1,
      pAddress2: data.physical_address_2,
      pCity: data.physical_city,
      pState: data.physical_state,
      pZip: data.physical_zip,
      mAddress: data.mailing_address_1,
      mAddress2: data.mailing_address_2,
      mCity: data.mailing_city,
      mState: data.mailing_state,
      mZip: data.mailing_zip,
      county: data.county_of_residence && data.county_of_residence.value,
      countyId: data.county_of_residence && data.county_of_residence.id,
      district: data.school_district && data.school_district.value,
      phone: data.primary_phone,
      phone2: data.alt_phone,
      email: data.primary_email,
      email2: data.alt_email,
      canText: !!data.primary_phone_text,
      canText2: !!data.alt_phone_text,
      headOfHousehold: [last_name, first_name].filter((f) => !!f).join(', '),
    };
    this.safeSetState(newState);
  };

  handleBack = () => this.props.history.push('/households');

  handleGoToNewClient = () => {
    this.props.history.push({
      pathname: '/clients/add',
      state: { returnState: { ...this.state } },
    });
  };

  handleEdit = () =>
    this.props.history.push(`/households/${this.state.householdId}/edit`); //'may update

  handleTabChange = (e, { activeIndex }) => {
    this.safeSetState({ activeIndex });
  };

  handleToggleFold = () =>
    this.setState((prevState) => ({ ...prevState, fold: !prevState.fold }));

  handleAddNote = () => this.handleShowNoteModal();

  handleViewClient = (row) => {
    this.props.history.push(`/clients/${row.id}`);
  };
  handleEditClient = (row) => {
    this.props.history.push(`/clients/${row && row.id}/edit`);
  };

  handleShowNoteModal = (editNote) => {
    this.safeSetState({
      editNote,
      isEditModal: !!editNote,
      isVisibleModal: true,
    });
  };
  handleHideNoteModal = (success) => {
    // if we pass success, then redirect to NoteTab
    if (success) {
      // lol this is a little odd...
      // 1. if we added a note with the add note button, then we want to go to the notes tab (tab 2) afterwards
      // 2. if we edited a note on the note tab, then we want to reload the note tab, but react is efficient,
      //    so if we are already on the note tab, it wont reload it... so quick switch from 0 tab to 2 to
      //    force it... lol... i could add a refetch function up on their shared ancestor, but thats also kinda jank...
      this.setState({ activeIndex: 2 }, () => {
        this.setState((prevState) => ({
          ...prevState,
          activeIndex: 2,
          editNote: null,
        }));
      });
    }
    this.safeSetState({ isVisibleModal: false, editNote: null });
  };

  render() {
    const {
      activeIndex,
      isLoading,
      householdId,
      preferredLanguage,
      pAddress,
      pAddress2,
      pCity,
      pState,
      pZip,
      mAddress,
      mAddress2,
      mCity,
      mState,
      mZip,
      county,
      district,
      phone,
      email,
      canText,
      headOfHousehold,
      fold,
      // from TabNotes for Edit Modal
      isEditModal,
      editNote,
      isVisibleModal,
      countyId,
    } = this.state;
    const { addToast } = this.props;

    return (
      <div className="HouseholdDetails">
        <EditNoteModal
          addToast={addToast}
          isEdit={isEditModal}
          householdId={householdId}
          note={editNote}
          isVisible={isVisibleModal}
          handleClose={this.handleHideNoteModal}
          householdCounty={countyId}
        />
        <HeaderBar
          title={`Household ${householdId}`}
          actionsLeft={
            <Button className="btn-secondary" onClick={this.handleAddNote}>
              ADD NOTE
            </Button>
          }
          actions={
            <React.Fragment>
              <Button className="ghost dark" onClick={this.handleBack}>
                BACK
              </Button>
              <Button className="primary" onClick={this.handleEdit}>
                EDIT HOUSEHOLD
              </Button>
            </React.Fragment>
          }
        />
        <Card
          title="Overview"
          isLoading={isLoading}
          className="overview"
          headerActions={
            <Icon
              style={{ fontSize: '20px', color: '#929396' }}
              link
              name={fold ? 'chevron down' : 'chevron up'}
              onClick={this.handleToggleFold}
            />
          }>
          <div className="form-row">
            <FormField
              autoFocus
              className="upcase"
              title="Head of Household"
              value={headOfHousehold}
            />
            <FormField
              className="upcase"
              title="Primary Phone"
              value={
                <React.Fragment>
                  {formatPhone(phone) || ''}
                  {canText && (
                    <Popup
                      size="mini"
                      position="top center"
                      trigger={
                        <Icon style={{ marginLeft: '8px' }} name="commenting" />
                      }
                      content="Can Text"
                    />
                  )}
                </React.Fragment>
              }
            />
            <FormField
              className="upcase"
              title="Email Address"
              value={<a href={`mailto:${email}`}>{email}</a>}
            />
            <FormField
              className="upcase"
              title="Preferred Language"
              value={preferredLanguage}
            />
          </div>
          {!fold && (
            <div className="form-row">
              <FormField
                className="upcase multiline"
                title="Physical Address"
                value={formatAddress({
                  address1: pAddress,
                  address2: pAddress2,
                  city: pCity,
                  state: pState,
                  zip: pZip,
                })}
              />
              <FormField
                className="upcase multiline"
                title="Mailing Address"
                value={formatAddress({
                  address1: mAddress,
                  address2: mAddress2,
                  city: mCity,
                  state: mState,
                  zip: mZip,
                })}
              />
              <FormField
                className="upcase"
                title="School District"
                value={district}
              />
              <FormField
                className="upcase"
                title="County of Residence"
                value={county}
              />
            </div>
          )}
        </Card>
        <Card className="nested-no-style" isLoading={isLoading}>
          <Tab
            style={{ width: '100%' }}
            menu={{ secondary: true, pointing: true }}
            activeIndex={activeIndex}
            onTabChange={this.handleTabChange}
            panes={[
              {
                menuItem: 'COMPOSITION',
                render: () => (
                  <TabComposition
                    handleViewClient={this.handleViewClient}
                    householdId={householdId}
                    handleGoToNewClient={this.handleGoToNewClient}
                    addToast={addToast}
                  />
                ),
              },
              {
                menuItem: 'APPLICATIONS',
                render: () => (
                  <TabApplications
                    history={this.props.history}
                    handleEditClient={this.handleEditClient}
                    householdId={householdId}
                    handleGoToNewClient={this.handleGoToNewClient}
                    addToast={addToast}
                    user={this.props.user}
                  />
                ),
              },
              {
                menuItem: 'NOTES',
                render: () => (
                  <WrappedTabNotes
                    user={this.props.user}
                    handleShowEditModal={this.handleShowNoteModal}
                    handleViewClient={this.handleViewClient}
                    householdId={householdId}
                    handleGoToNewClient={this.handleGoToNewClient}
                    addToast={addToast}
                    shouldFetchNotes={this.props.shouldFetchNotes}
                  />
                ),
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

const WrappedHouseholdsDetails = (props) => (
  <AppContext.Consumer>
    {({ addToast, user, shouldFetchNotes, flipShouldFetchNotes }) => (
      <HouseholdsDetails
        {...props}
        user={user}
        addToast={addToast}
        shouldFetchNotes={shouldFetchNotes}
        flipShouldFetchNotes={flipShouldFetchNotes}
      />
    )}
  </AppContext.Consumer>
);

export default WrappedHouseholdsDetails;
