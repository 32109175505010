import React from 'react';
//import bowser from 'bowser';
//import { Message } from 'semantic-ui-react';

import './BaseEntry.scss';

import logo from '../../assets/img/BrightPoint_4C.svg';
import altLogo from '../../assets/img/ckf_logo.svg';

const BaseEntry = ({ children }) => {
  /*
  let isRecommendedBrowser = true;
  if (!bowser.chrome && !bowser.chromium && !bowser.firefox && !bowser.msedge) {
    isRecommendedBrowser = false;
  }*/
  return (
    <div className="BaseEntry">
      <div className="loginBg" />
      <div className="viewContainer">
        <img alt="Brightpoint" className="brand" src={logo} />
        <div className="content">{children}</div>
        <img alt="Covering kids and families of Indiana" className="alt-brand" src={altLogo} />
        {/*!isRecommendedBrowser && (
          <Message className="recommended-browsers">
            We recommend using{' '}
            <a
              className="vendor-link"
              href="https://google.com/chrome"
              rel="noopener noreferrer">
              Google Chrome
            </a>{' '}
            or{' '}
            <a
              className="vendor-link"
              href="https://getfirefox.com"
              rel="noopener noreferrer">
              Mozilla Firefox
            </a>{' '}
            for the best experience.
          </Message>
        )*/}
      </div>
    </div>
  );
};
export default BaseEntry;
