import React from 'react';

import BaseEntry from '../BaseEntry/BaseEntry';
import FormInput from '../../components/FormInput/FormInput';

import { Message, Button } from 'semantic-ui-react';
import { Users } from '../../api';
import { AppContext } from '../../components/AppContext/AppContext';
import { email as validateEmail } from '../../validation';

import './ForgotPassword.scss';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailError: '',
      formError: '',
      isSubmitting: false,
    };
  }

  validateForm = () => {
    const { email } = this.state;

    let update = false;
    let newState = {};
    if (!email) {
      newState.emailError = 'Email address is required';
      newState.formError =
        'Login failed. Please check your entries and try again.';
      update = true;
    }
    if (!validateEmail(email)) {
      newState.emailError = 'Please enter a valid email address';
      newState.formError =
        'Login failed. Please check your entries and try again.';
      update = true;
    }
    if (update) {
      this.setState(newState);
    }
    return !update;
  };

  handleChangeEmail = e =>
    this.setState({ email: e.target.value, emailError: '' });

  handleSubmit = async e => {
    e.preventDefault();
    if (!this.validateForm()) {
      return;
    }
    const { email } = this.state;
    this.setState({ isSubmitting: true });

    let res;
    try {
      res = await Users.sendRecoveryEmail({ email });
    } catch (err) {
      const errMsg =
        (err.response && err.response.body && err.response.body.error) ||
        'We were unable to send a recovery email.';
      this.props.addToast({
        type: 'error',
        message:
          'Your password reset request could not be completed. Please check your email and try again.',
      });
      this.setState({
        formError: errMsg,
      });
    }

    if (res) {
      this.props.history.push('/login');
      this.props.addToast({
        type: 'success',
        message:
          'Your password request was submitted successfully. Please check your email to reset your password.',
      });
    }

    this.setState({ isSubmitting: false });
  };

  handleCancel = () => {
    this.props.history.push('/login');
  };

  render() {
    const { email, emailError, formError, isSubmitting } = this.state;
    return (
      <BaseEntry>
        <form className="ForgotPassword" onSubmit={this.handleSubmit}>
          {!!formError && <Message negative>{formError}</Message>}
          <h4 className="heading">Request Password Reset</h4>
          <p className="description">
            To request a password reset, please enter the email address
            associated with your account.
          </p>
          <FormInput
            autoFocus
            label="EMAIL ADDRESS"
            error={!!emailError}
            errorMessage={emailError}
            value={email}
            onChange={this.handleChangeEmail}
          />
          <div className="form-actions">
            <Button primary loading={isSubmitting} type="submit">
              REQUEST PASSWORD RESET
            </Button>
            <Button
              className="ghost dark"
              type="button"
              onClick={this.handleCancel}>
              CANCEL
            </Button>
          </div>
        </form>
      </BaseEntry>
    );
  }
}

const WrappedForgotPassword = props => (
  <AppContext.Consumer>
    {({ addToast }) => <ForgotPassword {...props} addToast={addToast} />}
  </AppContext.Consumer>
);
export default WrappedForgotPassword;
