import React from 'react';
import { format } from 'date-fns';

import {
  TableRowOptionPopup,
  DataTable,
  FormSelect,
  Chip,
} from '../../components/';
import { Tab } from 'semantic-ui-react';

import { ErrorHandler, Clients } from '../../api';

//import AddApplicationModal from './AddApplicationModal';

import './TabApplications.scss';

class TabApplications extends React.Component {
  columns = [
    { name: 'Application #', dataKey: 'application_number' },
    { name: 'Type', dataKey: 'application_category' },
    { name: 'Applicant Name', dataKey: 'name' },
    {
      name: 'DOB',
      dataKey: 'client.dob',
      formatter: (val) => val && format(val, 'M/D/YYYY'),
    },
    { name: 'Case #', dataKey: 'case_number' },
    {
      name: 'Status',
      dataKey: 'status',
      formatter: (val) => val && <Chip value={val} />,
    },
    {
      name: 'Application Date',
      dataKey: 'application_date',
      formatter: (val) => val && format(val, 'M/D/YYYY'),
    },
    {
      name: '',
      dataKey: 'id',
      formatter: (val, row) => (
        <TableRowOptionPopup
          options={[
            {
              label: 'Edit Application',
              action: () =>
                this.props.history.push(
                  `/households/${this.props.householdId}/applications/${row.id}`
                ),
            },
          ]}
          row={row}
        />
      ),
      style: { textAlign: 'right' },
    },
  ];

  _isMounted = false;
  constructor(props) {
    super(props);

    // TODO: handle incoming redirected state

    this.state = {
      isLoading: false,
      tableData: [],
      isVisibleModal: false,
      headOfHousehold: '',
      modalSelectedHHM: null,
      optionsApplicants: [],
      optionsAppTypes: [],
      optionsAppStatus: [],
      selectedApplicant: 'all',
      selectedAppType: 'all',
      selectedAppStatus: 'all',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });
    const { clientId } = this.props;

    let res;
    try {
      res = await Clients.applications({ clientId });
    } catch (err) {
      ErrorHandler.error(err);
    }

    let tableData = [];
    if (res && res.body && res.body.length > 0) {
      tableData = res.body.map((row) => {
        const client = row.client || {};
        row.name = [client.last_name, client.first_name]
          .filter((f) => !!f)
          .join(', ');
        return row;
      });
    }

    this.populateFilterOptions(tableData);
    this.safeSetState({ tableData, isLoading: false });
  };

  capitalize = (word) => {
    if (!word || typeof word !== 'string') {
      return word;
    }
    return `${word.substr(0, 1).toUpperCase()}${word.substr(1)}`;
  };

  populateFilterOptions = (data) => {
    const optionsApplicants = [{ text: 'All Applications', value: 'all' }];
    const optionsAppTypes = [{ text: 'All Types', value: 'all' }];
    const optionsAppStatus = [{ text: 'All Statuses', value: 'all' }];

    data.forEach((row) => {
      const { name, application_category, status } = row;
      if (!optionsApplicants.some((applicant) => applicant.value === name)) {
        optionsApplicants.push({ text: this.capitalize(name), value: name });
      }
      if (
        !optionsAppTypes.some((type) => type.value === application_category)
      ) {
        optionsAppTypes.push({
          text: this.capitalize(application_category),
          value: application_category,
        });
      }
      if (!optionsAppStatus.some((_status) => _status.value === status)) {
        optionsAppStatus.push({ text: this.capitalize(status), value: status });
      }
    });

    this.safeSetState({ optionsAppTypes, optionsApplicants, optionsAppStatus });
  };

  handleAppTypeChange = (e, { value }) =>
    this.safeSetState({ selectedAppType: value });
  handleAppStatusChange = (e, { value }) =>
    this.safeSetState({ selectedAppStatus: value });

  render() {
    const {
      tableData,
      isLoading,
      optionsAppTypes,
      optionsAppStatus,
      selectedAppStatus,
      selectedApplicant,
      selectedAppType,
    } = this.state;

    let filteredTableData = tableData;
    if (
      selectedApplicant !== 'all' ||
      selectedAppType !== 'all' ||
      selectedAppStatus !== 'all'
    ) {
      // do it all in one loop, more efficient;
      filteredTableData = filteredTableData.filter((row) => {
        if (selectedApplicant !== 'all' && row.name !== selectedApplicant) {
          return false;
        }
        if (selectedAppStatus !== 'all' && row.status !== selectedAppStatus) {
          return false;
        }
        if (
          selectedAppType !== 'all' &&
          row.application_category !== selectedAppType
        ) {
          return false;
        }
        return true;
      });
    }

    return (
      <Tab.Pane
        attached={false}
        className="ClientTabApplications"
        loading={isLoading}>
        <div className="header-row">
          {tableData.length > 0 && (
            <div className="filters">
              <FormSelect
                options={optionsAppTypes}
                onChange={this.handleAppTypeChange}
                value={selectedAppType}
              />
              <FormSelect
                options={optionsAppStatus}
                value={selectedAppStatus}
                onChange={this.handleAppStatusChange}
              />
            </div>
          )}
        </div>
        {tableData.length > 0 ? (
          <DataTable
            onRowClick={this.handleRowClick}
            tableName="Users"
            isLoading={isLoading}
            data={filteredTableData}
            defaultSort="application_date"
            emptyStateMessage="No applications match selected filters"
            defaultSortDirection="DESC"
            columns={this.columns}
          />
        ) : (
          <p className="empty-text">
            There are no applications for this client.
          </p>
        )}
      </Tab.Pane>
    );
  }
}

export default TabApplications;
