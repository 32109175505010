import React, { Component } from 'react';
import { Pagination } from 'semantic-ui-react';

class TablePagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: this.props.activePage || 1,
    };
  }

  handlePageChange = (activePage, perPage) => {
    this.setState({
      activePage,
    });
    this.props.onPageChange(activePage, perPage);
  };

  render() {
    const { perPage, itemCount } = this.props;
    const { activePage } = this.state;
    return (
      itemCount > perPage && (
        <div
          className="pagination"
          style={{ display: 'flex', alignItems: 'center' }}>
          <span
            className="count"
            style={{ color: '#464646', marginRight: '1em' }}>
            Showing {activePage === 1 ? '1' : (activePage - 1) * perPage + 1}-
            {activePage * perPage < itemCount
              ? activePage * perPage
              : itemCount}{' '}
            of {itemCount}
          </span>

          <Pagination
            // defaultActivePage={this.props.activePage ? undefined : 1}
            activePage={activePage}
            firstItem={null}
            lastItem={null}
            totalPages={Math.ceil(itemCount / perPage) || 1}
            onPageChange={(e, data) => {
              this.handlePageChange(data.activePage, perPage);
            }}
          />
        </div>
      )
    );
  }
}

export default TablePagination;
