import React from 'react';

import { Icon, Popup } from 'semantic-ui-react';

import { AppContext } from '../AppContext/AppContext';
import { GlobalClientSearch } from '../';
import { history } from '../../history';

import './MainHeaderBar.scss';

class MainHeaderBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isVisible: false };
  }

  handleSelectCustomer = (customerId) => history.push(`/clients/${customerId}`);

  onShow = () => this.setState({ isVisible: true });
  onHide = () => this.setState({ isVisible: false });

  render() {
    const { user, logout } = this.props;
    const { isVisible } = this.state;
    return (
      <div className="app headerbar">
        <div className="headerbarLeft">
          <GlobalClientSearch onChange={this.handleSelectCustomer} />
        </div>
        <div className="headerbarRight">
          <span className="userGreeting">
            {user ? `${user.firstName} ${user.lastName}` : ''}
          </span>
          <button className="link" tabIndex="-1">
            <Popup
              trigger={<Icon name="caret down" />}
              on="click"
              basic
              position="bottom right"
              open={isVisible}
              onOpen={this.onShow}
              onClose={this.onHide}
              content={
                <div
                  style={{ cursor: 'pointer', width: '90px' }}
                  onClick={logout}>
                  Log Out
                </div>
              }
            />
          </button>
        </div>
      </div>
    );
  }
}

const WrappedMainHeaderBar = (props) => (
  <AppContext.Consumer>
    {({ user, logout }) => (
      <MainHeaderBar {...props} user={user} logout={logout} />
    )}
  </AppContext.Consumer>
);
export default WrappedMainHeaderBar;
