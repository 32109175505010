import React from 'react';

import {
  AppContext,
  HeaderBar,
  FormSelect,
  FormInput,
  Card,
  HeadOfHousehold,
  DropdownCounty,
  DropdownSchoolDistrict,
} from '../../components/';
import { Button, Checkbox } from 'semantic-ui-react';

import {
  ErrorHandler,
  Households as HouseholdsAPI,
  Clients as ClientsAPI,
} from '../../api';
import states from '../../states';
import { formatPhone, checkNestedValue } from '../../util';
import { email as isValidEmail } from '../../validation';

import './HouseholdAdd.scss';

class HouseholdAdd extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    const householdId = this.props.match.params.household_id;
    const isEditView = window.location.pathname.indexOf('edit') !== -1;

    this.state = {
      isEditView,
      householdId,
      isLoadingTable: false,
      tableData: [],
      pAddress: '',
      pAddress2: '',
      pCity: '',
      pState: '',
      pZip: '',
      mAddress: '',
      mAddress2: '',
      mCity: '',
      mState: '',
      mZip: '',
      county: '',
      district: '',
      phone: '',
      phone2: '',
      email: '',
      email2: '',
      canText: false,
      canText2: false,
      headOfHousehold: '',
      isFormValid: false,
    };
  }

  componentDidMount() {
    if (checkNestedValue('location.state.headOfHousehold', this.props)) {
      this.setState(this.props.location.state, () => this.checkIsFormValid());
    }

    this._isMounted = true;
    const { isEditView, householdId } = this.state;
    if (isEditView && !!householdId) {
      this._fetch(householdId);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async (id) => {
    this.safeSetState({ isLoadingTable: true });

    let res;
    try {
      res = await HouseholdsAPI.get(id);
    } catch (err) {
      ErrorHandler.error(err);
    }

    let newState = { isLoading: false };
    if (res && res.body) {
      const data = res.body;
      Object.assign(newState, {
        pAddress: data.physical_address_1,
        pAddress2: data.physical_address_2,
        pCity: data.physical_city,
        pState: data.physical_state,
        pZip: data.physical_zip,
        mAddress: data.mailing_address_1,
        mAddress2: data.mailing_address_2,
        mCity: data.mailing_city,
        mState: data.mailing_state,
        mZip: data.mailing_zip,
        county: data.county_of_residence && data.county_of_residence.id,
        district: data.school_district && data.school_district.id,
        phone: data.primary_phone,
        phone2: data.alt_phone,
        email: data.primary_email,
        email2: data.alt_email,
        canText: !!data.primary_phone_text,
        canText2: !!data.alt_phone_text,
        headOfHousehold: data.hoh && data.hoh.id,
      });
    }
    this.safeSetState(newState, () => this.checkIsFormValid());
  };

  checkIsFormValid = () => {
    let isFormValid = true;
    const requiredFields = [
      'headOfHousehold',
      'pAddress',
      'pState',
      'pZip',
      'pCity',
    ];
    requiredFields.forEach((field) => {
      if (!this.state[field]) {
        isFormValid = false;
      }
    });
    this.safeSetState({ isFormValid });
  };

  validateForm = () => {
    let isValid = true;
    const {
      email,
      email2,
      pAddress,
      pCity,
      pState,
      pZip,
      headOfHousehold,
    } = this.state;
    let newState = {};

    if (!headOfHousehold) {
      newState.headOfHouseholdError = 'Please fill out this field';
      isValid = false;
    }
    if (!pAddress) {
      newState.pAddressError = 'Please fill out this field';
      isValid = false;
    }
    if (!pState) {
      newState.pStateError = 'Please fill out this field';
      isValid = false;
    }
    if (!pZip) {
      newState.pZipError = 'Please fill out this field';
      isValid = false;
    }
    if (!pCity) {
      newState.pCityError = 'Please fill out this field';
      isValid = false;
    }
    if (email && !isValidEmail(email)) {
      newState.emailError = 'Please enter a valid email address';
      isValid = false;
    }
    if (email2 && !isValidEmail(email2)) {
      newState.email2Error = 'Please enter a valid email address';
      isValid = false;
    }

    if (!isValid) {
      this.safeSetState(newState);
    }

    return isValid;
  };

  handleSubmit = async () => {
    this.handleClearErrors();
    const shouldSubmit = this.validateForm();
    if (!shouldSubmit) {
      this.props.addToast({
        type: 'error',
        message: 'Please fill in the required fields.',
      });
      return;
    }
    this.safeSetState({ isLoading: true });
    const { isEditView } = this.state;

    let res;
    try {
      if (isEditView) {
        res = await HouseholdsAPI.update({ ...this.state });
      } else {
        res = await HouseholdsAPI.create({ ...this.state });
      }
    } catch (err) {
      this.props.addToast({
        type: 'error',
        message: 'An error has occurred. Please try your request again.',
      });
    }

    if (res && res.body && res.body.id) {
      this.handleSetHOH();
      const householdId = res.body.id;
      this.props.addToast({
        type: 'success',
        message: `Household #${householdId} has been added successfully!`,
      });
      this.handleGoToHousehold(householdId);
    }
    this.safeSetState({ isLoading: false });
  };

  handleSetHOH = async () => {
    let clientId = this.state.headOfHousehold;
    let hohRelationshipId = 80;
    try {
      await ClientsAPI.update({ clientId, hohRelationshipId });
    } catch (err) {
      this.props.addToast({
        type: 'error',
        message: 'An error has occurred. Please try your request again.',
      });
    }
  };

  handleSelectHOH = (id) =>
    this.setState({ headOfHousehold: id }, () => this.checkIsFormValid());

  handleFormFieldChange = (e) => {
    const fieldName = e.target.name;
    let value = e.target.value;

    switch (fieldName) {
      case 'phone':
      case 'phone2':
        value = value.replace(/[^0-9]+/g, '');
        break;
      default:
    }

    this.safeSetState({ [fieldName]: value }, () => this.checkIsFormValid());
  };

  handleFormSelectChange = (field, value) =>
    this.safeSetState({ [field]: value }, () => this.checkIsFormValid());

  handleFormToggleChange = (field) => {
    this.setState((prevState) =>
      Object.assign(prevState, { [field]: !prevState[field] }, () =>
        this.checkIsFormValid()
      )
    );
  };

  handleCancel = () => {
    const { isEditView, householdId } = this.state;
    if (isEditView) {
      this.props.history.push(`/households/${householdId}`);
    } else {
      this.props.history.push('/households');
    }
  };
  handleGoToHousehold = (id) => {
    this.props.history.push(`/households/${id}`);
  };
  handleGoToNewClient = () => {
    this.props.history.push({
      pathname: '/clients/add',
      state: { path: '/households/add', returnState: { ...this.state } },
    });
  };

  handleCopyAddress = () => {
    const { pAddress, pAddress2, pCity, pState, pZip } = this.state;
    this.safeSetState({
      mAddress: pAddress,
      mAddress2: pAddress2,
      mCity: pCity,
      mState: pState,
      mZip: pZip,
    });
  };

  handleClearErrors = () => {
    this.safeSetState({
      pAddressError: '',
      pAddress2Error: '',
      pCityError: '',
      pStateError: '',
      pZipError: '',
      mAddressError: '',
      mAddress2Error: '',
      mCityError: '',
      mStateError: '',
      mZipError: '',
      countyError: '',
      districtError: '',
      phoneError: '',
      phone2Error: '',
      emailError: '',
      email2Error: '',
    });
  };
  handleClearForm = () => {
    this.safeSetState({
      pAddress: '',
      pAddress2: '',
      pCity: '',
      pState: '',
      pZip: '',
      mAddress: '',
      mAddress2: '',
      mCity: '',
      mState: '',
      mZip: '',
      county: '',
      district: '',
      phone: '',
      phone2: '',
      email: '',
      email2: '',
      canText: '',
      canText2: '',
      headOfHousehold: '',
    });
  };

  render() {
    const {
      //--FormFields
      pAddress,
      pAddress2,
      pCity,
      pState,
      pZip,
      mAddress,
      mAddress2,
      mCity,
      mState,
      mZip,
      county,
      district,
      phone,
      phone2,
      email,
      email2,
      canText,
      canText2,
      headOfHousehold,
      //---FormField errors
      pAddressError,
      pAddress2Error,
      pCityError,
      pStateError,
      pZipError,
      mAddressError,
      mAddress2Error,
      mCityError,
      mStateError,
      mZipError,
      countyError,
      districtError,
      phoneError,
      phone2Error,
      emailError,
      email2Error,
      //--Other Stuff
      isFormValid,
      isLoading,
      isEditView,
      householdId,
    } = this.state;

    return (
      <div className="HouseholdAdd">
        <HeaderBar
          title={
            isEditView
              ? `Edit Household ${householdId || ''}`
              : 'Add New Household'
          }
          actions={
            <React.Fragment>
              <Button className="ghost dark" onClick={this.handleCancel}>
                CANCEL
              </Button>
              <Button
                primary
                loading={isLoading}
                disabled={!isFormValid}
                onClick={this.handleSubmit}>
                {isEditView ? 'UPDATE' : 'ADD'}
              </Button>
            </React.Fragment>
          }
        />
        <Card title="Household Info">
          <div className="form-row left-aligned">
            <HeadOfHousehold
              label="HEAD OF HOUSEHOLD"
              value={headOfHousehold}
              placeholder="Search by Client Name or Case #"
              handleNewClient={this.handleGoToNewClient}
              onChange={this.handleSelectHOH}
            />
          </div>
        </Card>
        <Card title="Residence Info" isLoading={isLoading}>
          <div className="form-row">
            <FormInput
              autoFocus
              required
              label="PHYSICAL ADDRESS"
              className="address-1"
              name="pAddress"
              onChange={this.handleFormFieldChange}
              value={pAddress}
              error={!!pAddressError}
              errorMessage={pAddressError}
            />
            <FormInput
              label="ADDRESS 2"
              className="address-2"
              name="pAddress2"
              onChange={this.handleFormFieldChange}
              value={pAddress2}
              error={pAddress2Error}
              errorMessage={pAddress2Error}
            />
            <FormInput
              required
              label="CITY"
              className="city"
              name="pCity"
              onChange={this.handleFormFieldChange}
              value={pCity}
              error={pCityError}
              errorMessage={pCityError}
            />
            <FormSelect
              required
              label="STATE"
              className="state"
              options={states}
              onChange={(e, opt) =>
                this.handleFormSelectChange('pState', opt.value)
              }
              value={pState}
              error={pStateError}
              errorMessage={pStateError}
            />
            <FormInput
              required
              label="ZIP CODE"
              className="zip"
              name="pZip"
              onChange={this.handleFormFieldChange}
              value={pZip}
              error={pZipError}
              errorMessage={pZipError}
            />
          </div>
          <div className="form-row">
            <FormInput
              label="MAILING ADDRESS"
              className="address-1"
              name="mAddress"
              onChange={this.handleFormFieldChange}
              value={mAddress}
              error={mAddressError}
              errorMessage={mAddressError}
            />
            <FormInput
              label="ADDRESS 2"
              className="address-2"
              name="mAddress2"
              onChange={this.handleFormFieldChange}
              value={mAddress2}
              error={mAddress2Error}
              errorMessage={mAddress2Error}
            />
            <FormInput
              label="CITY"
              className="city"
              name="mCity"
              onChange={this.handleFormFieldChange}
              value={mCity}
              error={mCityError}
              errorMessage={mCityError}
            />
            <FormSelect
              label="STATE"
              className="state"
              options={states}
              onChange={(e, opt) =>
                this.handleFormSelectChange('mState', opt.value)
              }
              value={mState}
              error={mStateError}
              errorMessage={mStateError}
            />
            <FormInput
              label="ZIP CODE"
              className="zip"
              name="mZip"
              onChange={this.handleFormFieldChange}
              value={mZip}
              error={mZipError}
              errorMessage={mZipError}
            />
          </div>
          <Button className="btn-link" onClick={this.handleCopyAddress}>
            COPY PHYSICAL ADDRESS
          </Button>
          <div className="form-row left-aligned bump-top">
            <DropdownCounty
              value={county}
              error={countyError}
              errorMessage={countyError}
              onChange={(e, opt) =>
                this.handleFormSelectChange('county', opt.value)
              }
            />
            <DropdownSchoolDistrict
              value={district}
              error={districtError}
              errorMessage={districtError}
              onChange={(e, opt) =>
                this.handleFormSelectChange('district', opt.value)
              }
            />
          </div>
        </Card>
        <Card title="Contact Info" isLoading={isLoading}>
          <div className="form-row">
            <div className="quarter">
              <FormInput
                label="PRIMARY PHONE NUMBER"
                name="phone"
                onChange={this.handleFormFieldChange}
                value={formatPhone(phone) || ''}
                error={phoneError}
                errorMessage={phoneError}
                maxLength={14}
                placeholder="Enter numbers only"
              />
              <Checkbox
                label="Can text primary phone"
                onChange={() => this.handleFormToggleChange('canText')}
                checked={canText}
              />
            </div>
            <div className="quarter">
              <FormInput
                label="ALTERNATE PHONE NUMBER"
                name="phone2"
                onChange={this.handleFormFieldChange}
                value={formatPhone(phone2) || ''}
                error={phone2Error}
                errorMessage={phone2Error}
                placeholder="Enter numbers only"
                maxLength={14}
              />
              <Checkbox
                label="Can text alternate phone"
                onChange={() => this.handleFormToggleChange('canText2')}
                checked={canText2}
              />
            </div>
            <FormInput
              label="EMAIL ADDRESS"
              className="quarter"
              name="email"
              onChange={this.handleFormFieldChange}
              value={email}
              error={emailError}
              errorMessage={emailError}
            />
            <FormInput
              label="ALTERNATE EMAIL ADDRESS"
              className="quarter"
              name="email2"
              onChange={this.handleFormFieldChange}
              value={email2}
              error={email2Error}
              errorMessage={email2Error}
            />
          </div>
        </Card>
      </div>
    );
  }
}

const WrappedHouseholdAdd = (props) => (
  <AppContext.Consumer>
    {({ user, addToast }) => (
      <HouseholdAdd {...props} user={user} addToast={addToast} />
    )}
  </AppContext.Consumer>
);

export default WrappedHouseholdAdd;
