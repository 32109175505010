import React from 'react';

import {
  AppContext,
  DataTableSP,
  HeaderBar,
  FormSelect,
} from '../../components/';
import { format } from 'date-fns';
import { Icon, Button } from 'semantic-ui-react';

import { ErrorHandler, Households as HouseholdsAPI, Users } from '../../api';
import { debounce } from '../../util';

import './Households.scss';

// these are the only values we can use to sort on.
// if the API is throwing a 500 and saying invalid sort column, then
// it is either not in this list, or this list needs to be updated.
const allowedSortColumns = [
  'id',
  'physical_address_1',
  'physical_address_2',
  'physical_city',
  'physical_state',
  'physical_zip',
  'mailing_address_1',
  'mailing_address_2',
  'mailing_city',
  'mailing_state',
  'mailing_zip',
  'primary_phone',
  'primary_phone_text',
  'alt_phone',
  'alt_phone_text',
  'primary_email',
  'alt_email',
  'deleted_at',
  'county_of_residence_id',
  'school_district_id',
  'hoh_id',
  'created_at',
  'updated_at',
];

const checkCanSort = (col) => {
  return allowedSortColumns.includes(col);
};

const tableColumns = [
  {
    canSort: checkCanSort('id'),
    name: 'ID',
    dataKey: 'id',
    description: '',
    cellProps: { collapsing: true },
  },
  {
    canSort: checkCanSort('hoh_last_name'),
    name: 'HOH Name',
    dataKey: 'hoh_last_name',
    description: '',
    formatter: (val, row) =>
      row && [row.hoh_last_name || '', row.hoh_first_name || ''].join(', '),
  },
  {
    canSort: checkCanSort('members_count'),
    name: 'Members',
    dataKey: 'members_count',
    description: '',
  },
  {
    canSort: checkCanSort('physical_address_1'),
    name: 'Location',
    dataKey: 'physical_address_1',
    description: '',
    formatter: (val, row) =>
      row &&
      [
        row.physical_address_1 || '',
        row.physical_city || '',
        row.physical_state || '',
      ].join(', '),
  },
  {
    canSort: checkCanSort('has_pending_apps'),
    name: 'Pending Apps',
    dataKey: 'has_pending_apps',
    description: '',
    style: { textAlign: 'center' },
    formatter: (val) => val && <Icon className="green" name="check" />,
  },
  {
    canSort: checkCanSort('last_updated'),
    name: 'Last Updated',
    dataKey: 'last_updated',
    description: '',
    formatter: (val) => (val ? format(val, 'M/D/YYYY') : ''),
    style: { textAlign: 'right' },
  },
];

class Households extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isLoadingTable: false,
      tableData: [],
      navigators: [],
      selectedNavigator: props.user.id,
      // ====== //
      pageNumber: 1,
      searchToken: '',
      sortBy: 'updated_at',
      sortDirection: 'DESC',
      recordCount: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetchNavigators();
    this._fetch();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetchNavigators = async () => {
    this.safeSetState({ isLoadingNavigators: true });
    let res;
    try {
      res = await Users.list();
    } catch (err) {
      ErrorHandler.error(err);
    }

    const { user } = this.props;
    let navigators = [
      { text: 'All Households', value: 'ALL' },
      { text: 'My Households', value: user.id },
    ];

    if (res?.body?.length) {
      res.body.forEach((nav) => {
        if (nav.id !== user.id) {
          navigators.push({
            text: [nav.first_name, nav.last_name].filter((f) => !!f).join(' '),
            value: nav.id,
          });
        }
      });
    }

    this.safeSetState({ navigators, isLoadingNavigators: false });
  };

  _fetch = async () => {
    this.safeSetState({ isLoadingTable: true });

    const {
      pageNumber,
      searchToken,
      sortBy,
      sortDirection,
      selectedNavigator,
    } = this.state;

    let res;
    try {
      res = await HouseholdsAPI.all({
        pageNumber,
        search: searchToken,
        sortBy,
        sortDirection,
        navigatorId: selectedNavigator,
      });
    } catch (err) {
      ErrorHandler.error(err);
    }

    let Households = [];

    let recordCount = 0;
    let updatedPageNumber = pageNumber;
    let updatedSortBy = sortBy;
    let updatedSortDirection = sortDirection;

    if (res?.body) {
      const body = res.body;
      if (body?.data?.length) {
        Households = body.data.map((row) => {
          row.membersString = row.members
            .map((member) =>
              [member.first_name || '', member.last_name || ''].join(' ')
            )
            .join(' ');
          return row;
        });
      }
      if (body?.meta) {
        const meta = body.meta || {};
        recordCount = meta.record_count;
        updatedPageNumber = meta.offset ? meta.offset / 50 + 1 : 1;
        updatedSortBy = meta.sort_by;
        updatedSortDirection = meta.sort_direction;
      }
    }
    this.safeSetState({
      tableData: Households,
      isLoadingTable: false,
      recordCount,
      pageNumber: updatedPageNumber,
      sortBy: updatedSortBy,
      sortDirection: updatedSortDirection,
    });
  };

  handleTicketSelect = (row) => {
    this.props.history.push(`/households/${row.id}`);
  };

  handleSelectNavigator = (e, { value }) => {
    this.safeSetState({ selectedNavigator: value }, this._fetch);
  };

  handleAddHousehold = () => {
    this.props.history.push('/households/add');
  };

  handleFetchPage = (pageNumber) => {
    this.safeSetState({ pageNumber }, () => {
      this._fetch();
    });
  };

  debouncedFetch = debounce(this._fetch, 1000);

  handleSearch = (searchToken) => {
    this.safeSetState({ searchToken, pageNumber: 1 }, () => {
      this.debouncedFetch();
    });
  };
  handleSort = (sortBy, sortDirection) => {
    this.safeSetState({ sortBy, sortDirection, pageNumber: 1 }, () => {
      this._fetch();
    });
  };

  render() {
    const {
      isLoadingTable,
      tableData,
      navigators,
      selectedNavigator,
      sortBy,
      sortDirection,
      pageNumber,
      recordCount,
      searchToken,
    } = this.state;

    return (
      <div className="Households">
        <HeaderBar
          title="Households"
          actions={
            <Button className="primary" onClick={this.handleAddHousehold}>
              NEW HOUSEHOLD
            </Button>
          }
        />
        <DataTableSP
          onGoToPage={this.handleFetchPage}
          onSearch={this.handleSearch}
          onSort={this.handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          pageNumber={pageNumber}
          recordCount={recordCount}
          searchToken={searchToken}
          // ======================== //
          tableName="Households"
          isLoading={isLoadingTable}
          data={tableData}
          emptyStateMessage="No households match selected filters"
          additionalFilters={
            <FormSelect
              options={navigators}
              style={{ position: 'absolute', left: 0 }}
              onChange={this.handleSelectNavigator}
              value={selectedNavigator}
            />
          }
          searchPlaceholder="Search by client name"
          onRowClick={this.handleTicketSelect}
          columns={tableColumns}
        />
      </div>
    );
  }
}

const WrappedHouseholds = (props) => (
  <AppContext.Consumer>
    {({ user }) => <Households {...props} user={user} />}
  </AppContext.Consumer>
);

export default WrappedHouseholds;
