import React from 'react';

import {
  DeleteConfirmModal,
  TableRowOptionPopup,
  DataTable,
  HeaderBar,
  Card,
} from '../../components/';
import { Tab, Button } from 'semantic-ui-react';

import { AppContext } from '../../components/AppContext/AppContext';

import { ErrorHandler, Users as UserAPI } from '../../api';
import EditUserModal from './EditUserModal';

import './Users.scss';

class Users extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      isLoadingTable: false,
      tableData: [],
      isVisibleModal: false,
      isVisibleDeleteModal: false,
      selectedUser: null,
      deleteUserName: '',
      deleteUserId: '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoadingTable: true });
    let res;
    try {
      res = await UserAPI.all();
    } catch (err) {
      ErrorHandler.error(err);
    }

    let users = [];
    if (res && res.body && res.body.length > 0) {
      users = res.body;
    }
    this.safeSetState({ tableData: users, isLoadingTable: false });
  };

  handleTicketSelect = row => {
    this.props.history.push(`/admin/users/${row.id}`);
  };

  handleDisableUser = async row => {
    let res;
    try {
      res = await UserAPI.disable({ userId: row.id });
    } catch (err) {
      this.props.addToast({
        type: 'error',
        message:
          'There was a problem submitting your changes, please try again.',
      });
      ErrorHandler.error(err);
    }
    if (res) {
      this.props.addToast({
        type: 'success',
        message: 'User disabled successfully',
      });
      this._fetch();
    }
  };
  handleEnableUser = async row => {
    let res;
    try {
      res = await UserAPI.enable({ userId: row.id });
    } catch (err) {
      this.props.addToast({
        type: 'error',
        message:
          'There was a problem submitting your changes, please try again.',
      });
      ErrorHandler.error(err);
    }
    if (res) {
      this.props.addToast({
        type: 'success',
        message: 'User enabled successfully',
      });
      this._fetch();
    }
  };
  handleResendInvite = async row => {
    let res;
    try {
      res = await UserAPI.resendInvite({ id: row.id });
    } catch (err) {
      this.props.addToast({
        type: 'error',
        message:
          'There was a problem submitting your changes, please try again.',
      });
      ErrorHandler.error(err);
    }
    if (res) {
      this.props.addToast({
        type: 'success',
        message: 'Invite sent successfully',
      });
      this._fetch();
    }
  };
  handleDeleteUser = async () => {
    const { deleteUserId } = this.state;
    let res;
    try {
      res = await UserAPI.delete({ userId: deleteUserId });
    } catch (err) {
      this.props.addToast({
        type: 'error',
        message:
          'There was a problem submitting your changes, please try again.',
      });
      ErrorHandler.error(err);
    }
    if (res) {
      this.props.addToast({
        type: 'success',
        message: 'User has been successfully deleted',
      });
      this._fetch();
    }
    this.handleHideDeleteModal();
  };

  handleEditUser = row => {
    this.safeSetState({
      isVisibleModal: true,
      modalType: 'edit',
      selectedUser: row,
    });
  };
  handleInviteUser = () => {
    this.safeSetState({
      isVisibleModal: true,
      modalType: 'invite',
      selectedUser: null,
    });
  };

  handleEditUserClose = () => {
    this.safeSetState({ isVisibleModal: false, selectedUser: null }, () =>
      this._fetch()
    );
  };

  handleTabChange = (e, { activeIndex }) => this.safeSetState({ activeIndex });

  handleShowDeleteModal = row => {
    this.safeSetState({
      deleteUserName: [row.first_name, row.last_name]
        .filter(f => !!f)
        .join(' '),
      deleteUserId: row.id,
      isVisibleDeleteModal: true,
    });
  };
  handleHideDeleteModal = () =>
    this.safeSetState({
      deleteUserName: '',
      deleteUserId: '',
      isVisibleDeleteModal: false,
    });

  render() {
    const {
      isLoadingTable,
      tableData,
      selectedUser,
      isVisibleModal,
      modalType,
      activeIndex,
      isVisibleDeleteModal,
      deleteUserName,
    } = this.state;

    const { first_name, last_name, email_address, role, id } =
      selectedUser || {};
    const roleId = role && role.id;

    const tabName = ['active', 'invited', 'disabled'][activeIndex];
    const tableColumns = [
      {
        name: 'Name',
        dataKey: 'last_name',
        formatter: (val, row) => {
          let res = [];
          if (val) {
            res.push(val);
          }
          if (row && row.first_name) {
            res.push(row.first_name);
          }
          return res.join(', ');
        },
      },
      {
        name: 'Email',
        dataKey: 'email_address',
      },
      {
        name: 'Role',
        dataKey: 'role.name',
      },
      {
        name: '',
        dataKey: 'id',
        formatter: (val, row) => (
          <TableRowOptionPopup
            row={row}
            options={[
              (tabName === 'active' || tabName === 'invited') && {
                action: this.handleEditUser,
                label: 'Edit User',
              },
              tabName === 'active' && {
                action: this.handleDisableUser,
                label: 'Disable User',
              },
              tabName === 'disabled' && {
                action: this.handleEnableUser,
                label: 'Enable User',
              },
              tabName === 'invited' && {
                action: this.handleResendInvite,
                label: 'Resend Invite',
              },
              { action: this.handleShowDeleteModal, label: 'Delete User' },
            ].filter(f => !!f)}
          />
        ),
        style: { textAlign: 'right' },
      },
    ];

    return (
      <div className="Users">
        <DeleteConfirmModal
          isVisible={isVisibleDeleteModal}
          onClose={this.handleHideDeleteModal}
          onDelete={this.handleDeleteUser}
          message={
            <p>
              <strong>
                You are about to delete {deleteUserName} from Users.{' '}
              </strong>
              This user will be permanently removed from the system.
            </p>
          }
        />
        <EditUserModal
          modalType={modalType}
          onClose={this.handleEditUserClose}
          firstName={first_name}
          lastName={last_name}
          email={email_address}
          role={roleId}
          id={id}
          isVisible={isVisibleModal}
        />
        <HeaderBar
          title="Users"
          actions={
            <Button className="primary" onClick={this.handleInviteUser}>
              INVITE USER
            </Button>
          }
        />
        <Card className="nested-no-style">
          <Tab
            style={{ width: '100%' }}
            menu={{ secondary: true, pointing: true }}
            activeIndex={activeIndex}
            onTabChange={this.handleTabChange}
            panes={[
              {
                menuItem: 'ACTIVE',
                render: () => (
                  <FilteredTableTab
                    data={tableData.filter(row => row.status === 'active')}
                    columns={tableColumns}
                    isLoading={isLoadingTable}
                  />
                ),
              },
              {
                menuItem: 'INVITED',
                render: () => (
                  <FilteredTableTab
                    data={tableData.filter(row => row.status === 'invited')}
                    columns={tableColumns}
                    isLoading={isLoadingTable}
                  />
                ),
              },
              {
                menuItem: 'DISABLED',
                render: () => (
                  <FilteredTableTab
                    data={tableData.filter(row => row.status === 'disabled')}
                    columns={tableColumns}
                    isLoading={isLoadingTable}
                  />
                ),
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

const FilteredTableTab = ({ data, columns, isLoading }) => (
  <Tab.Pane attached={false}>
    <DataTable
      tableName="Users"
      isLoading={isLoading}
      data={data}
      defaultSort="last_name"
      defaultSortDirection="ASC"
      searchColumns={['first_name', 'last_name', 'email_address']}
      columns={columns}
    />
  </Tab.Pane>
);

const WrappedUsers = props => (
  <AppContext.Consumer>
    {({ addToast }) => <Users {...props} addToast={addToast} />}
  </AppContext.Consumer>
);

export default WrappedUsers;
