import React from 'react';

import { DataTable, HeaderBar } from '../../components/';
import { format } from 'date-fns';

import { ErrorHandler, Admin } from '../../api';

import './Settings.scss';

const tableColumns = [
  {
    name: 'Setting Name',
    dataKey: 'name',
    description: '',
  },
  {
    name: 'Last Updated',
    dataKey: 'last_updated',
    description: '',
    formatter: val => (val ? format(val, 'M/D/YYYY') : ''),
    style: { textAlign: 'right' },
  },
];

const pageFilters = {};

export default class Settings extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isLoadingTable: false,
      tableData: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoadingTable: true });
    let res;
    try {
      res = await Admin.allSettings();
    } catch (err) {
      ErrorHandler.error(err);
    }

    let settings = [];
    if (res && res.body && res.body.length > 0) {
      settings = res.body;
    }
    this.safeSetState({ tableData: settings, isLoadingTable: false });
  };

  handleTicketSelect = row => {
    this.props.history.push(`/admin/settings/${row.id}`);
  };

  render() {
    const { isLoadingTable, tableData } = this.state;

    return (
      <div className="Settings">
        <HeaderBar title="Settings" />
        <DataTable
          tableName="Settings"
          isLoading={isLoadingTable}
          data={tableData}
          defaultSort="name"
          defaultSortDirection="ASC"
          onRowClick={this.handleTicketSelect}
          searchColumns={['name']}
          pageFilters={pageFilters}
          columns={tableColumns}
        />
      </div>
    );
  }
}
