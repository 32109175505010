import superagent from 'superagent';
import intercept from 'superagent-intercept';

import { history } from './history';

import { environment } from './environment';
import { jwtDecode } from './util';

const API_BASE = environment.current.apiBase;

let token = '';

// set number of results for server side pagination of datatables
const PAGE_SIZE = 50;

const getOffset = (page) => {
  return PAGE_SIZE * (page - 1);
};

const defaultVersion = '/v1';
const apiVersion = {
  Account: {
    login: '',
  },
  Applications: {
    all: '',
  },
  Households: {
    all: '/v2',
    create: '',
    get: '',
    update: '',
    composition: '',
    compAddClient: '',
    compRemoveClient: '',
    applications: '',
    applicationGet: '',
    applicationAdd: '',
    applicationRemove: '',
    applicationEdit: '',
    notes: '',
    noteAdd: '',
    navigator_id: '',
    noteRemove: '',
    noteUpdate: '',
  },
  Users: {
    all: '',
    get: '',
    new: '',
    list: '',
    update: '',
    delete: '',
    resendInvite: '',
    passwordReset: '',
    finalizeAccount: '',
    sendRecoveryEmail: '',
  },
  Reports: {
    notes: '/v2',
    clients: '/v2',
    applications: '/v2',
    households: '/v2',
  },
  Clients: {
    all: '/v2',
    get: '',
    new: '',
    update: '',
    delete: '',
    applications: '',
  },
  Admin: {
    allSettings: '',
    getSetting: '',
    updateSettings: '',
  },
  Dashboard: {
    metrics: '',
    applications: '',
  },
};

const setToken = (_token) => {
  token = _token;
  localStorage.setItem('jwtToken', _token);
};

const getToken = () => {
  token = token || localStorage.getItem('jwtToken');
  if (token) {
    return token;
  } else {
    return false;
  }
};

const checkIsVerified = () => {
  let isVerified = false;
  const _token = getToken();
  if (_token) {
    const decoded = jwtDecode(_token);
    const expiration = (decoded && decoded.exp && decoded.exp * 1000) || false;
    isVerified = expiration && expiration - Date.now() > 0;
  }
  return isVerified;
};

const checkIsAdmin = () => {
  let user = localStorage.getItem('user');
  user = user ? JSON.parse(user) : user;
  return !!user && !!user.roleId && user.roleId === 1;
};

// if we have a token set, use it on the request
const tokenPlugin = (req) => {
  getToken();
  if (token) {
    req.set('Authorization', `Bearer ${token}`);
  }
};

// if we get a token on response, use it, or if 401, redirect to login
const authCheckIntercept = intercept((err, res) => {
  if (res && res.headers && res.headers.brightpoint_auth) {
    setToken(res.headers.brightpoint_auth);
  }
  if (err && err.response && err.response.status === 401) {
    if (window.location.pathname !== '/login') {
      history.replace('/login');
    }
  }
});

// if we get a token on response, use it, no redirect
/*
const authCheckInterceptNoRedirect = intercept((err, res) => {
  if (res && res.headers && res.headers.authorization) {
    setToken(res.headers.authorization);
  }
});
*/

/*
function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

const retryCallback = async (err, res) => {
  let shouldRetry = false;
  console.log({ err, res });
  if (res.status === 202) {
    shouldRetry = true;
    //await sleep(5000);
  }
  return shouldRetry;
};
*/

const request = {
  post: (url, body) =>
    superagent
      .post(`${API_BASE}${url}`, body)
      .use(tokenPlugin)
      .use(authCheckIntercept),
  put: (url, body) =>
    superagent
      .put(`${API_BASE}${url}`, body)
      .use(tokenPlugin)
      .use(authCheckIntercept),
  get: (url) =>
    superagent
      .get(`${API_BASE}${url}`)
      .use(tokenPlugin)
      .use(authCheckIntercept),
  /*getBigFile: (url) =>
    superagent
      .get(`${API_BASE}${url}`)
      .timeout({
           response: 5000, // Wait 5 seconds for the server to start sending,
           deadline: 300000, // but allow 5 minutes for the file to finish loading.
         })
       .retry(60, retryCallback) // waiting 5 minutes (60 * 5s)
      .use(tokenPlugin)
      .use(authCheckIntercept),*/
  delete: (url, payload) =>
    superagent
      .delete(`${API_BASE}${url}`, payload)
      .use(tokenPlugin)
      .use(authCheckIntercept),
};

/*******************************************************************************
 ****** Account
 *******************************************************************************/

const Account = {
  login: ({ email, password }) =>
    request.post(`${apiVersion.Account.login || defaultVersion}/login`, {
      email_address: email,
      password,
    }),
};

/*******************************************************************************
 ****** Application
 *******************************************************************************/

const Applications = {
  all: () =>
    request.get(
      `${apiVersion.Applications.all || defaultVersion}/applications`
    ),
};

/*******************************************************************************
 ****** Clients
 *******************************************************************************/

const Clients = {
  all: ({ pageNumber, search, sortBy, sortDirection }) =>
    request.get(
      `${apiVersion.Clients.all ||
        defaultVersion}/clients?${filtersToQueryParams({
        limit: PAGE_SIZE,
        search,
        offset: getOffset(pageNumber),
        sort_by: sortBy,
        sort_direction: sortDirection,
      })}`
    ),
  get: (clientId) =>
    request.get(
      `${apiVersion.Clients.get || defaultVersion}/clients/${clientId}`
    ),
  new: ({
    firstName,
    middleName,
    lastName,
    sex,
    dob,
    navigator_id,
    raceId,
    preferredLangId,
    hohRelationshipId,
    bptAuthorizedRep,
    hasInternet,
    hasPrimaryCare,
    consentNotes,
    householdId,
    isBptEmployee,
    isBptEmployeeFamily,
    bptFamilyRelationship,
    bptFamilyMemberName,
    ssn,
    caseNumber,
    ridNumber,
  }) =>
    request.post(`${apiVersion.Clients.new || defaultVersion}/clients`, {
      client: {
        case_number: caseNumber,
        rid_number: ridNumber,
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
        sex: sex,
        dob: dob,
        navigator_id: navigator_id,
        race_id: raceId,
        preferred_lang_id: preferredLangId,
        hoh_relationship_id: hohRelationshipId,
        bpt_authorized_rep: bptAuthorizedRep,
        has_internet: hasInternet,
        has_primary_care: hasPrimaryCare,
        consent_notes: consentNotes,
        household_id: householdId,
        is_bpt_employee: isBptEmployee,
        is_bpt_employee_family: isBptEmployeeFamily,
        bpt_family_relationship: bptFamilyRelationship,
        bpt_family_member_name: bptFamilyMemberName,
        ssn: ssn,
      },
    }),
  update: ({
    clientId,
    firstName,
    middleName,
    lastName,
    sex,
    dob,
    navigator_id,
    raceId,
    preferredLangId,
    hohRelationshipId,
    bptAuthorizedRep,
    hasInternet,
    hasPrimaryCare,
    consentNotes,
    householdId,
    isBptEmployee,
    isBptEmployeeFamily,
    bptFamilyRelationship,
    bptFamilyMemberName,
    ssn,
    caseNumber,
    ridNumber,
  }) =>
    request.put(
      `${apiVersion.Clients.update || defaultVersion}/clients/${clientId}`,
      {
        client: {
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          sex: sex,
          dob: dob,
          navigator_id: navigator_id,
          race_id: raceId,
          preferred_lang_id: preferredLangId,
          hoh_relationship_id: hohRelationshipId,
          bpt_authorized_rep: bptAuthorizedRep,
          has_internet: hasInternet,
          has_primary_care: hasPrimaryCare,
          consent_notes: consentNotes,
          household_id: householdId,
          is_bpt_employee: isBptEmployee,
          is_bpt_employee_family: isBptEmployeeFamily,
          bpt_family_relationship: bptFamilyRelationship,
          bpt_family_member_name: bptFamilyMemberName,
          ssn: ssn,
          case_number: caseNumber,
          rid_number: ridNumber,
        },
      }
    ),
  delete: ({ clientId }) =>
    request.delete(
      `${apiVersion.Clients.delete || defaultVersion}/clients/${clientId}`
    ),
  applications: ({ clientId }) =>
    request.get(
      `${apiVersion.Clients.applications ||
        defaultVersion}/clients/${clientId}/applications`
    ),
  notes: ({ clientId }) =>
    request.get(
      `${apiVersion.Clients.notes || defaultVersion}/clients/${clientId}/notes`
    ),
  noteAdd: ({ clientId, noteId, payload }) =>
    request.post(
      `${apiVersion.Clients.noteAdd ||
        defaultVersion}/clients/${clientId}/notes`,
      payload
    ),
  noteUpdate: ({ clientId, noteId, payload }) =>
    request.put(
      `${apiVersion.Clients.noteUpdate ||
        defaultVersion}/clients/${clientId}/notes/${noteId}`,
      payload
    ),
  noteRemove: ({ clientId, noteId }) =>
    request.delete(
      `${apiVersion.Clients.noteRemove ||
        defaultVersion}/clients/${clientId}/notes/${noteId}`
    ),
};

/*******************************************************************************
 ****** Households
 *******************************************************************************/

const Households = {
  all: ({ pageNumber, search, sortBy, sortDirection, navigatorId }) =>
    request.get(
      `${apiVersion.Households.all ||
        defaultVersion}/households?${filtersToQueryParams({
        limit: PAGE_SIZE,
        search,
        offset: getOffset(pageNumber),
        sort_by: sortBy,
        sort_direction: sortDirection,
        navigator_id: navigatorId,
      })}`
    ),

  create: ({
    pAddress,
    pAddress2,
    pCity,
    pState,
    pZip,
    mAddress,
    mAddress2,
    mCity,
    mState,
    mZip,
    county,
    district,
    navigator_id,
    phone,
    phone2,
    email,
    email2,
    canText,
    canText2,
    headOfHousehold,
  }) => {
    const payload = {
      physical_address_1: pAddress,
      physical_address_2: pAddress2,
      physical_city: pCity,
      physical_state: pState,
      physical_zip: pZip,
      mailing_address_1: mAddress,
      mailing_address_2: mAddress2,
      mailing_city: mCity,
      mailing_state: mState,
      mailing_zip: mZip,
      navigator_id: navigator_id,
      primary_phone: phone,
      primary_phone_text: canText,
      alt_phone: phone2,
      alt_phone_text: canText2,
      primary_email: email,
      alt_email: email2,
      county_of_residence_id: county,
      school_district_id: district,
      hoh_id: headOfHousehold,
    };
    return request.post(
      `${apiVersion.Households.create || defaultVersion}/households`,
      payload
    );
  },
  update: ({
    pAddress,
    pAddress2,
    pCity,
    pState,
    pZip,
    mAddress,
    mAddress2,
    mCity,
    mState,
    mZip,
    navigator_id,
    county,
    district,
    phone,
    phone2,
    email,
    email2,
    canText,
    canText2,
    headOfHousehold,
    householdId,
  }) => {
    const payload = {
      physical_address_1: pAddress,
      physical_address_2: pAddress2,
      physical_city: pCity,
      physical_state: pState,
      physical_zip: pZip,
      mailing_address_1: mAddress,
      mailing_address_2: mAddress2,
      mailing_city: mCity,
      mailing_state: mState,
      mailing_zip: mZip,
      primary_phone: phone,
      navigator_id: navigator_id,
      primary_phone_text: canText,
      alt_phone: phone2,
      alt_phone_text: canText2,
      primary_email: email,
      alt_email: email2,
      county_of_residence_id: county,
      school_district_id: district,
      hoh_id: headOfHousehold,
    };
    return request.put(
      `${apiVersion.Households.update ||
        defaultVersion}/households/${householdId}`,
      payload
    );
  },
  get: (id) =>
    request.get(
      `${apiVersion.Households.get || defaultVersion}/households/${id}`
    ),
  composition: ({ householdId }) =>
    request.get(
      `${apiVersion.Households.composition ||
        defaultVersion}/households/${householdId}/composition`
    ),
  compAddClient: ({ householdId, memberId }) =>
    request.put(
      `${apiVersion.Households.compAddClient ||
        defaultVersion}/households/${householdId}/composition`,
      {
        member_id: memberId,
      }
    ),
  compRemoveClient: ({ householdId, memberId }) =>
    request.delete(
      `${apiVersion.Households.compRemoveClient ||
        defaultVersion}/households/${householdId}/composition`,
      {
        member_id: memberId,
      }
    ),
  applications: ({ householdId }) =>
    request.get(
      `${apiVersion.Households.applications ||
        defaultVersion}/households/${householdId}/applications`
    ),
  applicationGet: ({ householdId, applicationId }) =>
    request.get(
      `${apiVersion.Households.applicationGet ||
        defaultVersion}/households/${householdId}/applications/${applicationId}`
    ),
  applicationAdd: ({ householdId, payload }) =>
    request.post(
      `${apiVersion.Households.applicationAdd ||
        defaultVersion}/households/${householdId}/applications`,
      payload
    ),
  applicationRemove: ({ householdId, applicationId }) =>
    request.delete(
      `${apiVersion.Households.applicationRemove ||
        defaultVersion}/households/${householdId}/applications/${applicationId}`
    ),
  applicationEdit: ({ householdId, payload, applicationId }) =>
    request.put(
      `${apiVersion.Households.applicationEdit ||
        defaultVersion}/households/${householdId}/applications/${applicationId}`,
      payload
    ),
  notes: ({ householdId }) =>
    request.get(
      `${apiVersion.Households.notes ||
        defaultVersion}/households/${householdId}/notes`
    ),
  noteAdd: ({ householdId, payload }) =>
    request.post(
      `${apiVersion.Households.noteAdd ||
        defaultVersion}/households/${householdId}/notes`,
      payload
    ),
  noteUpdate: ({ householdId, noteId, payload }) =>
    request.put(
      `${apiVersion.Households.noteUpdate ||
        defaultVersion}/households/${householdId}/notes/${noteId}`,
      payload
    ),
  noteRemove: ({ householdId, noteId }) =>
    request.delete(
      `${apiVersion.Households.noteRemove ||
        defaultVersion}/households/${householdId}/notes/${noteId}`,
      {
        note_id: noteId,
      }
    ),
};

/*******************************************************************************
 ****** Users
 *******************************************************************************/

const Users = {
  all: () => request.get(`${apiVersion.Users.all || defaultVersion}/users`),
  get: ({ userId }) =>
    request.get(`${apiVersion.Users.get || defaultVersion}/users/${userId}`),
  list: () =>
    request.get(`${apiVersion.Users.list || defaultVersion}/users/list`),
  new: ({ firstName, lastName, email, role }) =>
    request.post(`${apiVersion.Users.new || defaultVersion}/users`, {
      user: {
        first_name: firstName,
        last_name: lastName,
        email_address: email,
        role_id: role,
      },
    }),
  update: ({ userId, firstName, lastName, email, role }) =>
    request.put(
      `${apiVersion.Users.update || defaultVersion}/users/${userId}`,
      {
        user: {
          first_name: firstName,
          last_name: lastName,
          email_address: email,
          role_id: role,
        },
      }
    ),
  disable: ({ userId }) =>
    request.put(
      `${apiVersion.Users.update || defaultVersion}/users/${userId}`,
      {
        user: {
          status: 'disabled',
        },
      }
    ),
  enable: ({ userId }) =>
    request.put(
      `${apiVersion.Users.update || defaultVersion}/users/${userId}`,
      {
        user: {
          status: 'active',
        },
      }
    ),
  delete: ({ userId }) =>
    request.delete(
      `${apiVersion.Users.delete || defaultVersion}/users/${userId}`
    ),
  passwordReset: (data) =>
    request.post(
      `${apiVersion.Users.passwordReset ||
        defaultVersion}/users/reset_password`,
      data
    ),
  finalizeAccount: (data) =>
    request.post(
      `${apiVersion.Users.finalizeAccount ||
        defaultVersion}/users/finalize_account`,
      data
    ),
  sendRecoveryEmail: ({ email }) =>
    request.post(
      `${apiVersion.Users.sendRecoveryEmail ||
        defaultVersion}/users/send_recovery_email`,
      { email_address: email }
    ),
  resendInvite: ({ id }) =>
    request.post(
      `${apiVersion.Users.resendInvite || defaultVersion}/users/resend_invite`,
      {
        id,
      }
    ),
};

/*******************************************************************************
 ****** Reports
 *******************************************************************************/

const Reports = {
  notes: (start_date, end_date) =>
    request.get(
      `${apiVersion.Reports.notes || defaultVersion}/admin/reports/notes${
        start_date ? `?start_date=${start_date}` : ''
      }${end_date ? `&end_date=${end_date}` : ''}`
    ),
  clients: (start_date, end_date) =>
    request.get(
      `${apiVersion.Reports.clients || defaultVersion}/admin/reports/clients${
        start_date ? `?start_date=${start_date}` : ''
      }${end_date ? `&end_date=${end_date}` : ''}`
    ),
  applications: (start_date, end_date) =>
    request.get(
      `${apiVersion.Reports.applications ||
        defaultVersion}/admin/reports/applications${
        start_date ? `?start_date=${start_date}` : ''
      }${end_date ? `&end_date=${end_date}` : ''}`
    ),
  households: (start_date, end_date) =>
    request.get(
      `${apiVersion.Reports.households ||
        defaultVersion}/admin/reports/households${
        start_date ? `?start_date=${start_date}` : ''
      }${end_date ? `&end_date=${end_date}` : ''}`
    ),
};

/******************************************************************************* 
/*******************************************************************************
 ****** Admin
 *******************************************************************************/

const Admin = {
  allSettings: () =>
    request.get(
      `${apiVersion.Admin.allSettings || defaultVersion}/admin/settings`
    ),
  getSetting: ({ settingId }) =>
    request.get(
      `${apiVersion.Admin.getSetting ||
        defaultVersion}/admin/settings/${settingId}`
    ),
  updateSettings: ({ settingId, settings }) =>
    request.put(
      `${apiVersion.Admin.updateSettings ||
        defaultVersion}/admin/settings/${settingId}`,
      { setting: { setting_options_attributes: settings } }
    ),
};

/*******************************************************************************
 ****** Dashboard
 *******************************************************************************/

const Dashboard = {
  metrics: () =>
    request.get(
      `${apiVersion.Dashboard.metrics || defaultVersion}/dashboard/metrics`
    ),
  applications: () =>
    request.get(
      `${apiVersion.Dashboard.applications ||
        defaultVersion}/dashboard/applications`
    ),
  /* this is for v2 server side pagination
  applications: ({ pageNumber, search, sortBy, sortDirection }) =>
    request.get(
      `${apiVersion.Dashboard.applications ||
        defaultVersion}/dashboard/applications?${filtersToQueryParams({
        limit: PAGE_SIZE,
        search,
        offset: getOffset(pageNumber),
        sort_by: sortBy,
        sort_direction: sortDirection,
      })}`
    ),
    */
};

const ErrorHandler = {
  log: (msg) => (environment.isProduction ? null : console.log(msg)),
  warn: (msg) => (environment.isProduction ? null : console.warn(msg)),
  error: (err) => (environment.isProduction ? null : console.error(err)),
  bug: (err) => {
    if (!environment.isProduction) {
      console.error(err);
    }
  },
};

/**
 * Converts an object of filters and values to a query param string suitable for use with the API
 * Expects a filterObject with the names as keys and values as arrays or strings for single values.
 * @param {object} filterObject - ex. {os: ['value 1', 'value 2'], category: 'value 1'}
 * @returns {string} - the query string to append to the API request
 */
export const filtersToQueryParams = (filterObject, preserveEmpties) => {
  const checkEmpties = (val, keepEmpties) =>
    !!(!val && val !== 0 && !keepEmpties);
  if (!filterObject) {
    return '';
  }

  const filters = Object.keys(filterObject);

  if (filters.length === 0) {
    return '';
  }

  const qpArray = filters.map((f) => {
    // If the filter has multiple values, we need to create multiple query params for that filter
    // since the api expects filter[]=value1&filter[]=value2
    if (
      typeof filterObject[f] === 'object' &&
      Array.isArray(filterObject[f]) &&
      filterObject[f].length > 1
    ) {
      // if value is falsy and we dont want empties, return null and we will strip nulls later
      const filterArray = filterObject[f].map((val) =>
        checkEmpties(val, preserveEmpties) ? null : `${f}[]=${val || ''}`
      );
      return filterArray.join('&');
      // Otherwise, we just return filter=value
    } else {
      // If it's an array but only has one value, grab the first one.
      if (
        typeof filterObject[f] === 'object' &&
        Array.isArray(filterObject[f])
      ) {
        let val = filterObject[f][0];
        // keep val if its a value or zero
        val = !!val || val === 0 ? val : '';
        return checkEmpties(val, preserveEmpties)
          ? null
          : f.indexOf('[') === -1
          ? `${f}[]=${val}`
          : `${f}=${val}`;
      } else {
        let val = filterObject[f];
        val = !!val || val === 0 ? val : '';
        return checkEmpties(filterObject[f], preserveEmpties)
          ? null
          : `${f}=${val}`;
      }
    }
  });

  return qpArray.filter((f) => !!f).join('&');
};

export {
  ErrorHandler,
  Account,
  Applications,
  API_BASE,
  Households,
  Users,
  Reports,
  checkIsVerified,
  checkIsAdmin,
  Admin,
  Dashboard,
  Clients,
  PAGE_SIZE,
};
