import React from 'react';

import { FormSelect } from '../';
import { Admin, ErrorHandler } from '../../api';

export default class DropdownCounty extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      options: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };
  _fetch = async () => {
    this.safeSetState({ loading: true });

    let res;
    try {
      res = await Admin.getSetting({ settingId: 1 });
    } catch (err) {
      ErrorHandler.error(err);
    }

    let options = [];
    if (res && res.body && res.body.setting_options) {
      options = res.body.setting_options.map(opt => {
        return { text: opt.value, value: opt.id };
      });
    }
    this.safeSetState({ options, loading: false });
  };

  render() {
    return (
      <FormSelect {...this.state} {...this.props} label="COUNTY OF RESIDENCE" />
    );
  }
}
