import React from 'react';

import './FormFieldBase.scss';

export const FormFieldBase = ({
  label,
  placeholder,
  onChange,
  error,
  required,
  errorMessage,
  disabled,
  component,
  className,
  fluid,
  loading,
  ...otherProps
}) => (
  <div
    className={`FormFieldBase ${fluid ? 'fluid' : ''} ${className || ''}${
      error ? ' error' : ''
    }${disabled ? ' disabled' : ''}`}
    {...otherProps}>
    {!!label && (
      <div className={`label ${required ? 'required' : ''}`}>{label}</div>
    )}
    <div className="component">{component}</div>
    {!!errorMessage && <div className="error-message">{errorMessage}</div>}
  </div>
);

export default FormFieldBase;
