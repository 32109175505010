import React from 'react';

import { format } from 'date-fns';

import { TableRowOptionPopup, DataTable } from '../../components/';
import { Icon, Tab, Button } from 'semantic-ui-react';
import { checkNestedValue } from '../../util';

import { ErrorHandler, Households, Clients } from '../../api';
import AddHouseholdMemberModal from './AddHHMModal';

import './TabComposition.scss';

class TabComposition extends React.Component {
  columns = [
    { name: 'Name', dataKey: 'name' },
    { name: 'SSN', dataKey: 'ssn' },
    {
      name: 'Relationship',
      dataKey: 'jankyHOHSortColumn',
      formatter: (val, row) =>
        checkNestedValue('hoh_relationship.value', row)
          ? row.hoh_relationship.value
          : '',
    },
    {
      name: 'DOB',
      dataKey: 'dob',
      formatter: (val) => (val ? format(val, 'M/D/YYYY') : ''),
    },
    { name: 'Case #', dataKey: 'case_number' },
    { name: 'RID #', dataKey: 'rid_number' },
    {
      name: 'Consents',
      dataKey: 'has_consent_notes',
      style: { textAlign: 'center' },
      formatter: (val) => val && <Icon className="green" name="check" />,
    },
    {
      name: '',
      dataKey: 'id',
      formatter: (val, row) => (
        <TableRowOptionPopup
          options={[
            { label: 'View Client', action: this.props.handleViewClient },
            {
              label: 'Remove from Household',
              action: this.handleRemoveFromHOH,
            },
          ]}
          row={row}
        />
      ),
      style: { textAlign: 'right' },
    },
  ];

  _isMounted = false;
  constructor(props) {
    super(props);

    // TODO: handle incoming redirected state

    this.state = {
      isLoading: false,
      tableData: [],
      isVisibleModal: false,
      headOfHousehold: '',
      modalSelectedHHM: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });
    const { householdId } = this.props;

    let res;
    try {
      res = await Households.composition({ householdId });
    } catch (err) {
      ErrorHandler.error(err);
    }

    let tableData = [];
    if (res && res.body && res.body.length > 0) {
      tableData = res.body.map((row) => {
        row.name = [row.last_name, row.first_name]
          .filter((f) => !!f)
          .join(', ');
        // Sort by HOH at the top, then by DOB.../shrug
        // so, make hoh 1, so it goes to the top, then just secondary sort by dob.
        row.jankyHOHSortColumn = row.hoh_relationship === 'self' ? 1 : 0;
        return row;
      });
    }

    this.safeSetState({ tableData, isLoading: false });
  };
  handleCloseModal = () => this.setState({ isVisibleModal: false });
  handleShowModal = () => this.setState({ isVisibleModal: true });
  handleAddHHM = async (memberId, hohRelationshipId) => {
    const { householdId } = this.props;
    let res;
    try {
      res = await Households.compAddClient({ memberId, householdId });
    } catch (err) {
      ErrorHandler.error(err);
    }

    if (hohRelationshipId) {
      let clientId = memberId;
      res = await Clients.update({ clientId, hohRelationshipId });
    }

    this.handleCloseModal();

    if (res) {
      this._fetch();
    }
  };
  handleRemoveFromHOH = async (row) => {
    const { householdId } = this.props;
    let res;
    try {
      res = await Households.compRemoveClient({
        memberId: row.id,
        householdId,
      });
    } catch (err) {
      ErrorHandler.error(err);
      let errMsg =
        'There was a problem submitting your changes, please try again.';
      if (checkNestedValue('response.body.household_id.0', err)) {
        let newErr = err.response.body.household_id[0];
        errMsg =
          typeof newErr === 'string'
            ? `${newErr.charAt(0).toUpperCase()}${newErr.substr(1)}`
            : errMsg;
      }
      this.props.addToast({
        type: 'error',
        message: errMsg,
      });
    }

    if (res) {
      this.props.addToast({
        type: 'success',
        message: `${[row.first_name, row.last_name]
          .filter((f) => !!f)
          .join(' ')} has been removed successfully`,
      });
      this._fetch();
    }
  };

  render() {
    const { handleGoToNewClient } = this.props;
    const {
      tableData,
      isLoading,
      isVisibleModal,
      modalSelectedHHM,
    } = this.state;
    return (
      <Tab.Pane attached={false} className="TabComposition">
        <AddHouseholdMemberModal
          isLoading={isLoading}
          selectedHHM={modalSelectedHHM}
          handleGoToNewClient={handleGoToNewClient}
          handleAddHHM={this.handleAddHHM}
          handleClose={this.handleCloseModal}
          isVisible={isVisibleModal}
        />
        <DataTable
          title={
            <Button
              className="btn-secondary upcase"
              onClick={this.handleShowModal}>
              Add Household Member
            </Button>
          }
          tableName="Users"
          isLoading={isLoading}
          data={tableData}
          emptyStateMessage="No members have been added to this household"
          defaultSort="jankyHOHSortColumn"
          secondarySort="dob"
          defaultSortDirection="ASC"
          columns={this.columns}
        />
      </Tab.Pane>
    );
  }
}

export default TabComposition;
