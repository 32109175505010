import React from 'react';

import BaseEntry from '../BaseEntry/BaseEntry';
import FormInput from '../../components/FormInput/FormInput';

import { Message, Button } from 'semantic-ui-react';
import { Account } from '../../api';
import { email as validateEmail } from '../../validation';
import { AppContext } from '../../components/AppContext/AppContext';

import './Login.scss';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      emailError: '',
      passwordError: '',
      formError: '',
      isSubmitting: false,
    };
  }

  validateForm = () => {
    const { email, password } = this.state;

    let update = false;
    let newState = {};
    if (!email) {
      newState.emailError = 'Email address is required';
      newState.formError =
        'Login failed. Please check your entries and try again.';
      update = true;
    }
    if (!validateEmail(email)) {
      newState.emailError = 'Please enter a valid email address';
      newState.formError =
        'Login failed. Please check your entries and try again.';
      update = true;
    }
    if (!password) {
      newState.passwordError = 'Password is required';
      newState.formError =
        'Login failed. Please check your entries and try again.';
      update = true;
    }
    if (update) {
      this.setState(newState);
    }
    return !update;
  };

  handleChangeEmail = (e) =>
    this.setState({ email: e.target.value, emailError: '' });

  handleChangePassword = (e) =>
    this.setState({ password: e.target.value, passwordError: '' });

  handleSubmit = async (e) => {
    e.preventDefault();
    if (!this.validateForm()) {
      return;
    }
    const { email, password } = this.state;
    this.setState({ isSubmitting: true });

    let res;
    try {
      res = await Account.login({ email, password });
    } catch (err) {
      const errMsg =
        err?.response?.body?.error || 'We were unable to log you in.';
      this.setState({
        formError: errMsg,
        isSubmitting: false,
      });
    }
    if (res) {
      const { id, first_name, last_name, email_address, role, status } =
        res.body || {};
      this.props.setUser({
        firstName: first_name,
        lastName: last_name,
        email: email_address,
        role: role && role.name,
        roleId: role && role.id,
        id,
        status,
      });
      this.setState({ isSubmitting: false });
      this.props.history.push('/dashboard');
    }
  };

  handleForgotPassword = () => {
    this.props.history.push('/forgot-password');
  };

  render() {
    const {
      email,
      password,
      emailError,
      passwordError,
      formError,
      isSubmitting,
    } = this.state;
    return (
      <BaseEntry>
        <form className="Login" onSubmit={this.handleSubmit}>
          {!!formError && <Message negative>{formError}</Message>}
          <FormInput
            autoFocus
            label="EMAIL ADDRESS"
            error={!!emailError}
            errorMessage={emailError}
            value={email}
            onChange={this.handleChangeEmail}
          />
          <FormInput
            label="PASSWORD"
            type="password"
            error={!!passwordError}
            errorMessage={passwordError}
            value={password}
            onChange={this.handleChangePassword}
          />
          <div className="form-actions">
            <Button
              primary
              loading={isSubmitting}
              onClick={this.handleSubmit}
              type="submit">
              SIGN IN
            </Button>
            <Button
              className="ghost dark"
              onClick={this.handleForgotPassword}
              type="button">
              Forgot Password?
            </Button>
          </div>
        </form>
      </BaseEntry>
    );
  }
}

const WrappedLogin = (props) => (
  <AppContext.Consumer>
    {({ setUser }) => <Login {...props} setUser={setUser} />}
  </AppContext.Consumer>
);
export default WrappedLogin;
