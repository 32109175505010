import React from 'react';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import Toast from './Toast';
import { AppContext } from '../AppContext/AppContext';

const ToastContainer = () => {
  return (
    <AppContext.Consumer>
      {({ toasts, removeToast }) => (
        <TransitionGroup
          enter={true}
          exit={true}
          appear={true}
          className="toast-container">
          {toasts.reverse().map((t, i) => (
            <Toast key={i} id={t.id} dismiss={removeToast} type={t.type}>
              {t.message}
            </Toast>
          ))}
        </TransitionGroup>
      )}
    </AppContext.Consumer>
  );
};

export default ToastContainer;
