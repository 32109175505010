import React from 'react';
import 'react-day-picker/lib/style.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import PropTypes from 'prop-types';
import { isBefore, isAfter, isSameDay, format, parse, isValid } from 'date-fns';

class BaseDateSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBadDate: false,
    };
  }

  onDateChange = newDate => {
    if (!newDate || !isValid(newDate)) {
      return false;
    }
    const dateRanges =
      this.props.dayPickerProps && this.props.dayPickerProps.disabledDays;
    const earliest = dateRanges && dateRanges.before;
    const latest = dateRanges && dateRanges.after;

    // Make sure the date is within the range we want...
    if (
      (earliest &&
        (!isSameDay(newDate, earliest) && isBefore(newDate, earliest))) ||
      (latest && (!isSameDay(newDate, latest) && isAfter(newDate, latest)))
    ) {
      this.setState({ isBadDate: true });
      return false;
    }

    this.setState({
      isBadDate: false,
    });

    this.props.onDateChanged(newDate);
  };

  parseDate = (str, format, locale) => {
    if(str && typeof str === 'string' && str.length === 8){
      str = `${str.substr(0,2)}/${str.substr(2,2)}/${str.substr(4,4)}`;
    }
    if (
      !str.match(/^[0-9]{1,2}[/]{1}[0-9]{1,2}[/]{1}[0-9]{4}$/g) &&
      !str.match(/^[0-9]{1,2}[-]{1}[0-9]{1,2}[-]{1}[0-9]{4}$/g)
    ) {
      return undefined;
    }
    const parsed = parse(str, format, { locale });
    if (isValid(parsed)) {
      return parsed;
    }
    return undefined;
  };

  render() {
    const {
      className,
      placeholder,
      isBadDate,
      disabled,
      date,
      dayPickerProps,
      alignRight = false,
      autoFocus,
    } = this.props;

    const { disable } = this.state;
    /**
     * if NO label is passed, default it to 'As of', however if we specifically
     * pass `false` as a label, then we specifically dont show it.
     * or if an actual label is passed, obviously show that
     */
    let { label } = this.props;
    label = typeof label === 'undefined' ? 'As of' : label;

    return (
      <div
        className={`date-selector ${className || ''} ${
          disable ? 'disabled' : ''
        } ${isBadDate ? 'badDate' : ''} ${alignRight ? 'align-right' : ''}`}>
        {label && <label>{label}</label>}
        <DayPickerInput
          autoFocus={autoFocus}
          placeholder={(!placeholder && placeholder !== "") ? "Please select a date" : placeholder}
          inputProps={{ disabled: disabled }}
          formatDate={format}
          parseDate={this.parseDate}
          hideOnDayClick={true}
          //showOverlay={true} // for debugging
          onDayChange={this.onDateChange}
          value={date ? format(date, 'M/D/YYYY') : undefined}
          format="M/D/YYYY"
          dayPickerProps={
            dayPickerProps || {
              disabledDays: { after: new Date() },
            }
          }
          /** DayPickerInput props
           * classNames,
           * clickUnselectsDay,
           * component,
           * dayPickerProps,
           * format,
           * formatDate,
           * keepFocus,
           * hideOnDayClick,
           * inputProps,
           * overlayComponent,
           * parseDate,
           * placeholder,
           * showOverlay,
           * value
           * // events
           * onDayChange,
           * onDayPickerHide
           * // public methods
           * getDayPicker,
           * getInput,
           * hideDayPicker,
           * showDayPicker
           */
        />
      </div>
    );
  }
}

BaseDateSelector.propTypes = {
  className: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  dayPickerProps: PropTypes.object,
  onDateChanged: PropTypes.func,
};

export default BaseDateSelector;
