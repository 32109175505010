import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormInput } from './';

// Component requires an onSearch event. Will call the onSearch event when input is changed or escape key is pressed.
// onSearch will be called with synthetic event and value. Value will be null when input is cleared.

export default class SearchInput extends Component {
  handleKeyDown = (evt) => {
    if (evt.key === 'Escape') {
      this.props.onSearch(evt, '');
    }
  };

  handleOnChange = (e) => {
    this.props.onSearch(e, e.target.value);
  };

  render() {
    const { size, placeholder, style, loading, value, autoFocus } = this.props;
    return (
      <FormInput
        autoFocus={autoFocus}
        className="SearchInput"
        autoComplete="off"
        placeholder={placeholder || 'Search'}
        onKeyDown={this.handleKeyDown}
        onChange={this.handleOnChange}
        size={size || 'small'}
        type="search"
        value={value}
        style={{
          width: 225,
          fontFamily: 'Roboto, sans-serif',
          fontSize: '1rem',
          ...style,
        }}
        loading={loading || false}
        icon={{ name: 'search', size: 'small' }}
      />
    );
  }
}

SearchInput.propTypes = {
  autoFocus: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  size: PropTypes.oneOf([
    'mini',
    'tiny',
    'small',
    'medium',
    'large',
    'big',
    'huge',
    'massive',
  ]),
  style: PropTypes.object,
  loading: PropTypes.bool,
  value: PropTypes.string,
};
