import React from 'react';

const Overlay = ({ isVisible, color, zIndex, opacity, onClick, children }) => {
  const styles = {
    backgroundColor: color || 'transparent',
    opacity: opacity || '0',
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    zIndex: zIndex || '200',
  };

  if (!isVisible) {
    styles.display = 'none';
  }

  return (
    <div style={styles} onClick={onClick}>
      {children}
    </div>
  );
};

export default Overlay;
