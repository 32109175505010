import React from 'react';
import {Popup, Button} from 'semantic-ui-react';

import './TableRowOptionPopup.scss';

export default class TableRowOptionPopup extends React.Component {
  state = { isVisible: false };

  onShow = () => this.setState({ isVisible: true });
  onHide = () => this.setState({ isVisible: false });

  handleOptionClick = (callback) => {
    this.onHide();
    const {row} = this.props;
    // the actions must be functions, and row must be passed as a prop
    if(typeof callback === 'function' && !!row){
      callback(row);
    }
  };

  render() {
    const { isVisible } = this.state;
    const { options } = this.props;
    return (
      <Popup
        className="TableRowOptionPopup"
        trigger={<Button className="ghost" circular icon="ellipsis vertical" />}
        on="click"
        basic
        position="left center"
        open={isVisible}
        onOpen={this.onShow}
        onClose={this.onHide}
        content={
          <ul className="EditMenu">
            { !!options && options.length > 0 && options.map((opt, i) => (
              <li key={i} onClick={() => this.handleOptionClick(opt.action)}>{opt.label}</li>
            ))
            }
          </ul>
        }
      />
    );
  }
}
