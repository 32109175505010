import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';

/**
 * Renders the provided children and uses them to trigger a tooltip with the specified content
 * @prop {node} children - The children will be rendered and used as the trigger for the tooltip.
 * @prop {string} [content] - the content to be displayed in the tooltip
 * @prop {number} [delay=750] - delay in MS to wait before opening the tooltip
 */

class HeaderInfoTooltip extends Component {
  DEFAULT_DELAY = 750;

  // constructor(props) {
  // super(props);
  //    this.state = {
  //  isOpen: false,
  //};
  // }

  //handleOpen = () => {
  // window.setTimeout(() => {
  //   this.setState({
  //     isOpen: true,
  //   });
  // }, this.props.delay || this.DEFAULT_DELAY);
  //};

  //handleClose = () => {
  // this.setState({
  //   isOpen: false,
  // });
  //};

  render() {
    const { content, children } = this.props;
    // const { isOpen } = this.state;

    // If no content for thet tooltip, just render the children.
    if (!content) {
      return <span>{children}</span>;
    } else {
      return (
        <Popup
          // open={isOpen}
          position="top center"
          // onOpen={this.handleOpen}
          // onClose={this.handleClose}
          trigger={children}
          content={content}
          size="mini"
        />
      );
    }
  }
}

HeaderInfoTooltip.propTypes = {
  content: PropTypes.string,
  delay: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default HeaderInfoTooltip;
