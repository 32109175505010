import React from 'react';

import { Households, ErrorHandler } from '../../api';

import {
  FormMultiSelect,
  FormSelect,
  FormDate,
  FormInput,
  FormTextArea,
  DropdownSetting,
  DropdownNavigator,
} from '../../components/';
import { Modal, Button } from 'semantic-ui-react';

import './AddApplicationModal.scss';

export default class AddApplicationModal extends React.Component {
  _isMounted = false;
  _initialState = {
    selectedMembers: [],
    selectedCategory: '',
    step: 1,
    dateOfApplication: new Date(),
    applicationType: 'new-internal',
    appointmentSite: '',
    healthPlan: '',
    applicationNumber: '',
    referralCategory: '',
    referralSource: '',
    missingDocuments: '',
    faxDate: '',
    isLoadingClients: false,
    extAppLocation: '',
  };
  constructor(props) {
    super(props);

    this.state = {
      navigator: props?.user?.id,
      ...this._initialState,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetchClients();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  handleClose = (success) => {
    this.safeSetState(this._initialState, () => {
      this.props.onClose(success);
    });
  };

  _fetchClients = async () => {
    this.safeSetState({ isLoadingClients: true });
    const { householdId } = this.props;
    let res;
    try {
      res = await Households.composition({ householdId });
    } catch (err) {
      ErrorHandler.error(err);
    }

    let clients = [];
    if (res && res.body) {
      clients = res.body.map((row) => ({
        text: [row.first_name, row.last_name].filter((f) => !!f).join(' '),
        value: row.id,
      }));
    }

    this.safeSetState({ optionsClients: clients, isLoadingClients: false });
  };

  handleMemberChange = (e, { value }) =>
    this.safeSetState({ selectedMembers: value });

  handleCategorySelect = (e, { value }) =>
    this.safeSetState({ selectedCategory: value });

  handleFieldChange = (field, value) => {
    this.safeSetState({
      [field]: value,
      [`${field}Error`]: '',
    });
  };

  handleGoBack = () =>
    this.safeSetState({
      step: 1,
      dateOfApplication: new Date(),
      applicationType: 'new-internal',
      appointmentSite: '',
      healthPlan: '',
      applicationNumber: '',
      referralCategory: '',
      referralSource: '',
      missingDocuments: '',
      faxDate: new Date(),
    });

  validateForm = () => {
    const { selectedCategory } = this.state;
    const requiredFields = [
      'dateOfApplication',
      'applicationType',
      'appointmentSite',
      'referralCategory',
      'selectedCategory',
      'referralSource',
      'navigator',
    ];

    if (selectedCategory !== 'MP' && selectedCategory !== 'PEPW') {
      requiredFields.push('healthPlan');
    }
    let isFormValid = true;
    let newState = { isFormValid: false };
    requiredFields.forEach((field) => {
      if (!this.state[field]) {
        newState[`${field}Error`] = 'This field is required';
        isFormValid = false;
      }
    });
    if (!isFormValid) {
      this.safeSetState(newState);
    }
    return isFormValid;
  };

  handleGoToDetails = () => {
    const { selectedCategory } = this.state;
    if (selectedCategory) {
      this.safeSetState({ step: 2 });
    } else {
      this.safeSetState({ selectedCategoryError: 'This field is required' });
    }
  };

  handleCreateApplication = async () => {
    if (!this.validateForm()) {
      return;
    }
    const {
      selectedMembers,
      selectedCategory,
      dateOfApplication,
      applicationType,
      appointmentSite,
      healthPlan,
      applicationNumber,
      referralCategory,
      referralSource,
      missingDocuments,
      faxDate,
      navigator,
      extAppLocation,
    } = this.state;

    const { householdId, addToast } = this.props;

    this.safeSetState({ isLoading: true });
    let res;
    let payload;
    if (selectedCategory === 'MP') {
      payload = {
        clients: selectedMembers,
        application_category: selectedCategory,
        application_date: dateOfApplication,
        application_type: applicationType,
        missing_documents: missingDocuments,
        appointment_site_id: appointmentSite,
        application_number: applicationNumber,
        referral_category_id: referralCategory,
        referral_source_id: referralSource,
        navigator_id: navigator,
      };
    } else {
      payload = {
        clients: selectedMembers,
        application_category: selectedCategory,
        application_date: dateOfApplication,
        application_type: applicationType,
        missing_documents: missingDocuments,
        appointment_site_id: appointmentSite,
        health_plan_id: healthPlan,
        referral_category_id: referralCategory,
        referral_source_id: referralSource,
        navigator_id: navigator,
        application_number: applicationNumber,
        dfr_fax_date: faxDate,
      };
    }
    if (
      (applicationType === 'redeterm-external' ||
        applicationType === 'new-external') &&
      extAppLocation
    ) {
      payload.application_external_location_id = extAppLocation;
    }

    try {
      res = await Households.applicationAdd({ householdId, payload });
    } catch (err) {
      ErrorHandler.error(err);
      addToast({
        type: 'error',
        message:
          'The Application could not be created at this time. Please try again.',
      });
    }

    if (res) {
      addToast({
        type: 'success',
        message: `New ${selectedCategory} application has been added successfully!`,
      });
      this.handleClose(true);
    }
    this.safeSetState({ isLoading: false });
  };

  clearErrors = () => {
    this.safeSetState({
      dateOfApplicationError: '',
      applicationTypeError: '',
      appointmentSiteError: '',
      healthPlanError: '',
      extAppLocationError: '',
      referralCategoryError: '',
      referralSourceError: '',
      navigatorError: '',
    });
  };

  render() {
    const { isVisible } = this.props;
    const {
      selectedMembers,
      selectedCategory,
      step,
      isLoading,
      dateOfApplication,
      dateOfApplicationError,
      applicationType,
      applicationTypeError,
      appointmentSite,
      appointmentSiteError,
      healthPlan,
      healthPlanError,
      applicationNumber,
      referralCategory,
      missingDocuments,
      referralCategoryError,
      selectedCategoryError,
      referralSource,
      referralSourceError,
      faxDate,
      optionsClients,
      navigator,
      navigatorError,
      extAppLocation,
      extAppLocationError,
    } = this.state;

    const optionsAppCategory = [
      { text: 'Hoosier Healthwise (HHW)', value: 'HHW' },
      { text: 'Healthy Indiana Plan (HIP)', value: 'HIP' },
      {
        text: 'Presumptive Eligibility for Pregnancy Women (PEPW)',
        value: 'PEPW',
      },
      { text: 'Traditional Medicaid (ABD)', value: 'ABD' },
      { text: 'Marketplace (MP)', value: 'MP' },
    ];

    const optionsAppTypes = [
      { text: 'New Application (Internal)', value: 'new-internal' },
      { text: 'New Application (External)', value: 'new-external' },
      { text: 'Redeterm Application (Internal)', value: 'redeterm-internal' },
      { text: 'Redeterm Application (External)', value: 'redeterm-external' },
    ];

    return (
      <Modal
        open={isVisible}
        onClose={this.handleClose}
        size="tiny"
        className="AddApplicationModal">
        <Modal.Header>
          {step === 1
            ? 'Add New Application'
            : `Add New Application: ${selectedCategory}`}
        </Modal.Header>
        {step === 1 && (
          <Modal.Content>
            <FormSelect
              autoFocus
              required
              fluid
              label="SELECT APPLICATION CATEGORY"
              options={optionsAppCategory}
              value={selectedCategory}
              error={selectedCategoryError}
              errorMessage={selectedCategoryError}
              onChange={(e, { value }) =>
                this.handleFieldChange('selectedCategory', value)
              }
            />
            <DropdownNavigator
              error={navigatorError}
              errorMessage={navigatorError}
              required
              style={{ marginTop: '20px', width: '100%' }}
              label="SELECT NAVIGATOR"
              value={navigator}
              onChange={(e, { value }) =>
                this.handleFieldChange('navigator', value)
              }
            />
            <FormMultiSelect
              style={{ marginTop: '20px' }}
              label="SELECT HOUSEHOLD MEMBERS"
              options={optionsClients}
              value={selectedMembers}
              onChange={(e, { value }) =>
                this.handleFieldChange('selectedMembers', value)
              }
            />
            <div className="button-box">
              <Button className="ghost dark" onClick={this.handleClose}>
                CANCEL
              </Button>
              <Button
                disabled={!selectedMembers.length}
                primary
                onClick={this.handleGoToDetails}>
                ADD DETAILS
              </Button>
            </div>
          </Modal.Content>
        )}
        {step === 2 && (
          <Modal.Content>
            <div className="form-row">
              <FormDate
                autoFocus
                label="DATE OF APPLICATION"
                required
                value={dateOfApplication}
                error={dateOfApplicationError}
                errorMessage={dateOfApplicationError}
                onChange={(e, { value }) =>
                  this.handleFieldChange('dateOfApplication', value)
                }
              />
              <FormSelect
                label="TYPE"
                options={optionsAppTypes}
                className="field-type"
                required
                value={applicationType}
                error={applicationTypeError}
                errorMessage={applicationTypeError}
                onChange={(e, { value }) =>
                  this.handleFieldChange('applicationType', value)
                }
              />
            </div>

            {applicationType === 'redeterm-external' ||
            applicationType === 'new-external' ? (
              <div className="form-row">
                <DropdownSetting
                  settingId={15}
                  label="EXT APP LOCATION"
                  className="field-site long"
                  value={extAppLocation}
                  error={extAppLocationError}
                  errorMessage={extAppLocationError}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('extAppLocation', value)
                  }
                />
              </div>
            ) : null}

            <div className="form-row">
              <DropdownSetting
                settingId={8}
                label="APPOINTMENT SITE"
                className={
                  selectedCategory !== 'PEPW' ? 'field-site' : 'field-site long'
                }
                value={appointmentSite}
                error={appointmentSiteError}
                errorMessage={appointmentSiteError}
                onChange={(e, { value }) =>
                  this.handleFieldChange('appointmentSite', value)
                }
                required
              />
              {selectedCategory === 'MP' ? (
                <FormInput
                  label="APPLICATION NUMBER"
                  className="field-app-number"
                  value={applicationNumber}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('applicationNumber', value)
                  }
                />
              ) : null}
              {selectedCategory !== 'MP' && selectedCategory !== 'PEPW' ? (
                <DropdownSetting
                  settingId={9}
                  required
                  label="HEALTH PLAN"
                  className="field-plan"
                  value={healthPlan}
                  error={healthPlanError}
                  errorMessage={healthPlanError}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('healthPlan', value)
                  }
                />
              ) : null}
            </div>
            <div className="form-row">
              <DropdownSetting
                settingId={6}
                label="REFERRAL CATEGORY"
                className="field-category"
                required
                value={referralCategory}
                error={referralCategoryError}
                errorMessage={referralCategoryError}
                onChange={(e, { value }) =>
                  this.handleFieldChange('referralCategory', value)
                }
              />
              <DropdownSetting
                settingId={7}
                required
                label="REFERRAL SOURCE"
                className="field-source"
                value={referralSource}
                error={referralSourceError}
                errorMessage={referralSourceError}
                onChange={(e, { value }) =>
                  this.handleFieldChange('referralSource', value)
                }
              />
            </div>
            {selectedCategory !== 'MP' && selectedCategory !== 'PEPW' && (
              <div className="form-row">
                <FormInput
                  label="APPLICATION NUMBER"
                  className="field-app-number"
                  value={applicationNumber}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('applicationNumber', value)
                  }
                />
                <FormDate
                  label="DFR FAX DATE"
                  className="field-site"
                  value={faxDate}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('faxDate', value)
                  }
                />
              </div>
            )}
            <div className="form-row">
              <FormTextArea
                label="MISSING DOCUMENTS"
                value={missingDocuments}
                rows="4"
                cols="400"
                onChange={(e, { value }) =>
                  this.handleFieldChange('missingDocuments', value)
                }
              />
            </div>
            <div className="button-box">
              <Button className="ghost dark" onClick={this.handleGoBack}>
                BACK
              </Button>
              <Button
                primary
                loading={isLoading}
                onClick={this.handleCreateApplication}>
                CREATE APPLICATION
              </Button>
            </div>
          </Modal.Content>
        )}
      </Modal>
    );
  }
}
