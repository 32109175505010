import React from 'react';
import { format } from 'date-fns';

import { AppContext } from '../../components/AppContext/AppContext';

import {
  FormField,
  SearchInput,
  FormSelect,
  TableRowOptionPopup,
  DeleteConfirmModal,
} from '../../components/';
import { Tab } from 'semantic-ui-react';
import { checkNestedValue } from '../../util';

import { ErrorHandler, Clients } from '../../api';

import './TabNotes.scss';

class TabNotes extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      notes: [],
      noteType: 'all',
      noteTypes: [],
      navigator: 'all',
      navigators: [],
      noteSearch: '',
      deleteNoteDate: '',
      deleteNoteType: '',
      deleteNoteFirstName: '',
      deleteNoteLastName: '',
      shouldFetchNotes: this.props.shouldFetchNotes,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });
    const { clientId } = this.props;

    let res;
    try {
      res = await Clients.notes({ clientId });
    } catch (err) {
      ErrorHandler.error(err);
    }

    let notes = [];
    let noteTypes = [{ text: 'All Note Types', value: 'all' }];
    let navigators = [{ text: 'All Navigators', value: 'all' }];
    if (res && res.body && res.body.length > 0) {
      notes = res.body;
      notes.forEach((note) => {
        if (!noteTypes.some((n) => n.value === note.note_type)) {
          noteTypes.push({ text: note.note_type, value: note.note_type });
        }
        if (
          note.navigator &&
          note.navigator.id &&
          !navigators.some((nav) => nav.value === note.navigator.id)
        ) {
          navigators.push({
            text: [note.navigator.first_name, note.navigator.last_name]
              .filter((f) => !!f)
              .join(' '),
            value: note.navigator.id,
          });
        }
      });
    }

    this.safeSetState({ navigators, notes, noteTypes, isLoading: false });
  };

  handleEditNote = (note) => {
    this.props.handleShowEditModal(note);
  };

  handleDeleteNote = async () => {
    this.safeSetState({
      isLoading: true,
    });
    const { deleteNoteId, deleteNoteType } = this.state;
    const { clientId } = this.props;
    let res;
    try {
      res = await Clients.noteRemove({
        noteId: deleteNoteId,
        clientId,
      });
    } catch (err) {
      ErrorHandler.error(err);
      let errMsg =
        'There was a problem submitting your changes, please try again.';
      if (checkNestedValue('response.body.client_id.0', err)) {
        let newErr = err.response.body.client_id[0];
        errMsg =
          typeof newErr === 'string'
            ? `${newErr.charAt(0).toUpperCase()}${newErr.substr(1)}`
            : errMsg;
      }
      this.props.addToast({
        type: 'error',
        message: errMsg,
      });
    }

    if (res) {
      this.props.addToast({
        type: 'success',
        message: `The ${deleteNoteType} note was deleted successfully.`,
      });
      this._fetch();
    }
    this.safeSetState({ isLoading: false });
    this.handleCloseNoteDeleteModal();
  };

  handleNoteTypeChange = (e, opt) => {
    this.safeSetState({ noteType: opt.value });
  };

  handleNavigatorChange = (e, opt) => {
    this.safeSetState({ navigator: opt.value });
  };

  handleNoteSearch = (e) => this.safeSetState({ noteSearch: e.target.value });

  handleCloseNoteDeleteModal = () =>
    this.safeSetState({ isVisibleDeleteModal: false });

  handleShowNoteDeleteModal = (row) =>
    this.safeSetState({
      deleteNoteId: row.id,
      isVisibleDeleteModal: true,
      deleteNoteDate: row.service_date,
      deleteNoteType: row.note_type,
      deleteNoteFirstName: row.first_name,
      deleteNoteLastName: row.last_name,
    });

  render() {
    const {
      noteTypes,
      isLoading,
      notes,
      noteSearch,
      noteType,
      navigators,
      navigator,
      isVisibleDeleteModal,
      deleteNoteDate,
      deleteNoteType,
    } = this.state;

    let filteredNotes = notes;
    if (noteType && noteType !== 'all') {
      filteredNotes = filteredNotes.filter((f) => f.note_type === noteType);
    }
    if (navigator && navigator !== 'all') {
      filteredNotes = filteredNotes.filter(
        (f) => f.navigator && f.navigator.id === navigator
      );
    }
    if (noteSearch) {
      filteredNotes = filteredNotes.filter((f) => {
        let res = false;
        if (f.note_details && typeof f.note_details === 'string') {
          res =
            f.note_details.toLowerCase().indexOf(noteSearch.toLowerCase()) !==
            -1;
        }
        return res;
      });
    }
    return (
      <Tab.Pane attached={false} className="ClientTabNotes" loading={isLoading}>
        <DeleteConfirmModal
          isLoading={isLoading}
          isVisible={isVisibleDeleteModal}
          onClose={this.handleCloseNoteDeleteModal}
          onDelete={this.handleDeleteNote}
          message={
            <p>
              <strong>
                You are about to delete a {deleteNoteType} note with a service
                date of {format(deleteNoteDate, 'M/D/YYYY')}.{' '}
              </strong>
              Please confirm your request.
            </p>
          }
        />
        {!!notes && notes.length > 0 && (
          <div className="NoteContainer">
            <div className="note-filter-row">
              <div className="left-filters">
                <FormSelect
                  options={noteTypes}
                  onChange={this.handleNoteTypeChange}
                  value={noteType}
                />
                <FormSelect
                  options={navigators}
                  onChange={this.handleNavigatorChange}
                  value={navigator}
                />
              </div>
              <SearchInput
                placeholder="Search in notes"
                value={noteSearch}
                onSearch={this.handleNoteSearch}
              />
            </div>
            {filteredNotes.length > 0 &&
              filteredNotes.map((note, k) => (
                <NoteRow
                  isAdmin={this.props.user && this.props.user.role === 'Admin'}
                  key={k}
                  note={note}
                  handleDeleteNote={this.handleShowNoteDeleteModal}
                  handleEditNote={this.handleEditNote}
                />
              ))}
            {filteredNotes.length === 0 && (
              <p
                style={{ textAlign: 'center', padding: '50px', color: '#666' }}>
                No notes found that match search criteria.
              </p>
            )}
          </div>
        )}
        {(!notes || notes.length === 0) && (
          <p
            className="empty-text"
            style={{ textAlign: 'center', padding: '50px', color: '#666' }}>
            No notes have been added for this Client.
          </p>
        )}
      </Tab.Pane>
    );
  }
}

const NoteRow = ({
  note,
  handleDeleteNote,
  handleEditNote,
  isAdmin = this.props.user && this.props.user.role === 'Admin',
}) => {
  let rowOptions = [{ label: 'Edit Note', action: () => handleEditNote(note) }];
  if (!!isAdmin) {
    rowOptions.push({
      label: 'Delete Note',
      action: handleDeleteNote,
    });
  }
  return (
    <div className="note-row">
      <div className="note-header">
        <h5>{note.note_type}</h5>
        <TableRowOptionPopup options={rowOptions} row={note} />
      </div>
      <p>{note.note_details}</p>
      <div className="note-meta">
        {!!note.service_date && (
          <FormField
            title="SERVICE DATE"
            value={format(note.service_date, 'M/D/YYYY')}
          />
        )}
        {!!note.time_spent && (
          <FormField title="TIME SPENT" value={`${note.time_spent} min`} />
        )}
        {!!note.assist_category && (
          <FormField title="CATEGORY" value={note.assist_category} />
        )}
        {!!note.assist_type && !!note.assist_type.value && (
          <FormField title="TYPE" value={note.assist_type.value} />
        )}
        {(checkNestedValue('navigator.first_name', note) ||
          checkNestedValue('navigator.last_name', note)) && (
          <FormField
            title="NAVIGATOR"
            value={[note.navigator.first_name, note.navigator.last_name]
              .filter((f) => !!f)
              .join(' ')}
          />
        )}
        {!!note.referral_category && !!note.referral_category.value && (
          <FormField
            title="REFERRAL CATEGORY"
            value={note.referral_category.value}
          />
        )}
        {!!note.referral_source && !!note.referral_source.value && (
          <FormField
            title="REFERRAL SOURCE"
            value={note.referral_source.value}
          />
        )}
        {!!note.provider && !!note.provider.value && (
          <FormField title="PROVIDER" value={note.provider.value} />
        )}
        <FormField
          title="PREGNANT"
          value={`${note.pregnant_client ? 'Yes' : 'No'}`}
        />
      </div>
    </div>
  );
};

export default TabNotes;

export const WrappedTabNotes = (props) => (
  <AppContext.Consumer>
    {({ shouldFetchNotes, flipShouldFetchNotes }) => <TabNotes {...props} />}
  </AppContext.Consumer>
);
