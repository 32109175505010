import React from 'react';

import { Pagination } from 'semantic-ui-react';

const TablePaginationStateless = ({
  perPage,
  itemCount,
  activePage,
  onPageChange,
}) => {
  return (
    itemCount > perPage && (
      <div
        className="pagination"
        style={{ display: 'flex', alignItems: 'center' }}>
        <span
          className="count"
          style={{ color: '#464646', marginRight: '1em' }}>
          Showing {activePage === 1 ? '1' : (activePage - 1) * perPage + 1}-
          {activePage * perPage < itemCount ? activePage * perPage : itemCount}{' '}
          of {itemCount}
        </span>

        <Pagination
          activePage={activePage}
          firstItem={null}
          lastItem={null}
          totalPages={Math.ceil(itemCount / perPage) || 1}
          onPageChange={(e, data) => {
            onPageChange(data.activePage, perPage);
          }}
        />
      </div>
    )
  );
};

export default TablePaginationStateless;
