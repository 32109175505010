const envStages = {
  production: {
    name: 'production',
    apiBase: 'https://ckfdb.mybrightpoint.org/api',
    production: true,
  },
  staging: {
    name: 'stag',
    apiBase: 'https://brightpoint.staging.corsicatech.com/api',
    production: false,
  },
  development: {
    name: 'dev',
    //apiBase: 'https://brightpoint.dev.corsicatech.com/api',
    apiBase: 'https://brightpoint.staging.corsicatech.com/api',
    production: false,
  },
};

const checkEnv = (env) => process.env.REACT_APP_ENV === env.name;

export const environment = {
  env: process.env.REACT_APP_ENV,
  isProduction: checkEnv(envStages.production),
  isStaging: checkEnv(envStages.staging),
  isDevelopment: checkEnv(envStages.development),
  get current() {
    return envStages[process.env.REACT_APP_ENV] || envStages.development;
  },
};
