import React from 'react';
import { Redirect, Switch, Route, Router } from 'react-router-dom';

import { history as customHistory } from './history';
import { checkIsVerified, checkIsAdmin } from './api';

import Login from './views/Login/Login';
import ForgotPassword from './views/ForgotPassword/ForgotPassword';
import ResetPassword from './views/ResetPassword/ResetPassword';
import Dashboard from './views/Dashboard/Dashboard';
import Clients from './views/Clients/Clients';
import ClientAdd from './views/Clients/ClientAdd';
import ClientDetails from './views/Clients/ClientDetails';
import Users from './views/Users/Users';
import Households from './views/Households/Households';
import HouseholdAdd from './views/Households/HouseholdAdd';
import HouseholdDetails from './views/Households/HouseholdDetails';
import Settings from './views/Settings/Settings';
import Reports from './views/Reports/Reports';
import SettingsDetails from './views/Settings/SettingsDetails';
import FinalizeAccount from './views/FinalizeAccount/FinalizeAccount';
import { WithAppContext } from './components/AppContext/AppContext';
import ToastContainer from './components/Toast/ToastContainer';
import MainLayout from './views/MainLayout/MainLayout';
import EditApplication from './views/Households/EditApplication';
import ErrorBoundary from './ErrorBoundary';

const App = () => {
  return (
    <ErrorBoundary>
      <div className="App">
        <WithAppContext>
          <ToastContainer />
          <Router history={customHistory}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/finalize-account" component={FinalizeAccount} />

              <PrivateRoute path="/dashboard" component={Dashboard} />

              <PrivateRoute exact path="/clients/add" component={ClientAdd} />
              <PrivateRoute exact path="/clients" component={Clients} />
              <PrivateRoute
                path="/clients/:client_id/edit"
                component={ClientAdd}
              />
              <PrivateRoute
                path="/clients/:client_id"
                component={ClientDetails}
              />

              <PrivateRoute exact path="/households" component={Households} />
              <PrivateRoute
                exact
                path="/households/add"
                component={HouseholdAdd}
              />
              <PrivateRoute
                exact
                path="/households/:household_id/applications/:application_id"
                component={EditApplication}
              />
              <PrivateRoute
                exact
                path="/households/:household_id/edit"
                component={HouseholdAdd}
              />
              <PrivateRoute
                exact
                path="/households/:household_id"
                component={HouseholdDetails}
              />

              <PrivateAdminRoute path="/admin/users" component={Users} />
              <PrivateAdminRoute path="/admin/reports" component={Reports} />
              <PrivateAdminRoute
                path="/admin/settings"
                exact
                component={Settings}
              />
              <PrivateAdminRoute
                path="/admin/settings/:id"
                component={SettingsDetails}
                exact
              />
              <Redirect to="/login" />
            </Switch>
          </Router>
        </WithAppContext>
      </div>
    </ErrorBoundary>
  );
};

export default App;

/*********************************************************************************************
 * PrivateRoute - Use this to wrap a component that can only be accessed once the user logs in
 *********************************************************************************************/
const PrivateRoute = ({ component: Component, ...rest }) => {
  const isVerified = checkIsVerified();
  return (
    <Route
      {...rest}
      render={(props) =>
        isVerified ? (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

/*********************************************************************************************
 * PrivateAdminRoute - Use this to wrap components that require login and admin privilages
 *********************************************************************************************/
const PrivateAdminRoute = ({ component: Component, ...rest }) => {
  const isVerified = checkIsVerified();
  const isAdmin = checkIsAdmin();
  return (
    <Route
      {...rest}
      render={(props) =>
        isVerified && isAdmin ? (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
