/**
 * Usage:
 *
 * import params from './queryParams.js';
 *
 * <Link to={{ pathname: '/the/path', search: params.stringify({ your: 'query' }) }}>click me</Link>
 *
 * params.getAll();   // {your: 'params', returned: 'here}
 *
 * params.get('date') // {date: '4/12/2016'}
 */

class params {
  /**
   * Accepts an array of objects or an object of queryparams, and returns a url safe encoded query string
   * @param {string of objects or object} params
   */
  stringify = params => {
    if (!params) {
      return '';
    }
    if (Array.isArray(params)) {
      params = Object.assign({}, ...params);
    }
    params = this.stripEmpties(params);
    return !this.isEmptyObject(params)
      ? `${Object.entries(params)
          .map(pair => `${pair[0]}=${encodeURIComponent(pair[1])}`)
          .join('&')}`
      : '';
  };

  isEmptyObject(obj) {
    return typeof obj === 'object' && Object.keys(obj).length === 0;
  }

  stripEmpties(obj) {
    let newObj = {};
    Object.keys(obj).forEach(o => {
      if (obj[o] !== null && obj[o] !== undefined && obj[o] !== '') {
        newObj[o] = obj[o];
      }
    });
    return newObj;
  }

  dateFiltersToParams = (dateFilters, currentParams) => {
    const params = currentParams || {};
    dateFilters.forEach(f => {
      const key = Object.keys(f)[0];
      params[key] = f[key];
    });
    return params;
  };

  filtersToParams = (filters, currentParams) => {
    const params = currentParams || {};
    filters.forEach(f => {
      if (params[f.name]) {
        // if there is already this category, just add the values
        params[f.name] += `|${f.values.join('|')}`;
      } else {
        params[f.name] = f.values.join('|');
      }
    });
    return params;
  };

  getAll = () => {
    const qs = window.location.search;
    return this.parse(qs);
  };

  parse = search => {
    let params = {};
    if (search && search.length > 1) {
      let pairs = search.split('?');
      pairs = pairs.length > 1 ? pairs[1].split('&') : pairs[0].split('&');
      pairs.map(pair => {
        pair = pair.split('=');
        if (pair.length === 2) {
          return (params[pair[0]] = decodeURIComponent(pair[1]));
        } else {
          return (params[pair[0]] = '');
        }
      });
    }

    return params;
  };

  get = param => this.getAll()[param];
}

export default new params();
