import React from 'react';

import { Card, HeaderBar, FormInput } from '../../components/';
import { Button, Icon } from 'semantic-ui-react';

import { ErrorHandler, Admin } from '../../api';
import { AppContext } from '../../components/AppContext/AppContext';

import './SettingsDetails.scss';

class SettingsDetails extends React.Component {
  _isMounted = false;
  _tempIDCounter = 1;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      options: [],
      title: '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });
    const settingId = this.props.match.params.id;
    let res;
    try {
      res = await Admin.getSetting({ settingId });
    } catch (err) {
      ErrorHandler.error(err);
    }

    let options = [];
    let title = '';
    if (res && res.body) {
      const data = res.body;
      options = data.setting_options;
      title = data.name;
    }
    this.safeSetState({ options, title, isLoading: false });
  };

  handleSubmit = async () => {
    this.safeSetState({ isSubmitting: true, isLoading: true });
    const settingId = this.props.match.params.id;
    const { options } = this.state;
    let cleanOpts = options.map(opt => {
      if (!Number.isInteger(opt.id)) {
        opt = { value: opt.value };
      }
      return opt;
    });
    let res;
    try {
      res = await Admin.updateSettings({ settingId, settings: cleanOpts });
    } catch (err) {
      this.props.addToast({
        type: 'error',
        message:
          'There was a problem submitting your changes, please try again.',
      });
      ErrorHandler.error(err);
    }

    if (res && res.body && res.body.name) {
      this.props.addToast({
        type: 'success',
        message: `${res.body.name} has been updated successfully!`,
      });
      this.props.history.push('/admin/settings');
    }
    this.safeSetState({ isLoading: false });
  };

  handleCancel = () => this.props.history.go(-1);

  handleOptChange = (optId, val) => {
    const newOpts = [...this.state.options];

    this.safeSetState({
      options: newOpts.map(opt => {
        if (opt.id === optId) {
          opt.value = val;
        }
        return opt;
      }),
    });
  };

  handleDelOpt = optId => {
    const newOpts = [];
    this.state.options.forEach(opt => {
      if (opt.id !== optId) {
        newOpts.push(opt);
      } else if (Number.isInteger(optId)) {
        opt._destroy = 1;
        newOpts.push(opt);
      }
    });

    this.safeSetState({ options: newOpts });
  };

  handleSortOptions = () => {
    const newOptions = this.state.options.sort((a, b) =>
      a.value > b.value ? 1 : a.value < b.value ? -1 : 0
    );

    this.safeSetState({
      options: newOptions,
    });
  };

  handleAddOption = () => {
    this.safeSetState({
      options: [
        ...this.state.options,
        { id: `temp-${(this._tempIDCounter += 1)}`, value: '' },
      ],
    });
  };

  render() {
    const { isLoading, options, title } = this.state;

    return (
      <div className="SettingsDetails">
        <HeaderBar
          title="Edit Setting"
          actions={
            <React.Fragment>
              <Button className="ghost dark" onClick={this.handleCancel}>
                CANCEL
              </Button>
              <Button
                className="primary"
                loading={isLoading}
                onClick={this.handleSubmit}>
                UPDATE
              </Button>
            </React.Fragment>
          }
        />
        <Card title={title} isLoading={isLoading}>
          <ul className="options-list">
            {!!options &&
              options.length > 0 &&
              options.map((opt, i) =>
                opt._destroy ? null : (
                  <li key={i}>
                    <FormInput
                      autoFocus={i === 0}
                      className="option-input"
                      value={opt.value}
                      onChange={e =>
                        this.handleOptChange(opt.id, e.target.value)
                      }
                    />
                    <Icon
                      name="trash alternate"
                      onClick={() => this.handleDelOpt(opt.id)}
                    />
                  </li>
                )
              )}
          </ul>
          <Button
            className="add-new ghost secondary"
            onClick={this.handleAddOption}>
            + ADD NEW OPTION
          </Button>
        </Card>
      </div>
    );
  }
}

const WrappedSettingsDetails = props => (
  <AppContext.Consumer>
    {({ addToast }) => <SettingsDetails {...props} addToast={addToast} />}
  </AppContext.Consumer>
);

export default WrappedSettingsDetails;
