import React from 'react';
import { Transition } from 'react-transition-group';

import './Toast.scss';

export default class Toast extends React.Component {
  componentDidMount() {
    this.timeoutId = window.setTimeout(this.handleDismissToast, 5000);
  }

  componentWillUnmount() {
    this.props.dismiss(this.props.id);
    window.clearTimeout(this.timeoutId);
  }

  handleDismissToast = () => {
    this.props.dismiss(this.props.id);
  };

  render() {
    return (
      <TransitionSlideInOut
        {...this.props}
        isVisible={true}
        inDuration={300}
        outDuration={300}>
        <div className={`Toast -${this.props.type}`}>
          <div className="body">{this.props.children}</div>
          <button
            className="closeButton link"
            onClick={this.handleDismissToast}>
            &times;
          </button>
        </div>
      </TransitionSlideInOut>
    );
  }
}

function TransitionSlideInOut(props) {
  const { children, inDuration = 0, outDuration = 300 } = props;
  const transDuration = 300;
  const defaultStyle = {
    transform: 'translateX(100%)',
    transition: `transform ${transDuration}ms cubic-bezier(1, 0, 0, 1)`,
  };

  const transitionStyles = {
    entering: {
      transform: 'translateX(110%)',
    },
    entered: {
      transform: 'translateX(0)',
    },
    exiting: {
      transform: 'translateX(110%)',
    },
  };

  return (
    <Transition
      {...props}
      appear={true}
      timeout={{ enter: inDuration, exit: outDuration }}>
      {status => {
        const currentStyles = transitionStyles[status];
        return React.cloneElement(children, {
          style: Object.assign({}, defaultStyle, currentStyles),
        });
      }}
    </Transition>
  );
}
