export const jwtDecode = (token) => {
  const base64Url = token.split('.')[1];

  if (!base64Url) {
    return false;
  }

  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const numberWithCommas = (x) => {
  if (!x || (typeof x !== 'string' && typeof x !== 'number')) {
    return '0';
  } else if (isNaN(x)) {
    // if we get a non-number string, then just return that same string
    return x;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/*
 * Replace special characters that tend to break XML
 */
const charsToSwap = [
  { from: '<', to: '&lt;' },
  { from: '>', to: '&gt;' },
  { from: '"', to: '&quot;' },
  { from: "'", to: '&apos;' },
  { from: '&', to: '&amp;' },
];
export const encodeForXML = (str) => {
  let cleanStr = str;
  charsToSwap.forEach((pair) => {
    cleanStr = cleanStr.replace(pair.from, pair.to);
  });
  return cleanStr;
};

export const decodeForXML = (str) => {
  let cleanStr = str;
  charsToSwap.forEach((pair) => {
    cleanStr = cleanStr.replace(pair.to, pair.from);
  });
  return cleanStr;
};

export const shortenLargeNumbers = (num) => {
  if (!num) {
    return '0';
  }

  const getTopNumber = (power) =>
    Math.pow(10, power + 1) - (Math.pow(10, power - 2) / 2 + 1);
  const getBottomNumber = (power) =>
    Math.pow(10, power) - Math.pow(10, power - 3) / 2;

  let res;
  if (0 < num && num <= 9999) {
    // 0-9,999	Do Not Shorten
    res = numberWithCommas(num);
  } else if (1e4 <= num && num <= getTopNumber(4)) {
    // 10,000-99,949	##.#K
    res = `${numberWithCommas((num * Math.pow(1000, -1)).toFixed(1))}K`;
  } else if (getBottomNumber(5) <= num && num <= getTopNumber(5)) {
    // 99,950-999,499	###K
    res = `${numberWithCommas((num * Math.pow(1000, -1)).toFixed(0))}K`;
  } else if (getBottomNumber(6) <= num && num <= getTopNumber(6)) {
    // 999,500-9,994,999	#.##M
    res = `${numberWithCommas((num * Math.pow(1000, -2)).toFixed(2))}M`;
  } else if (getBottomNumber(7) <= num && num <= getTopNumber(7)) {
    // 9,995,000-99,949,999	##.#M
    res = `${numberWithCommas((num * Math.pow(1000, -2)).toFixed(1))}M`;
  } else if (getBottomNumber(8) <= num && num <= getTopNumber(8)) {
    // 99,950,000-999,499,999	###M
    res = `${numberWithCommas((num * Math.pow(1000, -2)).toFixed(0))}M`;
  } else if (getBottomNumber(9) <= num && num <= getTopNumber(9)) {
    // 999,500,000-9,994,999,999	#.##B
    res = `${numberWithCommas((num * Math.pow(1000, -3)).toFixed(2))}B`;
  } else if (getBottomNumber(10) <= num && num <= getTopNumber(10)) {
    // 9,995,000,000-99,949,999,999	##.#B
    res = `${numberWithCommas((num * Math.pow(1000, -3)).toFixed(1))}B`;
  } else if (getBottomNumber(11) <= num && num <= getTopNumber(11)) {
    // 99,950,000,000-999,499,999,999	###B
    res = `${numberWithCommas((num * Math.pow(1000, -3)).toFixed(0))}B`;
  }

  return res;
};

export const shortenLargeNumbersWithPrecision = (
  num,
  maxLength = 1,
  precision = 0
) => {
  if (!num && num !== 0) {
    return 0;
  }

  const numWithCommas = numberWithCommas(num);
  let formatted = numWithCommas;

  // if the number doesn't exceed the maxLength cutoff, dont abbreviate it.
  if (maxLength && String(num).length <= maxLength) {
    return numWithCommas;
  }

  if (formatted.includes(',')) {
    const segmented = formatted.split(',');
    const commaCount = segmented.length - 1;
    const commaToShorthand = ['', 'K', 'M', 'B', 'T', 'Q', 'S'];

    formatted = `${(num * Math.pow(1000, commaCount * -1)).toFixed(precision)}${
      commaToShorthand[commaCount]
    }`;
  }
  return formatted;
};

/**
 * Prettifies bytes to human-readable format
 * Code from https://stackoverflow.com/a/18650828/2939773
 * @param {int} bytes
 * @param {int} [decimalPoints=2] - how many decimal points to round to
 * @param {enum: Bytes, KB, MB, GB, TB, PB, EB, ZB, YB} [units] - if not specified bytes will be converted to largest whole unit
 * @returns {string} Bytes converted into largest available unit by default or by unit specified
 */
export const byteSize = (bytes, decimalPoints, units) => {
  if (!bytes || bytes === 0 || isNaN(bytes)) {
    return units ? `0 ${units}` : '0 Bytes';
  }

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const dm = !decimalPoints && decimalPoints !== 0 ? 2 : decimalPoints;

  let i = Math.floor(Math.log(bytes) / Math.log(k));

  // if we specify units, use that
  if (units) {
    i = sizes.indexOf(units);
  }

  return (bytes / Math.pow(k, i)).toFixed(dm) + ' ' + sizes[i];
};

/*
 * Takes an array of objects, and merges all the objects into one object,
 * order matters, as any duplicates will be replaced
 * @param ArrayOfObjects
 * @returns Object
 */
export const reduceArrayOfObjectsToObject = (array) => {
  return array.reduce((res, obj) => {
    Object.keys(obj).forEach((key) => (res[key] = obj[key]));
    return res;
  }, {});
};

/*
 * This adds items to arrays, then filters empties, then joins them into strings,
 * joined by either spaces, commas, or new lines, but only if needed.
 */
export const formatAddress = ({ address1, address2, city, state, zip }) =>
  [
    address1,
    address2,
    [[city, state].filter((f) => !!f).join(', '), zip]
      .filter((f) => !!f)
      .join(' '),
  ]
    .filter((f) => !!f)
    .join('\n');

/**
 * Takes a number / string and returns a string formatted as (###) ###-####
 * @param phone {number} 0 - 10-digit phone number to format
 * @returns {string} Formatted phone number
 */
export const formatPhone = (phone) => {
  if (!phone || typeof phone !== 'string') {
    return '';
  }
  //normalize string and remove all unnecessary characters
  if (!phone || typeof phone !== 'string') {
    return '';
  }
  phone = phone.replace(/[^\d]/g, '');
  phone = phone.substr(0, 10);

  if (phone.length <= 3) {
    return phone;
  }
  if (phone.length > 3 && phone.length <= 6) {
    return phone.replace(/(\d{3})/, '($1) ');
  }
  if (phone.length > 6 && phone.length <= 9) {
    return phone.replace(/(\d{3})(\d{3})/, '($1) $2-');
  }
  if (phone.length >= 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  //return null;
};

export const formatSSN = (ssn) => {
  if (!ssn || typeof ssn !== 'string') {
    return '';
  }
  //normalize string and remove all unnecessary characters
  ssn = ssn.replace(/[^\d]/g, '');
  ssn = ssn.substr(0, 9);

  if (ssn.length <= 3) {
    return ssn;
  }
  if (ssn.length > 3 && ssn.length <= 5) {
    return ssn.replace(/(\d{3})/, '$1-');
  }
  if (ssn.length > 5 && ssn.length <= 8) {
    return ssn.replace(/(\d{3})(\d{2})/, '$1-$2-');
  }
  if (ssn.length >= 8) {
    return ssn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
  }
  //return null;
};

export const getUTCDate = (dateString = Date.now()) => {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const getISODate = (str) => {
  return str
    ? new Date(str).toISOString().substr(0, 10)
    : new Date().toISOString().substr(0, 10);
};

/**
 * Takes a number that we want rounded according to Paul's requirements in SCP-701.
 * @param {number} num
 * @return {number|string} the rounded number or case 2 will return a string.
 */
export const roundPercent = (num) => {
  if (!num || (typeof num !== 'string' && typeof num !== 'number')) {
    return 0;
  }
  const number = Number(num);
  if (num && !isNaN(number)) {
    // 1. If number is greater then or equal to one...
    if (number >= 1) {
      // round it down
      return Math.floor(number);
    }

    // 2. If number is less than one but greater than zero...
    if (number < 1 && number > 0) {
      // return '< 1' string
      return '< 1';
    }
    // 3. Otherwise, just return the number (because it's presumably 0).
    return 0;
  }
};

export const addCommaAndSpaceIfItExists = (str) => {
  if (!str || typeof str !== 'string') {
    return '';
  } else {
    return `${str}, `;
  }
};

/* Debounce -------
 *  Returns a function, that, as long as it continues to be invoked, will not
 *  be triggered. The function will be called after it stops being called for
 *  N milliseconds. If `immediate` is passed, trigger the function on the
 *  leading edge, instead of the trailing.
 *
 * usage:
 * var myEfficientFn = debounce(function() {
 *   // All the taxing stuff you do
 * }, 250);
 * window.addEventListener('resize', myEfficientFn);
 */
export const debounce = (func, wait, immediate) => {
  let timeout;
  return () => {
    const context = this;
    const args = [func, wait, immediate];
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const leftPad = (string, length, fill = 0) => {
  if (!string || typeof string !== 'string') {
    string = '';
  }
  if (!length || length <= string.length) {
    return string;
  }
  return `${new Array(length - string.length + 1).join(fill)}${string}`;
};

/**
 * Convert a date string to an ISO date string, eg 1/3/2015 -> 2015-01-03
 * @param { strring } str - input date string, can be dash or slash separated
 * @returns { string }
 */
export const convertToISODateString = (str) => {
  if (!str || typeof str !== 'string' || str.length > 10) {
    return '';
  }
  let partsArr = [];
  if (str.indexOf('/') !== -1) {
    partsArr = str.split('/');
  }
  if (str.indexOf('-') !== -1) {
    partsArr = str.split('-');
  }
  partsArr = partsArr.map((p) => leftPad(p, 2));
  // move end item to start. ie '01-12-2018' -> '2018-10-12'
  partsArr.unshift(partsArr.pop());
  return partsArr.join('-');
};

/**
 * Convert an ISO date string to a regular date string, eg 2015-01-03 -> 1/3/2015
 * @param { strring } str - input ISO date string
 * @param { strring } separator - the separator that will be used, defaults '-'
 * @param { boolean } preserveLeadingZeroes - true for 01/02/2015, false for 1/2/2015, defaults true
 * @returns { string }
 */
export const convertFromISODateString = (
  str,
  separator = '-',
  preserveLeadingZeroes = true
) => {
  if (
    !str ||
    typeof str !== 'string' ||
    !str.match(/(\d{4})-(\d{2})-(\d{2})/)
  ) {
    return '';
  }
  let partsArr = str.split('-');
  if (!preserveLeadingZeroes) {
    partsArr = partsArr.map((p) => parseInt(p, 10));
  }
  // move start item to end. ie '2018-10-12' -> '01-12-2018'
  partsArr.push(partsArr.shift());
  return partsArr.join(separator);
};

/**
 * Returns an ellipsized string if the string is longer than the max length provided.
 * @param { string } string
 * @param { integer } maxLength
 * @returns { string }
 */
export const ellipsize = (string, maxLength) => {
  if (!string) {
    return;
  }

  if (string.length > maxLength) {
    return string.slice(0, maxLength) + '...';
  } else {
    return string;
  }
};

/**
 * Searches a filter array for the specified filter key and returns the value
 * @param {Filter[]} dateFilters - array of date filters to search
 * @param {string} key - the key of the filter to return
 * @returns {*} - the value of the found key or false if not found
 */
export const getDateFilter = (dateFilters, key) => {
  if (!dateFilters || !dateFilters.length) {
    return false;
  }

  const result = dateFilters.find((f) => f.hasOwnProperty(key));
  if (result) {
    return result[key];
  } else {
    return false;
  }
};

export const monthLookup = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Converts provided seconds into Hours & Minute, Second format
 * @param {int} seconds - seconds to convert
 * @param {string} format - string containing formats to contain 'DHMS'
 * @returns {string} - the formatted string of the D H M S
 */
export const secondsToDHMS = (seconds, format) => {
  if (seconds) {
    seconds = Number(seconds);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor((seconds % 3600) % 60);

    const dDisplay =
      format.includes('D') && d > 0 ? d + (d === 1 ? ' day ' : ' days ') : '';
    const hDisplay =
      format.includes('H') && h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : '';
    const mDisplay =
      format.includes('M') && m > 0
        ? m + (m === 1 ? ' minute ' : ' minutes ')
        : '';
    const sDisplay =
      format.includes('S') && s > 0
        ? s + (s === 1 ? ' second' : ' seconds')
        : '';

    return (dDisplay + hDisplay + mDisplay + sDisplay).trim();
  }
  return '';
};

/**
 * Converts two numbers to a percent
 * @param {int} dividend - the top number
 * @param {int} divisor - the bottom number - if zero, it returns zero
 * @returns {int} - the pcercent, in number without the symbol
 */
export const getPercent = (dividend, divisor) => {
  if (!divisor > 0) {
    return 0;
  }
  if (dividend === 0) {
    return divisor;
  }
  const result = (dividend / divisor) * 100;
  return Math.round(result, 0);
};

/**
 * Rounds a number to a place, or shows less than if its too small
 * @param {float / string} number - the incoming number, float or string
 * @param {int} decimalPlaces - how many decimal places to display
 * @returns {int} - a rounded number
 * @example
 * formatPrecision('0.002', 1);
 * // => '<0.1'
 */
export const formatPrecision = (number, decimalPlaces) => {
  if (!number || (typeof number !== 'string' && isNaN(number))) {
    number = 0;
  }
  const place = Math.pow(10, decimalPlaces * -1);
  return number >= place
    ? typeof number === 'string'
      ? parseFloat(number).toFixed(decimalPlaces)
      : number.toFixed(decimalPlaces)
    : `<${place}`;
};

/**
 * Accepts a time in the format of '01:00' as 1am, and will return it in minutes
 * @param {string} - time in the 00:00 in 24h format
 * @returns {number} - the time in minutes
 * @example
 * timeToMins('01:15');
 * // => 75
 */
export const timeToMins = (time) => {
  const re = /([0-1]\d|[2][0-4]):[0-5]\d/g;
  if (!time || typeof time !== 'string' || !time.match(re)) {
    return 0;
  }
  let parts = time.split(':');
  const hour = +parts[0];
  const minutes = +parts[1];
  return minutes + hour * 60;
};

/**
 * Accepts a time in minutes and will format it via a format string
 * @param {int} mins - time in minutes
 * @param {string} format - the format desired. options: hh, h, mm, m, a, A
 * @returns {number} - the time formatted as a string
 * @example
 * formatTimeFromMins(75, 'hh:mm a');
 * // => '01:15 am'
 * formatTimeFromMins(65, 'h A, + m');
 * // => '1 AM, + 5'
 */
export const formatTimeFromMins = (mins, format) => {
  if (!format || isNaN(mins) || typeof format !== 'string' || mins > 1440) {
    return '';
  }
  let hour = Math.floor(mins / 60);
  let oHour = hour;
  const minutes = String(mins % 60);
  const minutesPadded = leftPad(minutes, 2);
  let period = 'am';

  /**
   * all the weird cases
   * 00 => 12 am
   * 01 =>  1 am
   * ...
   * 12 => 12 pm
   * 13 =>  1 pm
   * ...
   * 23 => 11 pm
   * 24 => 12 am
   */

  if (oHour === 0) {
    hour = 12;
  } else if (oHour === 12) {
    period = 'pm';
  } else if (oHour > 12 && oHour < 24) {
    period = 'pm';
    hour -= 12;
  } else if (oHour === 24) {
    hour = 12;
  }
  const hourPadded = leftPad(String(hour), 2);

  let res = format;
  res = res.replace(/hh/g, hourPadded);
  res = res.replace(/h/g, hour);
  res = res.replace(/mm/g, minutesPadded);
  res = res.replace(/m/g, minutes);
  res = res.replace(/a/g, period);
  res = res.replace(/A/g, period.toUpperCase());
  return res;
};

export const capitalize = (str) => {
  if (typeof str !== 'string') {
    return '';
  }
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

/**
 * Sort nested data based on a key, only works 1 deep. (should optimize for any depth)
 * @example:
 * sortByKey({data, 'title', 'DESC'});
 *  //=> `data` sorted in decending order based on the 'title' column
 */
export const sortByKey = ({ data, key, direction }) => {
  const d = direction === 'DESC' ? -1 : 1;
  return data.sort((a, b) =>
    a[key] > b[key] ? d : b[key] > a[key] ? d * -1 : 0
  );
};

// TODO: ADD UNIT TESTS FOR THIS!!!!!
/**
 * Function that accepts a string of keys seperated by a period,
 * and an object, and returns the nested value. Returns empty string if not found
 * @param {string} keyString - example: "project.status.id"
 * @param {object} obj - example: {project: {status: {id: 3}}}
 * @example
 * getNestedValue("project.status.id", {project:{status:{id: 3}}})
 * // => 3
 */
export const getNestedValue = (keyString, obj) => {
  if (typeof keyString !== 'string' || keyString.indexOf('.') === -1) {
    return obj && typeof obj[keyString] !== 'undefined' ? obj[keyString] : null;
  }
  const keys = keyString.split('.');
  let result = obj;

  keys.forEach((k) => {
    // be sure not to fail this on falsy values.
    if (result && typeof result[k] !== 'undefined') {
      result = result[k];
    } else {
      // nothing was found at that node
      result = null;
    }
  });
  return result;
};

export const fileNameify = (str) => {
  return str.replace(/([^a-z0-9 -]+)/gi, '').replace(/ /g, '_');
};

export const getListOfMonthYear = (count = 20, fromDate = new Date()) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const year = fromDate.getFullYear();
  const month = fromDate.getMonth() + 1;

  const listOfMonths = [];
  for (let i = count; i >= 0; i--) {
    const fromZero = i - count;
    const offset = month + fromZero;
    const adjMonth = offset > 0 ? offset : (offset % 12) + 12;
    listOfMonths.push(
      `${months[adjMonth - 1]} - ${year -
        Math.floor((Math.abs(fromZero) + (12 - month)) / 12)}`
    );
  }
  return listOfMonths;
};

/**
 * Checks an object for the nested string keys
 * @param str - the nested string to test ie: 'user.name'
 * @param obj - the object to test
 * @returns boolean
 * @example
 * checkNestedKeys('user.name', {user:{name:'bob'}});
 * // => true
 */
export const checkNestedKeys = (str, obj) => {
  if (
    !str ||
    typeof str.split !== 'function' ||
    !obj ||
    typeof obj !== 'object'
  ) {
    return false;
  }
  const keys = str.split('.');
  let layer = obj;
  let res = true;
  keys.forEach((k) => {
    if (layer) {
      if (!Object.keys(layer).includes(k)) {
        res = false;
      }
      layer = layer[k];
    } else {
      res = false;
    }
  });
  return res;
};

/*
 * Check the validity of a nested object
 * @param str - a period delimited string of object keys to try
 * @param obj - the object to try the keys on
 * @example
 * const someObject = {a: {b: {c: {d: [1,2,3,{ e: 'got it!' }]}}}};
 * checkNestedValue('a.b', someObject);
 * // => true
 * checkNestedValue('a.b.c.d.3.e', someObject);
 * // => true
 * checkNestedValue('a.b.c.d.3.e.f', someObject);
 * // => false
 * checkNestedValue('c.d', someObject);
 * // => false
 */
export const checkNestedValue = (str, obj) => {
  if (!str || typeof str !== 'string' || !obj || typeof obj !== 'object') {
    return false;
  }
  const chain = str.split('.');
  const dataKey = chain.shift();
  return chain.length > 0
    ? checkNestedValue(chain.join('.'), obj[dataKey])
    : typeof obj[dataKey] !== 'undefined';
};
