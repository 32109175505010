import React from 'react';

import { HeadOfHousehold, FormField, DropdownSetting } from '../../components/';
import { Clients as ClientsAPI } from '../../api';
import { Modal, Button } from 'semantic-ui-react';

import './AddHHMModal.scss';

export default class AddHHMModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedHHM: null,
      hohRelationshipId: '',
      missingHOHRel: null,
    };
  }

  safeSetState = (newState, callback) => {
    this.setState(newState, callback);
  };

  handleHHMChange = async (hhid) => {
    this.setState({
      selectedHHM: hhid,
    });
    let clientId = hhid;
    let res = await ClientsAPI.get(clientId);
    if (
      res &&
      res.body &&
      res.body.hoh_relationship &&
      res.body.hoh_relationship.id
    ) {
      this.setState({
        hohRelationshipId: res.body.hoh_relationship.id,
        missingHOHRel: false,
      });
    } else {
      this.setState({
        missingHOHRel: true,
      });
    }
  };

  handleFieldChange = (field, value) => {
    this.safeSetState({
      hohRelationshipId: value,
    });
  };

  handleSubmit = () => {
    this.setState({ isLoading: true });
    const { selectedHHM, hohRelationshipId } = this.state;
    this.props.handleAddHHM(selectedHHM, hohRelationshipId);
    this.setState({
      isLoading: false,
      selectedHHM: null,
      missingHOHRel: null,
      hohRelationshipId: null,
    });
  };

  handleClose = () => {
    this.setState(
      {
        selectedHHM: null,
        missingHOHRel: null,
        hohRelationshipId: null,
      },
      () => {
        this.props.handleClose();
      }
    );
  };
  render() {
    const { isVisible, handleGoToNewClient } = this.props;
    const {
      selectedHHM,
      isLoading,
      hohRelationshipId,
      missingHOHRel,
    } = this.state;

    return (
      <Modal
        open={isVisible}
        onClose={this.handleClose}
        size="small"
        className="AddHHMModal">
        <Modal.Header>Add Household Member</Modal.Header>
        <Modal.Content>
          <FormField
            title="SELECT CLIENT"
            value={
              <HeadOfHousehold
                onChange={this.handleHHMChange}
                value={selectedHHM}
                placeholder="Search by Client Name or Case #"
                handleNewClient={handleGoToNewClient}
                width="100%"
                icon={false}
              />
            }
          />
          {missingHOHRel && (
            <DropdownSetting
              className="hoh_rel"
              settingId={5}
              label="HOH RELATIONSHIP"
              value={hohRelationshipId}
              onChange={(e, { value }) =>
                this.handleFieldChange('hohRelationshipId', value)
              }
            />
          )}
          <div className="button-box">
            <Button className="ghost dark" onClick={this.handleClose}>
              CANCEL
            </Button>
            <Button
              loading={isLoading}
              disabled={!selectedHHM || !hohRelationshipId}
              primary
              onClick={this.handleSubmit}>
              ADD MEMBER
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}
