import React from 'react';

import { DataTableSP, HeaderBar } from '../../components/';
import { Icon, Button } from 'semantic-ui-react';
import { format } from 'date-fns';

import { AppContext } from '../../components/AppContext/AppContext';

import {
  ErrorHandler,
  Clients as ClientAPI,
  Users as UserAPI,
} from '../../api';

import { debounce } from '../../util';

import './Clients.scss';

// these are the only values we can use to sort on.
// if the API is throwing a 500 and saying invalid sort column, then
// it is either not in this list, or this list needs to be updated.
const allowedSortColumns = [
  'id',
  'first_name',
  'middle_name',
  'last_name',
  'sex',
  'dob',
  'deleted_at',
  'encrypted_ssn',
  'encrypted_ssn_iv',
  'ssn_bidx',
  'race_id',
  'preferred_lang_id',
  'hoh_relationship_id',
  'bpt_authorized_rep',
  'has_internet',
  'has_primary_care',
  'consent_notes',
  'created_at',
  'updated_at',
  'household_id',
  'is_bpt_employee',
  'is_bpt_employee_family',
  'bpt_family_relationship',
  'bpt_family_member_name',
  'case_number',
  'rid_number',
];

const checkCanSort = (col) => {
  return allowedSortColumns.includes(col);
};

class Clients extends React.Component {
  tableColumns = [
    {
      canSort: checkCanSort('last_name'),
      style: { width: '16%' },
      name: 'Name',
      dataKey: 'last_name',
      description: '',
      formatter: (val, row) => {
        let res = [];
        if (val) {
          res.push(val);
        }
        if (row && row.first_name) {
          res.push(row.first_name);
        }
        return res.join(', ');
      },
    },
    {
      canSort: checkCanSort('ssn'),
      style: { width: '11%' },
      name: 'SSN',
      dataKey: 'ssn',
      description: '',
    },
    {
      canSort: checkCanSort('dob'),
      style: { width: '11%' },
      name: 'DOB',
      dataKey: 'dob',
      description: '',
      formatter: (val) => val && format(val, 'M/D/YYYY'),
    },
    {
      canSort: checkCanSort('case_number'),
      style: { width: '10%' },
      name: 'Case #',
      dataKey: 'case_number',
      description: '',
    },
    {
      canSort: checkCanSort('rid_number'),
      style: { width: '10%' },
      name: 'RID #',
      dataKey: 'rid_number',
      description: '',
    },
    {
      canSort: checkCanSort('household_id'),
      style: { width: '6%' },
      name: 'Household',
      dataKey: 'household_id',
      description: '',
      formatter: (val) => (
        <Button
          className="link"
          onClick={(e) => {
            e.stopPropagation();
            this.handleHouseholdSelect(val);
          }}>
          {val}
        </Button>
      ),
    },
    {
      canSort: checkCanSort('has_pending_apps'),
      name: 'Has Pending Application',
      dataKey: 'has_pending_apps',
      description: '',
      formatter: (val) => val && <Icon className="green" name="check" />,
      style: { textAlign: 'center' },
    },
    {
      canSort: checkCanSort('bpt_authorized_rep'),
      name: 'Brightpoint Authorized Rep',
      dataKey: 'bpt_authorized_rep',
      description: '',
      formatter: (val) => val && <Icon className="green" name="check" />,
      style: { textAlign: 'center' },
    },
  ];
  _isMounted = false;
  pageFilters = {};

  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      isLoadingTable: false,
      tableData: [],
      navigators: [],
      selectedClient: null,
      selectedNavigator: props.user.id,
      searchToken: '',
      pageNumber: 1,
      sortBy: 'last_name',
      sortDirection: 'ASC',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoadingTable: true });
    const { user } = this.props;
    const { searchToken, pageNumber, sortBy, sortDirection } = this.state;

    let res;
    try {
      res = await ClientAPI.all({
        pageNumber,
        search: searchToken,
        sortBy,
        sortDirection,
      });
    } catch (err) {
      ErrorHandler.error(err);
    }

    let navigatorsData = [];
    let navRes;

    try {
      navRes = await UserAPI.all();
    } catch (err) {
      ErrorHandler.error(err);
    }
    navigatorsData = navRes.body;

    let clientData = [];
    let recordCount = 0;
    let updatedPageNumber = pageNumber;
    let updatedSortBy = sortBy;
    let updatedSortDirection = sortDirection;

    const navigators = [
      { text: 'All Clients', value: null },
      { text: 'My Clients', value: user.id },
    ];

    if (res?.body) {
      const body = res.body;
      if (body?.data?.length > 0) {
        clientData = body.data.map((row) => {
          const nav = navigatorsData.find((n) => n.id === row.navigator_id);
          if (nav?.id && !navigators.find((n) => n.value === nav.id)) {
            // if the navigator has not been added, add it!
            // find the navigator by the id inside the navs array
            navigators.push({
              text: [nav.first_name || '', nav.last_name || ''].join(' '),
              value: nav.id,
            });
          }
          return row;
        });
      }
      if (body?.meta) {
        const meta = body.meta;
        recordCount = meta.record_count;
        updatedPageNumber = meta.offset ? meta.offset / 50 + 1 : 1;
        updatedSortBy = meta.sort_by;
        updatedSortDirection = meta.sort_direction;
      }
    }
    this.safeSetState({
      tableData: clientData,
      isLoadingTable: false,
      navigators,
      recordCount,
      pageNumber: updatedPageNumber,
      sortBy: updatedSortBy,
      sortDirection: updatedSortDirection,
    });
  };

  handleFetchPage = ({ pageNumber }) => {
    this.safeSetState({ pageNumber }, () => {
      this._fetch();
    });
  };

  debouncedFetch = debounce(this._fetch, 1000);

  handleSearch = (searchToken) => {
    this.safeSetState({ searchToken, pageNumber: 1 }, () => {
      this.debouncedFetch();
    });
  };
  handleSort = (sortBy, sortDirection) => {
    this.safeSetState({ sortBy, sortDirection, pageNumber: 1 }, () => {
      this._fetch();
    });
  };

  handleTicketSelect = (row) => {
    this.props.history.push(`/clients/${row.id}`);
  };

  handleHouseholdSelect = (household_id) => {
    this.props.history.push(`/households/${household_id}`);
  };

  handleAddClient = () => {
    this.props.history.push(`/clients/add`);
  };

  handleSeletNavigator = (e, { value }) => {
    this.safeSetState({ selectedNavigator: value });
  };

  render() {
    const {
      isLoadingTable,
      tableData,
      sortBy,
      sortDirection,
      pageNumber,
      recordCount,
      searchToken,
    } = this.state;

    return (
      <div className="Clients">
        <HeaderBar
          title="Clients"
          actions={
            <Button className="primary" onClick={this.handleAddClient}>
              NEW CLIENT
            </Button>
          }
        />
        <DataTableSP
          onGoToPage={this.handleFetchPage}
          onSearch={this.handleSearch}
          onSort={this.handleSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          pageNumber={pageNumber}
          recordCount={recordCount}
          searchToken={searchToken}
          tableName="Clients"
          isLoading={isLoadingTable}
          data={tableData}
          searchPlaceholder="Search by Name, Case # or RID #"
          emptyStateMessage="No clients have been created."
          isNotFixed={true}
          onRowClick={this.handleTicketSelect}
          pageFilters={this.pageFilters}
          columns={this.tableColumns}
        />
      </div>
    );
  }
}

const WrappedClients = (props) => (
  <AppContext.Consumer>
    {({ user }) => <Clients {...props} user={user} />}
  </AppContext.Consumer>
);

export default WrappedClients;
