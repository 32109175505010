/*=======================================
 * DataTable with server-side pagination
 *=======================================*/

import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';

import { numberWithCommas, getNestedValue } from '../../util';
import { PAGE_SIZE } from '../../api';

import {
  Card,
  SearchInput,
  HeaderInfoTooltip,
  TablePaginationStateless,
} from '../';

import './DataTableSP.scss';

export class DataTableSP extends Component {
  topOffset = 0;
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();

    this.state = {
      shouldSendAnalytics: false,
      isFixedHeader: false,
    };
  }

  /*
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
    window.addEventListener('resize', this.updateWidths, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.updateWidths);
  }
  */

  handleSort = (dataKey) => {
    let column = dataKey;

    const { sortBy, sortDirection } = this.props;

    let direction = sortDirection;

    if (dataKey) {
      // if we are passing a key, then its cause we clicked a header,
      // so we need to handle direction change if needed
      if (dataKey === sortBy) {
        // if the column passed is the same as the currently sorted column, then switch direction
        direction = direction === 'ASC' ? 'DESC' : 'ASC';
      }
    } else {
      column = sortBy;
    }

    this.props.onSort(column, direction);
  };

  handlePageChange = (page) => {
    this.props.onGoToPage({ pageNumber: page });
  };

  handleQueryChange = (e, value) => {
    const searchToken = typeof value === 'string' ? value.trim() : value;
    this.props.onSearch(searchToken);
  };

  handleRowClick = (row) => {
    if (this.props.onRowClick && typeof this.props.onRowClick === 'function') {
      this.props.onRowClick(row);
    }
  };

  handleScroll = (e) => {
    this.topOffset = 60;
    const rootEl = document.getElementById('root');
    const rootClasses = rootEl && rootEl.classList;
    if (rootClasses.contains('hasNotification')) {
      this.topOffset += 60;
    }
    if (rootClasses.contains('hasStickyHeader')) {
      this.topOffset += 78;
    }
    if (this.tableRef && this.tableRef.current) {
      if (
        e.target.id === 'appContent' &&
        this.getPosition(this.tableRef.current) < this.topOffset - 40
      ) {
        this.handleFixed();
      } else {
        this.handleUnFixed();
      }
    }
  };

  getPosition = (element) => {
    if (!element) {
      return;
    }
    let yPosition = 0;

    while (element) {
      yPosition += element.offsetTop - element.scrollTop + element.clientTop;
      element = element.offsetParent;
    }

    return yPosition;
  };

  updateWidths = () => {
    const widths = [];
    let els = document.getElementsByClassName('first-table-row');
    els = els && els.length > 0 && els[0].children;
    const count = (els && els.length) || 0;
    for (let i = 0; i < count; i++) {
      widths.push(els[i].offsetWidth);
    }
    this.setState({ widths });
  };

  handleFixed = () => {
    this.updateWidths();
    this.setState({ isFixedHeader: true });
  };

  handleUnFixed = () => {
    this.setState({ isFixedHeader: false });
  };

  render() {
    const { widths, isFixedHeader } = this.state;
    const {
      sortBy,
      sortDirection,
      columns,
      columnTotals,
      className,
      isLoading,
      emptyStateMessage,
      title,
      subtitle,
      additionalFilters,
      tableProps,
      rowProps,
      searchPlaceholder,
      isNotFixed,
      data,
      pageNumber,
      searchToken,
    } = this.props;

    // if a callback function was passed as a prop for the onRowClick,
    // then the rows are clickable, and apply any applicable styles/functionality
    const isRowClickable = typeof this.props.onRowClick === 'function';

    return (
      <Card
        className={`${className || ''}${title ? ' has-title' : ''}`}
        isLoading={isLoading}
        title={title}
        subtitle={subtitle}>
        <div className="DataTableSP" ref={this.tableRef}>
          <div className="filterRow">
            {!!additionalFilters && additionalFilters}
            <SearchInput
              autoFocus={true}
              placeholder={searchPlaceholder || 'Search'}
              onSearch={this.handleQueryChange}
              value={searchToken}
            />
          </div>
          {data.length ? (
            <div>
              <Table
                basic="very"
                sortable
                selectable
                fixed={!isNotFixed}
                style={isFixedHeader ? { paddingTop: '50px' } : null}
                {...tableProps}>
                <Table.Header>
                  <Table.Row
                    className="table-header-row"
                    style={
                      isFixedHeader
                        ? {
                            position: 'fixed',
                            top: `${this.topOffset}px`,
                            background: '#fff',
                            minWidth: '1060px',
                          }
                        : null
                    }>
                    {columns.map((d, index) => (
                      <Table.HeaderCell
                        className="table-header-cell"
                        key={index}
                        style={Object.assign(
                          isFixedHeader
                            ? {
                                textAlign: 'left',
                                width:
                                  widths && widths[index]
                                    ? `${widths[index]}px`
                                    : null,
                              }
                            : {
                                textAlign: 'left',
                              },
                          d.style,
                          d.headerStyle,
                          d.canSort && { cursor: 'pointer' }
                        )}
                        sorted={
                          sortBy === d.dataKey
                            ? sortDirection === 'DESC'
                              ? 'descending'
                              : 'ascending'
                            : null
                        }
                        onClick={() => {
                          if (!d.canSort) return;
                          this.handleSort(d.dataKey);
                        }}>
                        <HeaderInfoTooltip content={d.description}>
                          <span className="title">
                            {d.name}{' '}
                            {columnTotals &&
                            columnTotals.hasOwnProperty(d.dataKey)
                              ? `(${numberWithCommas(columnTotals[d.dataKey])})`
                              : null}
                          </span>
                        </HeaderInfoTooltip>
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.map((row, index) => (
                    <Table.Row
                      {...rowProps}
                      key={index}
                      className={index === 0 ? 'first-table-row' : null}
                      style={
                        typeof this.props.rowStyleFormatter === 'function'
                          ? this.props.rowStyleFormatter(row, index)
                          : {}
                      }
                      onClick={() => {
                        this.handleRowClick(row);
                      }}>
                      {columns.map((col, colIndex) => (
                        <Table.Cell
                          {...(col.cellProps || {})}
                          key={colIndex}
                          style={Object.assign(
                            {
                              cursor: isRowClickable ? 'pointer' : 'initial',
                            },
                            col.style,
                            col.cellStyle
                          )}>
                          {typeof col.formatter === 'function'
                            ? col.formatter(
                                getNestedValue(col.dataKey, row),
                                row
                              )
                            : getNestedValue(col.dataKey, row)}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <TablePaginationStateless
                activePage={pageNumber}
                onPageChange={this.handlePageChange}
                itemCount={this.props.recordCount}
                perPage={PAGE_SIZE}
              />
            </div>
          ) : (
            <div
              className="well"
              style={{
                marginLeft: '50%',
                transform: 'translateX(-50%)',
              }}>
              {emptyStateMessage || 'No data found.'}
            </div>
          )}
        </div>
      </Card>
    );
  }
}

export default DataTableSP;
