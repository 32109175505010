import React from 'react';

import Overlay from '../Overlay';
import { Icon, Loader } from 'semantic-ui-react';
import isEqual from 'lodash/isEqual';
import { FormFieldBase } from './FormFieldBase';

import './FormSelect.scss';

class FormSelect extends React.Component {
  constructor(props) {
    super(props);

    const options = props.options || [];

    this.state = {
      selectedOption: this.getSelectedOption(props.value, props.options),
      isDrawerOpen: false,
      focusIndex:
        options.length > 0 && props.value
          ? options.indexOf(o => o.value === props.value)
          : 0,
      filterToken: '',
      filteredOptions: options,
      lastAction: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { options, value } = this.props;
    const selectedOption = this.getSelectedOption(value, options);
    if (( typeof value!=='undefined' && value !== prevProps.value) || !isEqual(prevProps.options, options)) {
      const focusIndex =
        options.length > 0 && value
          ? options.indexOf(o => o.value === value)
          : 0;
      this.setState(
        { selectedOption, focusIndex }, 
        () => { this.filterOptions();
      });
    }
  }

  getSelectedOption = (value, options) => {
    const defaultOption = { text: '', value: '' };
    options = options || this.props.options || [];
    const selectedOpt =
      options.find(opt => opt.value === value) || defaultOption;
    return selectedOpt;
  };

  handleSelect = (e, option, index) => {
    let newState = { filterToken: '' };
    if (index || index === 0) {
      newState.focusIndex = index;
    }

    this.setState(newState);
    this.handleHideDrawer();

    if (typeof this.props.onChange === 'function') {
      this.props.onChange(e, option || { text: '', value: '' });
    }
  };

  toggleDrawerVisibility = () => {
    this.setState(prevState => {
      return { ...prevState, isDrawerOpen: !prevState.isDrawerOpen };
    });
  };

  handleShowDrawer = () => {
    this.setState({ isDrawerOpen: true, filterOptions: this.props.options });
  };
  handleHideDrawer = () => {
    this.setState({ isDrawerOpen: false, filterToken: '' });
  };

  handleKeyDown = e => {
    const {
      focusIndex,
      isDrawerOpen,
      filteredOptions,
      lastAction,
    } = this.state;
    const options = filteredOptions;

    const keymap = {
      TAB: 9,
      ARROW_DOWN: 40,
      ARROW_UP: 38,
      SPACE: 32,
      ENTER: 13,
      DELETE: 46,
      DELETE2: 8,
    };

    const keyCode = e.keyCode;
    const index = focusIndex;
    const last = options.length - 1;

    switch (keyCode) {
      case keymap.TAB:
        if (isDrawerOpen) {
          this.handleHideDrawer();
          this.handleSelect(e, options[focusIndex]);
        }
        break;
      case keymap.DELETE:
        if (isDrawerOpen) {
          this.handleHideDrawer();
          this.handleSelect(e, options[focusIndex]);
        }
        break;
      case keymap.DELETE2:
        if (isDrawerOpen) {
          this.handleHideDrawer();
          this.handleSelect(e, options[focusIndex]);
        }
        break;
      case keymap.ARROW_DOWN:
        if (!isDrawerOpen) {
          this.handleShowDrawer();
        } else {
          if (index < last) {
            this.setState({ focusIndex: index + 1, lastAction: 'movement' });
          } else {
            this.setState({ focusIndex: 0, lastAction: 'movement' });
          }
        }
        break;
      case keymap.ARROW_UP:
        if (!isDrawerOpen) {
          this.handleShowDrawer();
        } else {
          if (index > 0) {
            this.setState({ focusIndex: index - 1, lastAction: 'movement' });
          } else {
            this.setState({ focusIndex: last, lastAction: 'movement' });
          }
        }
        break;
      case keymap.SPACE:
        if (!isDrawerOpen) {
          this.handleShowDrawer();
        } else if (lastAction === 'movement') {
          this.handleSelect(e, options[focusIndex]);
        }
        break;
      case keymap.ENTER:
        if (!isDrawerOpen) {
          this.handleShowDrawer();
        } else {
          this.handleSelect(e, options[focusIndex]);
        }
        break;

      default:
    }
  };

  filterOptions = () => {
    const { filterToken } = this.state;
    const { options } = this.props;
    let filteredOptions = options;
    if (filterToken !== '') {
      filteredOptions = filteredOptions.filter(
        opt =>
          opt.text &&
          typeof opt.text.includes === 'function' &&
          opt.text.toLowerCase().includes(filterToken.toLowerCase())
      );
    }
    this.setState({ filteredOptions });
  };

  handleChange = e => {
    this.setState(
      {
        filterToken: e.target.value,
        isDrawerOpen: true,
        focusIndex: 0,
        lastAction: 'typing',
      },
      () => {
        this.filterOptions();
      }
    );
  };

  render() {
    const {
      placeholder,
      disabled,
      icon,
      className,
      loading,
      autoFocus,
      ...otherProps
    } = this.props;

    const {
      selectedOption,
      isDrawerOpen,
      focusIndex,
      filterToken,
      filteredOptions,
    } = this.state;

    return (
      <FormFieldBase
        disabled={disabled}
        className={`FormSelect ${className || ''}`}
        component={
          <React.Fragment>
            {selectedOption && typeof selectedOption.text !== 'string' ? (
              <div
                className="selected-component"
                onClick={this.handleShowDrawer}>
                {selectedOption.text}
              </div>
            ) : (
              <input
                autoFocus={autoFocus}
                placeholder={
                  (selectedOption && selectedOption.text) || placeholder
                }
                value={filterToken}
                onChange={this.handleChange}
                disabled={disabled}
                onClick={this.handleShowDrawer}
                onKeyDown={this.handleKeyDown}
              />
            )}
            {loading && (
              <Loader
                size="tiny"
                style={{ margin: '8px 7px 0 0' }}
                inline
                active
              />
            )}
            {!loading && (
              <Icon
                onClick={disabled ? () => null : this.toggleDrawerVisibility}
                name={icon || 'caret down'}
              />
            )}
            {isDrawerOpen && !disabled && (
              <React.Fragment>
                <Overlay
                  onClick={this.handleHideDrawer}
                  zIndex={'99'}
                  isVisible={isDrawerOpen}
                />
                <ul className="options-container">
                  {!!filteredOptions &&
                    filteredOptions.map((opt, i) => (
                      <li
                        key={i}
                        onClick={e => this.handleSelect(e, opt, i)}
                        className={`${
                          opt.value === selectedOption.value ? 'selected' : ''
                        } ${i === focusIndex ? 'focused' : ''} ${opt.text === '' ? 'empty' : ''}`}>
                        {opt.text}
                      </li>
                    ))}
                </ul>
              </React.Fragment>
            )}
          </React.Fragment>
        }
        {...otherProps}
      />
    );
  }
}

export { FormSelect };
export default FormSelect;
