import React from 'react';
import { Popup, Table } from 'semantic-ui-react';
import { FormInput } from '../';
import { Applications, ErrorHandler } from '../../api';

import './GlobalCaseSearch.scss';

export default class GlobalCaseSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
      caseData: [],
      isVisible: false,
      searchToken: '',
    };
  }

  _fetch = async () => {
    this.setState({ isLoading: true });

    let res;
    try {
      res = await Applications.all();
    } catch (err) {
      ErrorHandler.error(err);
    }

    let caseData = [];
    if (res && res.body) {
      caseData = res.body;
    }
    const { value } = this.props;
    let searchToken = '';
    if (value && caseData.length > 0) {
      const selected = caseData.find((cd) => cd.case_number === value);
      if (selected) {
        searchToken = selected.case_number;
        this.setState({ searchToken });
      }
    }

    this.setState({ isLoading: false, caseData });
  };

  onShow = () => this.setState({ isVisible: true }, () => this._fetch());
  onHide = () => this.setState({ isVisible: false });

  handleSearchChange = (e) => this.setState({ searchToken: e.target.value });
  handleSelect = (row) => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(row.household_id);
    }
    this.setState(
      {
        value: row.case_number,
        searchToken: row.case_number,
      },
      () => this.onHide()
    );
    // empty the search bar
    this.setState({
      searchToken: '',
    });
  };

  render() {
    const { caseData, isVisible, isLoading, searchToken } = this.state;
    const { handleNewClient, placeholder } = this.props;

    return (
      <Popup
        className="GlobalCaseSearch"
        trigger={
          <FormInput
            placeholder={placeholder || 'Case # Search'}
            onChange={this.handleSearchChange}
            value={searchToken}
            icon="search"
            type="search"
            loading={isLoading}
            style={{ width: '300px' }}
          />
        }
        on="click"
        basic
        position="bottom left"
        open={isVisible}
        onOpen={this.onShow}
        onClose={this.onHide}
        content={
          <AccountsTable
            handleNewClient={handleNewClient}
            handleSelectHOH={this.handleSelect}
            searchToken={searchToken}
            caseData={caseData}
          />
        }
      />
    );
  }
}

const AccountsTable = ({
  handleNewClient,
  searchToken,
  caseData,
  handleSelectHOH,
}) => {
  const filteredData = searchToken
    ? caseData.filter((row) => {
        return [row.case_number || ''].some((val) =>
          String(val)
            .toLowerCase()
            .includes(String(searchToken).toLowerCase())
        );
      })
    : caseData;
  return (
    <div style={{ width: '500px' }}>
      <Table basic="very" selectable singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Household #</Table.HeaderCell>
            <Table.HeaderCell style={{ textAlign: 'right' }}>
              Case #
            </Table.HeaderCell>
            <Table.HeaderCell style={{ textAlign: 'right' }}>
              Application #
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {!!filteredData &&
            filteredData.length > 0 &&
            filteredData.map((row, i) => (
              <Table.Row
                key={i}
                style={{ cursor: 'pointer' }}
                onClick={() => handleSelectHOH(row)}>
                <Table.Cell>{row.household_id}</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }}>
                  {row.case_number}
                </Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }}>
                  {row.application_number}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </div>
  );
};
