import React from 'react';

import { Icon, Loader } from 'semantic-ui-react';

import { FormFieldBase } from './FormFieldBase';

import './FormInput.scss';

export const FormInput = ({
  placeholder,
  value,
  onChange,
  disabled,
  icon,
  loading,
  name,
  id,
  type,
  maxLength,
  minLength,
  size,
  className,
  autoFocus,
  ...otherProps
}) => (
  <FormFieldBase
    component={
      <React.Fragment>
        <input
          autoFocus={autoFocus}
          minLength={minLength}
          size={size}
          maxLength={maxLength}
          type={type}
          id={id}
          name={name}
          placeholder={placeholder}
          value={value === null || typeof value === 'undefined' ? '' : value}
          onChange={e => onChange(e, { value: e.target.value })}
          disabled={disabled}
        />
        {loading && (
          <Loader size="tiny" style={{ margin: '8px 7px 0 0' }} inline active />
        )}
        {!loading &&
          !!icon &&
          (typeof icon === 'string' ? (
            <Icon name={icon} />
          ) : (
            !!icon.name && <IconFromObj {...icon} />
          ))}
      </React.Fragment>
    }
    className={`FormInput ${className || ''}`}
    disabled={disabled}
    {...otherProps}
  />
);

const IconFromObj = ({ loading, ...otherProps }) => {
  return <Icon {...otherProps} />;
};

export default FormInput;
