import React from 'react';

import './HeaderBar.scss';

const HeaderBar = ({ title, actions, actionsLeft }) => (
  <div className="HeaderBar">
    <h1 className="header-bar-title">{title}</h1>
    {!!actionsLeft && <div className="left-actions">{actionsLeft}</div>}
    {!!actions && <div className="right-actions">{actions}</div>}
  </div>
);

export default HeaderBar;
