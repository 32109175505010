import React from 'react';

import './Chip.scss';

const Chip = ({ value, ...otherProps }) => (
  <div {...otherProps} className={`Chip ${value}`}>
    {value}
  </div>
);

export default Chip;
