import React from 'react';

import {
  AppContext,
  HeaderBar,
  FormSelect,
  FormInput,
  FormDate,
  Card,
  DropdownSetting,
  FormTextArea,
  DeleteConfirmModal,
} from '../../components/';
import { Button } from 'semantic-ui-react';
import { format } from 'date-fns';
import { formatSSN, checkNestedValue } from '../../util';
import { ErrorHandler, Clients as ClientsAPI } from '../../api';

import './ClientAdd.scss';

class ClientAdd extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    const clientId = this.props.match.params.client_id;
    const isEditView = window.location.pathname.indexOf('edit') !== -1;

    this.state = {
      isVisibleDeleteModal: false,
      isAdmin: this.props.user && this.props.user.role === 'Admin',
      isEditView,
      clientId,
      dob: '',
      isLoading: false,
      tableData: [],
      navigators: [],
      hasInternet: '',
      ssn: '',
      raceId: '',
      preferredLangId: '',
      hohRelationshipId: '',
      caseNumber: '',
      ridNumber: '',
      firstName: '',
      lastName: '',
      sex: '',
      middleName: '',
      consentNotes: '',
      bptFamilyRelationship: '',
      bptFamilyMemberName: '',
      returnLocation: null,
      returnState: null,
    };
  }

  componentDidMount() {
    if (checkNestedValue('location.state.returnState', this.props)) {
      const prevLocState = this.props.location.state;
      const prevHouseholdId = this.props.location.state.returnState.householdId;
      this.setState({
        prevHouseholdId: prevHouseholdId,
        returnLocation: prevLocState.path,
        returnState: prevLocState.returnState,
      });
    }
    this._isMounted = true;
    const { isEditView, clientId } = this.state;
    if (isEditView && !!clientId) {
      this._fetch(clientId);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async (clientId) => {
    this.safeSetState({ isLoading: true });

    let res;
    try {
      res = await ClientsAPI.get(clientId);
    } catch (err) {
      ErrorHandler.error(err);
    }

    let newState = { isLoading: false };
    if (res && res.body) {
      const data = res.body;
      Object.assign(newState, {
        firstName: data.first_name,
        middleName: data.middle_name,
        lastName: data.last_name,
        sex: data.sex,
        dob: data.dob,
        ssn: data.ssn,
        householdId: data.household_id,
        hasInternet: data.has_internet ? 'Yes' : 'No',
        consentNotes: data.consent_notes,
        bptFamilyRelationship: data.bpt_family_relationship,
        hasPendingApps: !!data.has_pending_apps ? 'Yes' : 'No',
        bptAuthorizedRep: !!data.bpt_authorized_rep ? 'Yes' : 'No',
        isBptEmployee: !!data.is_bpt_employee ? 'Yes' : 'No',
        isBptEmployeeFamily: !!data.is_bpt_employee_family ? 'Yes' : 'No',
        preferredLangId: data.preferred_lang && data.preferred_lang.id,
        raceId: data.race && data.race.id,
        hohRelationshipId: data.hoh_relationship && data.hoh_relationship.id,
        caseNumber: data.case_number,
        ridNumber: data.rid_number,
        bptFamilyMemberName: data.bpt_family_member_name,
      });
    }

    this.safeSetState(newState);
  };

  validateForm = () => {
    const { isBptEmployeeFamily, returnState } = this.state;

    const requiredFields = ['firstName', 'lastName'];

    if (returnState) {
      requiredFields.push('hohRelationshipId');
    }

    if (isBptEmployeeFamily === 'Yes') {
      requiredFields.push('bptFamilyRelationship');
      requiredFields.push('bptFamilyMemberName');
    }
    let isFormValid = true;
    let newState = { isFormValid: false };
    requiredFields.forEach((field) => {
      if (!this.state[field]) {
        newState[`${field}Error`] = 'This field is required';
        isFormValid = false;
        newState['isSubmitting'] = false;
      }
    });
    if (!isFormValid) {
      this.safeSetState(newState);
    }
    return isFormValid;
  };

  handleSubmit = async () => {
    const {
      isEditView,
      returnLocation,
      returnState,
      prevHouseholdId,
    } = this.state;
    let {
      isBptEmployee,
      isBptEmployeeFamily,
      bptAuthorizedRep,
      hasInternet,
    } = this.state;
    if (!this.validateForm()) {
      return;
    }

    await this.safeSetState({
      isSubmitting: true,
    });

    if (isBptEmployee === 'No') {
      await this.safeSetState({
        isBptEmployee: false,
      });
    }

    if (isBptEmployeeFamily === 'No') {
      await this.safeSetState({
        isBptEmployeeFamily: false,
      });
    }

    if (bptAuthorizedRep === 'No') {
      await this.safeSetState({
        bptAuthorizedRep: false,
      });
    }
    if (hasInternet === 'No') {
      await this.safeSetState({
        hasInternet: false,
      });
    }

    if (returnState && returnState['householdId']) {
      await this.safeSetState({
        householdId: returnState['householdId'],
      });
    }
    let res;
    try {
      if (isEditView) {
        res = await ClientsAPI.update({ ...this.state });
      } else {
        res = await ClientsAPI.new({ ...this.state });
      }
    } catch (err) {
      this.props.addToast({
        type: 'error',
        message: 'An error has occurred. Please try your request again.',
      });
    }
    if (res && res.body && res.body.id) {
      const { id } = res.body;
      if (isEditView) {
        this.props.addToast({
          type: 'success',
          message: `Client #${id} has been updated successfully!`,
        });
        this.handleGoToClient(id);
      } else {
        this.props.addToast({
          type: 'success',
          message: `Client #${id} has been added successfully!`,
        });
        if ((returnLocation && returnState) || prevHouseholdId) {
          this.handleGoBackToLink({
            pathname: returnLocation || `/households/${prevHouseholdId}`,
            state: { ...(returnState || {}), headOfHousehold: id },
          });
        } else {
          this.handleGoToClient(id);
        }
      }
    }
    this.safeSetState({
      isSubmitting: false,
    });
  };

  showDeleteModal = () => this.safeSetState({ isVisibleDeleteModal: true });
  hideDeleteModal = () => this.safeSetState({ isVisibleDeleteModal: false });

  handleDelete = async () => {
    const { clientId } = this.state;

    let res;
    try {
      res = await ClientsAPI.delete({ clientId });
    } catch (err) {
      ErrorHandler.error(err);
      this.props.addToast({
        type: 'error',
        message: 'Client could not be deleted at this time. Please try again.',
      });
    }

    if (res) {
      this.props.addToast({
        type: 'success',
        message: `Client was deleted successfully`,
      });
      this.props.history.push(`/clients`);
    }
  };

  handleCancel = () => {
    const {
      isEditView,
      clientId,
      returnLocation,
      returnState,
      prevHouseholdId,
    } = this.state;

    if (isEditView) {
      this.props.history.push(`/clients/${clientId}`);
    } else if ((returnLocation && returnState) || returnState) {
      this.handleGoBackToLink({
        pathname: returnLocation || `/households/${prevHouseholdId}`,
        state: { ...(returnState || {}) },
      });
    } else {
      this.props.history.push('/clients');
    }
  };
  handleGoBackToLink = ({ pathname, state }) => {
    this.props.history.push({ pathname, state });
  };

  handleGoToClient = (clientId) => {
    this.props.history.push(`/clients/${clientId}`);
  };

  handleFieldChange = (field, value) => {
    this.safeSetState({
      [field]: value,
      [`${field}Error`]: '',
    });
    if (
      field === 'isBptEmployeeFamily' &&
      this.state.bptFamilyMemberName &&
      this.state.bptFamilyRelationship.length > 0 &&
      value === 'No'
    ) {
      this.setState({
        bptFamilyMemberName: '',
        bptFamilyRelationship: '',
      });

      const clearFamilyFields = async () => {
        try {
          await ClientsAPI.update({
            ...this.state,
          });
          this.props.addToast({
            type: 'success',
            message: `Client has been updated successfully!`,
          });
        } catch (err) {
          this.props.addToast({
            type: 'error',
            message: 'An error has occurred. Please try your request again.',
          });
        }
      };
      clearFamilyFields();
    }
  };

  handleClearErrors = () => {
    this.safeSetState({
      firstNameError: '',
      lastNameError: '',
      ssnError: '',
      raceIdError: '',
      preferredLangIdError: '',
      hohRelationshipIdError: '',
      bptFamilyRelationshipError: '',
      bptFamilyMemberName: '',
    });
  };
  handleClearForm = () => {
    this.safeSetState({
      firstName: '',
      lastName: '',
      sex: '',
      ssn: '',
      dob: '',
      raceId: '',
      preferredLangId: '',
      hohRelationshipId: '',
      caseNumber: '',
      ridNumber: '',
      middleName: '',
      consentNotes: '',
      bptFamilyRelationship: '',
    });
  };

  render() {
    const {
      //--FormFields
      firstName,
      middleName,
      lastName,
      ssn,
      sex,
      dob,
      raceId,
      hohRelationshipId,
      caseNumber,
      ridNumber,
      preferredLangId,
      bptAuthorizedRep,
      hasInternet,
      isBptEmployee,
      isBptEmployeeFamily,
      bptFamilyRelationship,
      bptFamilyMemberName,
      consentNotes,
      //---FormField errors
      firstNameError,
      lastNameError,
      ssnError,
      bptFamilyRelationshipError,
      bptFamilyMemberNameError,
      raceIdError,
      preferredLangIdError,
      hohRelationshipIdError,
      //--Other Stuff
      isAdmin,
      isVisibleDeleteModal,
      isLoading,
      isEditView,
      returnState,
      clientId,
      isSubmitting,
    } = this.state;

    const sexes = [
      { text: 'M', value: 'M' },
      { text: 'F', value: 'F' },
    ];
    const bools = [
      { text: 'Yes', value: 'Yes' },
      { text: 'No', value: 'No' },
    ];

    return (
      <div className="ClientAdd">
        <DeleteConfirmModal
          isVisible={isVisibleDeleteModal}
          onClose={this.hideDeleteModal}
          onDelete={this.handleDelete}
          message={
            <p>
              <strong>
                You are about to delete the Client {firstName || ''}{' '}
                {lastName || ''}.{' '}
              </strong>
              Please confirm your request.
            </p>
          }
        />
        <HeaderBar
          title={
            isEditView ? `Edit Client ${clientId || ''}` : 'Add New Client'
          }
          actions={
            <React.Fragment>
              <Button className="ghost dark" onClick={this.handleCancel}>
                CANCEL
              </Button>
              <Button
                primary
                loading={isSubmitting}
                onClick={this.handleSubmit}>
                {isEditView ? 'UPDATE' : 'ADD'}
              </Button>
            </React.Fragment>
          }
        />
        <Card title="Client Info" isLoading={isLoading}>
          <div className="form-row">
            <FormInput
              autoFocus
              required
              label="FIRST NAME"
              className="first_name"
              onChange={(e, { value }) =>
                this.handleFieldChange('firstName', value)
              }
              value={firstName}
              error={!!firstNameError}
              errorMessage={firstNameError}
            />
            <FormInput
              label="M.I."
              className="middle_name"
              onChange={(e, { value }) =>
                this.handleFieldChange('middleName', value)
              }
              value={middleName}
            />
            <FormInput
              required
              label="LAST NAME"
              className="last_name"
              onChange={(e, { value }) =>
                this.handleFieldChange('lastName', value)
              }
              value={lastName}
              error={lastNameError}
              errorMessage={lastNameError}
            />
            <FormSelect
              label="SEX"
              className="sex"
              options={sexes}
              onChange={(e, { value }) =>
                this.handleFieldChange('sex', value, e)
              }
              value={sex}
            />
            <FormDate
              alignRight
              className="dob"
              label="DATE OF BIRTH"
              onChange={(e, { value }) => this.handleFieldChange('dob', value)}
              value={dob && format(dob, 'MM/DD/YYYY')}
              placeholder=""
              disabled={false}
            />
            <FormInput
              label="SSN"
              className="ssn"
              onChange={(e, { value }) => this.handleFieldChange('ssn', value)}
              value={formatSSN(ssn) || ''}
              error={ssnError}
              errorMessage={ssnError}
            />
          </div>
          <div className="form-row">
            <DropdownSetting
              className="raceId"
              settingId={3}
              label="RACE"
              value={raceId}
              error={raceIdError}
              errorMessage={raceIdError}
              onChange={(e, { value }) =>
                this.handleFieldChange('raceId', value)
              }
            />
            <DropdownSetting
              className="pref_lang"
              settingId={4}
              label="PREFERRED LANGUAGE"
              value={preferredLangId}
              error={preferredLangIdError}
              errorMessage={preferredLangIdError}
              onChange={(e, { value }) =>
                this.handleFieldChange('preferredLangId', value)
              }
            />
            {returnState && (
              <DropdownSetting
                required
                className="hoh_rel"
                settingId={5}
                label="HOH RELATIONSHIP"
                value={hohRelationshipId}
                error={hohRelationshipIdError}
                errorMessage={hohRelationshipIdError}
                onChange={(e, { value }) =>
                  this.handleFieldChange('hohRelationshipId', value)
                }
              />
            )}
            {!returnState && (
              <DropdownSetting
                className="hoh_rel"
                settingId={5}
                label="HOH RELATIONSHIP"
                value={hohRelationshipId}
                error={hohRelationshipIdError}
                errorMessage={hohRelationshipIdError}
                onChange={(e, { value }) =>
                  this.handleFieldChange('hohRelationshipId', value)
                }
              />
            )}
            <FormSelect
              label="IS BRIGHTPOINT AUTHORIZED REP?"
              className="bptAuthorizedRep"
              options={bools}
              onChange={(e, { value }) =>
                this.handleFieldChange('bptAuthorizedRep', value)
              }
              value={bptAuthorizedRep}
            />
          </div>
          <div
            className={
              isBptEmployeeFamily === 'Yes' ? 'form-row' : 'form-row not-family'
            }>
            <FormSelect
              label="HAS INTERNET ACCESS?"
              className="hasInternet"
              options={bools}
              onChange={(e, { value }) =>
                this.handleFieldChange('hasInternet', value)
              }
              value={hasInternet}
            />
            <FormSelect
              label="IS BRIGHTPOINT EMPLOYEE?"
              className="isBptEmployee"
              options={bools}
              onChange={(e, { value }) =>
                this.handleFieldChange('isBptEmployee', value)
              }
              value={isBptEmployee}
            />
            <FormSelect
              label="IS BRIGHTPOINT EMPLOYEE FAMILY MEMBER?"
              className="isBptEmployeeFamily"
              options={bools}
              onChange={(e, { value }) =>
                this.handleFieldChange('isBptEmployeeFamily', value)
              }
              value={isBptEmployeeFamily}
            />
            {isBptEmployeeFamily === 'Yes' && (
              <>
                <FormInput
                  required
                  label="FAMILY MEMBER NAME"
                  className="bpt_family_member_name"
                  error={bptFamilyMemberNameError}
                  errorMessage={bptFamilyMemberNameError}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('bptFamilyMemberName', value)
                  }
                  value={bptFamilyMemberName}
                />
                <FormInput
                  required
                  label="RELATIONSHIP TO EMPLOYEE"
                  className="bpt_family_relationship"
                  error={bptFamilyRelationshipError}
                  errorMessage={bptFamilyRelationshipError}
                  onChange={(e, { value }) =>
                    this.handleFieldChange('bptFamilyRelationship', value)
                  }
                  value={bptFamilyRelationship}
                />
              </>
            )}
          </div>
          <div className="form-row flex-left">
            <FormInput
              label="CASE NUMBER"
              value={caseNumber}
              onChange={(e, { value }) =>
                this.handleFieldChange('caseNumber', value)
              }
            />
            <FormInput
              label="RID NUMBER"
              value={ridNumber}
              onChange={(e, { value }) =>
                this.handleFieldChange('ridNumber', value)
              }
            />
          </div>
          <div className="form-row">
            <div className="quarter">
              <FormTextArea
                label="CONSENT NOTES"
                className="consent_notes"
                onChange={(e, { value }) =>
                  this.handleFieldChange('consentNotes', value)
                }
                value={consentNotes}
              />
            </div>
          </div>
          {isAdmin && isEditView && (
            <Button
              className="ghost danger delete"
              onClick={this.showDeleteModal}>
              DELETE CLIENT
            </Button>
          )}
        </Card>
      </div>
    );
  }
}

const WrappedClientAdd = (props) => (
  <AppContext.Consumer>
    {({ user, addToast }) => (
      <ClientAdd {...props} user={user} addToast={addToast} />
    )}
  </AppContext.Consumer>
);

export default WrappedClientAdd;
