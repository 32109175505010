import React from 'react';
import { format } from 'date-fns';

import { AppContext } from '../../components/AppContext/AppContext';

import {
  FormField,
  SearchInput,
  FormSelect,
  TableRowOptionPopup,
} from '../../components/';
import { Tab } from 'semantic-ui-react';
import { checkNestedValue } from '../../util';

import { ErrorHandler, Households } from '../../api';

import './TabNotes.scss';

class TabNotes extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      notes: [],
      noteType: 'all',
      noteTypes: [],
      noteSearch: '',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this._fetch();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.shouldFetchNotes !== this.props.shouldFetchNotes) {
      this._fetch();
    }
  }
  safeSetState = (newState, callback) => {
    if (this._isMounted) {
      this.setState(newState, callback);
    }
  };

  _fetch = async () => {
    this.safeSetState({ isLoading: true });
    const { householdId } = this.props;

    let res;
    try {
      res = await Households.notes({ householdId });
    } catch (err) {
      ErrorHandler.error(err);
    }

    let notes = [];
    let noteTypes = [{ text: 'All Note Types', value: 'all' }];
    if (res && res.body && res.body.length > 0) {
      notes = res.body;
      notes.forEach((note) => {
        if (!noteTypes.some((n) => n.value === note.note_type)) {
          noteTypes.push({ text: note.note_type, value: note.note_type });
        }
      });
    }

    this.safeSetState({ notes, noteTypes, isLoading: false });
  };

  handleEditNote = (note) => {
    this.props.handleShowEditModal(note);
  };

  handleDeleteNote = async (note) => {
    const { householdId } = this.props;
    let res;
    try {
      res = await Households.noteRemove({
        noteId: note.id,
        householdId,
      });
    } catch (err) {
      ErrorHandler.error(err);
      let errMsg =
        'There was a problem submitting your changes, please try again.';
      if (checkNestedValue('response.body.household_id.0', err)) {
        let newErr = err.response.body.household_id[0];
        errMsg =
          typeof newErr === 'string'
            ? `${newErr.charAt(0).toUpperCase()}${newErr.substr(1)}`
            : errMsg;
      }
      this.props.addToast({
        type: 'error',
        message: errMsg,
      });
    }

    if (res) {
      this.props.addToast({
        type: 'success',
        message: `${[note.first_name, note.last_name]
          .filter((f) => !!f)
          .join(' ')} has been removed successfully`,
      });
      this._fetch();
    }
  };

  handleNoteTypeChange = (e, opt) => {
    this.safeSetState({ noteType: opt.value });
  };
  handleNoteSearch = (e) => this.safeSetState({ noteSearch: e.target.value });
  render() {
    const { noteTypes, isLoading, notes, noteSearch, noteType } = this.state;

    let filteredNotes = notes;
    if (noteType && noteType !== 'all') {
      filteredNotes = filteredNotes.filter((f) => f.note_type === noteType);
    }
    if (noteSearch) {
      filteredNotes = filteredNotes.filter((f) => {
        let res = false;
        if (f.note_details && typeof f.note_details === 'string') {
          res =
            f.note_details.toLowerCase().indexOf(noteSearch.toLowerCase()) !==
            -1;
        }
        return res;
      });
    }
    return (
      <Tab.Pane attached={false} className="TabNotes" loading={isLoading}>
        {!!notes && notes.length > 0 && (
          <div className="NoteContainer">
            <div className="note-filter-row">
              <FormSelect
                options={noteTypes}
                onChange={this.handleNoteTypeChange}
                value={noteType}
              />
              <SearchInput
                placeholder="Search in notes"
                value={noteSearch}
                onSearch={this.handleNoteSearch}
              />
            </div>
            {filteredNotes.length > 0 &&
              filteredNotes.map((note, k) => (
                <NoteRow
                  key={k}
                  isAdmin={this.props.user && this.props.user.role === 'Admin'}
                  note={note}
                  handleDeleteNote={this.handleDeleteNote}
                  handleEditNote={this.handleEditNote}
                />
              ))}
            {filteredNotes.length === 0 && (
              <p
                style={{ textAlign: 'center', padding: '50px', color: '#666' }}>
                No notes found that match search criteria.
              </p>
            )}
          </div>
        )}
        {(!notes || notes.length === 0) && (
          <p style={{ textAlign: 'center', padding: '50px', color: '#666' }}>
            No notes have been added for this Household.
          </p>
        )}
      </Tab.Pane>
    );
  }
}

const NoteRow = ({
  note,
  handleDeleteNote,
  handleEditNote,
  isAdmin = this.props.user && this.props.user.role === 'Admin',
}) => {
  let rowOptions = [{ label: 'Edit Note', action: () => handleEditNote(note) }];
  if (!!isAdmin) {
    rowOptions.push({
      label: 'Delete Note',
      action: handleDeleteNote,
    });
  }
  return (
    <div className="note-row">
      <div className="note-header">
        <h5>{note.note_type}</h5>
        <TableRowOptionPopup options={rowOptions} row={note} />
      </div>
      <p>{note.note_details}</p>
      <div className="note-meta">
        {!!note.service_date && (
          <FormField
            title="SERVICE DATE"
            value={format(note.service_date, 'M/D/YYYY')}
          />
        )}
        {!!note.time_spent && (
          <FormField title="TIME SPENT" value={`${note.time_spent} min`} />
        )}
        {Array.isArray(note.clients) && (
          <FormField
            title="CLIENT(S)"
            value={note.clients
              .map((client) =>
                [client.first_name, client.last_name]
                  .filter((f) => !!f)
                  .join(' ')
              )
              .join(', ')}
          />
        )}
        {!!note.assist_category && (
          <FormField title="CATEGORY" value={note.assist_category} />
        )}
        {!!note.assist_type && !!note.assist_type.value && (
          <FormField title="TYPE" value={note.assist_type.value} />
        )}
        {(checkNestedValue('navigator.first_name', note) ||
          checkNestedValue('navigator.last_name', note)) && (
          <FormField
            title="NAVIGATOR"
            value={[note.navigator.first_name, note.navigator.last_name]
              .filter((f) => !!f)
              .join(' ')}
          />
        )}
        {!!note.referral_category && !!note.referral_category.value && (
          <FormField
            title="REFERRAL CATEGORY"
            value={note.referral_category.value}
          />
        )}
        {!!note.referral_source && !!note.referral_source.value && (
          <FormField
            title="REFERRAL SOURCE"
            value={note.referral_source.value}
          />
        )}
        {!!note.provider && !!note.provider.value && (
          <FormField title="PROVIDER" value={note.provider.value} />
        )}
        <FormField
          title="PREGNANT"
          value={`${note.pregnant_client ? 'Yes' : 'No'}`}
        />
      </div>
    </div>
  );
};

export default TabNotes;

export const WrappedTabNotes = (props) => (
  <AppContext.Consumer>
    {({ shouldFetchNotes, flipShouldFetchNotes }) => (
      <TabNotes
        {...props}
        shouldFetchNotes={shouldFetchNotes}
        flipShouldFetchNotes={flipShouldFetchNotes}
      />
    )}
  </AppContext.Consumer>
);
