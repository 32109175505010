import React from 'react';

import BaseEntry from '../BaseEntry/BaseEntry';
import FormInput from '../../components/FormInput/FormInput';
import { Users } from '../../api';

import { Message, Button } from 'semantic-ui-react';
import params from '../../qp';
import { AppContext } from '../../components/AppContext/AppContext';

import './FinalizeAccount.scss';

class FinalizeAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordError: '',
      confirm: '',
      confirmError: '',
      formError: '',
      isSubmitting: false,
    };
  }

  validateForm = () => {
    const { password, confirm } = this.state;

    let update = false;
    let newState = {};
    if (!password) {
      newState.passwordError = 'New password is required';
      newState.formError =
        'Password reset failed. Please check your entries and try again.';
      update = true;
    }
    if (!confirm) {
      newState.confirmError = 'Confirm new password is required';
      newState.formError =
        'Password reset failed. Please check your entries and try again.';
      update = true;
    }
    if (confirm !== password) {
      newState.confirmError = 'Both passwords must match';
      newState.formError =
        'Password reset failed. Please check your entries and try again.';
      update = true;
    }
    if (update) {
      this.setState(newState);
    }
    return !update;
  };

  handleChangePassword = e =>
    this.setState({ password: e.target.value, passwordError: '' });
  handleChangeConfirm = e =>
    this.setState({ confirm: e.target.value, confirmError: '' });

  handleSubmit = async e => {
    const code = params.get('code');
    e.preventDefault();
    if (!this.validateForm()) {
      return;
    }
    const { password } = this.state;
    this.setState({ isSubmitting: true });

    Users.finalizeAccount({ code, password })
      .then(res => {
        this.props.addToast({
          type: 'success',
          message: 'You account setup has been completed successfully!',
        });
        this.props.history.push('/login');
      })
      .catch(err => {
        const errMsg =
          (err.response && err.response.body && err.response.body.error) ||
          'We were unable to finalize the account.';
        this.props.addToast({
          type: 'error',
          message:
            'Your account setup could not be finalized at this time. Please try again.',
        });
        this.setState({
          formError: errMsg,
        });
      });

    this.setState({ isSubmitting: false });
  };

  handleCancel = () => {
    this.props.history.push('/login');
  };

  render() {
    const {
      password,
      passwordError,
      confirm,
      confirmError,
      formError,
      isSubmitting,
    } = this.state;
    return (
      <BaseEntry>
        <div className="FinalizeAccount">
          {!!formError && <Message negative>{formError}</Message>}
          <h4 className="heading">Complete Account Setup</h4>
          <p className="description">
            To complete your account setup, please enter and confirm your
            desired password below.
          </p>
          <FormInput
            autoFocus
            label="PASSWORD"
            error={!!passwordError}
            errorMessage={passwordError}
            value={password}
            onChange={this.handleChangePassword}
            type="password"
          />
          <FormInput
            label="CONFIRM PASSWORD"
            error={!!confirmError}
            errorMessage={confirmError}
            value={confirm}
            onChange={this.handleChangeConfirm}
            type="password"
          />
          <div className="form-actions">
            <Button primary loading={isSubmitting} onClick={this.handleSubmit}>
              COMPLETE ACCOUNT SETUP
            </Button>
            <Button className="ghost dark" onClick={this.handleCancel}>
              CANCEL
            </Button>
          </div>
        </div>
      </BaseEntry>
    );
  }
}

const WrappedFinalizeAccount = props => (
  <AppContext.Consumer>
    {({ addToast }) => <FinalizeAccount {...props} addToast={addToast} />}
  </AppContext.Consumer>
);
export default WrappedFinalizeAccount;
