/**
 *
 * TODO:
 * 1. we might want to have a different 'emptyStateTextAfterSearching'
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import isEqual from 'lodash/isEqual';

import { numberWithCommas, getNestedValue, checkNestedKeys } from '../../util';

import { ErrorHandler } from '../../api';

import { Card, SearchInput, HeaderInfoTooltip, TablePagination } from '../';

import './DataTable.scss';

const PAGE_SIZE = 50;

export class DataTable extends Component {
  topOffset = 0;
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();

    this.state = {
      needToSort: true,
      originalData: props.data || [],
      searchQuery: '',
      searchedData: [],
      sortedData: [],
      pageNumber: 1,
      sortColumn: props.defaultSort,
      sortDirection: props.defaultSortDirection,
      shouldSendAnalytics: false,
      isFixedHeader: false,
    };
  }

  componentDidMount() {
    this.filterData();
    window.addEventListener('scroll', this.handleScroll, true);
    window.addEventListener('resize', this.updateWidths, true);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.updateWidths);
  }
  componentDidUpdate(prevProps, prevState) {
    const { data } = this.props;
    const prevData = prevProps.data;
    if (!isEqual(data, prevData)) {
      if (!data || !data.length > 0) {
        this.setState({
          originalData: [],
          searchedData: [],
          sortedData: [],
          displayData: null,
        });
      } else {
        this.setState({ originalData: data, needToSort: true }, () => {
          this.filterData();
        });
      }
    }
  }

  validateImportantProps = () => {
    const { searchColumns, columns, data, defaultSort } = this.props;
    if (!data || !data.length) {
      return;
    }

    const sampleRow = data && data.length && data.length > 0 && data[0];

    // Validate column dataKeys
    columns.forEach(col => {
      if (!sampleRow || !checkNestedKeys(col.dataKey, sampleRow)) {
        ErrorHandler.bug(
          `ERROR::DataTable::dataKey ${col.dataKey} is not valid!`
        );
      }
    });

    // Validate SearchColumns
    if (searchColumns && searchColumns.length > 0) {
      searchColumns.forEach(searchCol => {
        if (!checkNestedKeys(searchCol, sampleRow)) {
          ErrorHandler.bug(
            `ERROR::DataTable::SearchColumn ${searchCol} is not valid!`
          );
        }
      });
    }

    // Validate defaultSort column
    if (!columns.find(col => col.dataKey === defaultSort)) {
      ErrorHandler.bug(
        `ERROR::DataTable::defaultSort ${defaultSort} is not valid!`
      );
    }
  };

  //   --initial load--
  //   data ==>> searchFilter ==>> sort ==>> paginate ==>> display
  //   --on search--
  //   data ==>> searchFilter ==>> sort ==>> paginate ==>> display
  //   --on sort--
  //   searchData ==>> sort ==>> paginate ==>> display
  //   --on page--
  //   sortedData ==>> paginate ==>> display

  filterData = () => {
    this.validateImportantProps();

    const { searchColumns } = this.props;
    const { searchQuery, originalData } = this.state;

    // if there are no search columns, or if there is no query, return all originalData
    if (!searchColumns || !searchColumns.length > 0 || !searchQuery) {
      if (!this.state.needToSort) {
        this.setState({ searchedData: originalData, sortedData: originalData });
      } else {
        this.setState({ searchedData: originalData }, () => {
          this.handleSort();
        });
      }
      return;
    }

    let searchedData = originalData.filter(row => {
      let keep = false;
      for (let col of searchColumns) {
        const val = getNestedValue(col, row);
        if (val && val.toLowerCase().includes(searchQuery.toLowerCase())) {
          keep = true;
          break;
        }
      }
      return keep;
    });

    if (!this.state.needToSort) {
      this.setState({ searchedData, sortedData: searchedData });
    } else {
      this.setState({ searchedData }, () => {
        this.handleSort();
      });
    }
  };

  prepFieldForSort = (sortType, val, column) => {
    let aVal = val;
    switch (sortType) {
      case 'number':
        if (typeof aVal === 'string') {
          aVal = parseFloat(aVal.replace(/[^\d.-]/g, ''));
        }
        if (!aVal) {
          aVal = 0;
        }
        break;
      case 'boolean':
        // should just work
        break;
      case 'string':
        aVal = typeof aVal === 'string' ? aVal.toLowerCase() : aVal;
        break;
      case 'date':
      case 'time':
        aVal = new Date(aVal).getTime();
        break;
      case 'array':
        // if we try to sort a column of arrays, just join the arrays into a big string, and string compare...
        // lets hope they have the values in the order they want. may consider sorting the strings first??
        aVal = aVal.join('');
        break;
      case 'object':
        // I don't know why anyone would try to sort by objects,
        // but if they do, we will just take the values in order
        // mash them into a string, then compare the strings. lol
        aVal = Object.values(aVal).join('');
        ErrorHandler.bug(
          'Attempting to sort a column of objects... are you sure you wired the data up right?'
        );
        break;
      default:
        ErrorHandler.bug(
          `ERROR::DataTable::Invalid type [${sortType}] provided for datatable column [${column}], sample value: ${JSON.stringify(
            aVal
          )}`
        );
    }
    return aVal;
  };

  handleSort = dataKey => {
    let column = dataKey;

    const { columns, secondarySort } = this.props;
    const { sortColumn, sortDirection, searchedData } = this.state;

    let direction = sortDirection;

    if (dataKey) {
      // if we are passing a key, then its cause we clicked a header,
      // so we need to handle direction change if needed
      if (dataKey === sortColumn) {
        // if the column passed is the same as the currently sorted column, then switch direction
        direction = direction === 'ASC' ? 'DESC' : 'ASC';
        this.setState({ sortDirection: direction });
      }
      this.setState({ sortColumn: dataKey });
    } else {
      column = sortColumn;
    }

    // find the item from the columns so that we can find its 'type'
    // also maybe we should allow the user to pass a sort function
    // on second thought, no, lets just keep expanding the sort types. we can have nice custom ones.

    // if dataKey is undefined, then we are doing the default sort, so use that as the key
    const sortItem = columns.find(col => col.dataKey === column);
    let sortType = sortItem && sortItem.type;

    let sortedData = [];

    //gotchas  null values
    // mixed types?
    sortedData = searchedData.sort((a, b) => {
      let aVal = getNestedValue(column, a) || '';
      let bVal = getNestedValue(column, b) || '';
      let cVal = '';
      let dVal = '';

      if (secondarySort) {
        cVal = getNestedValue(secondarySort, a) || '';
        dVal = getNestedValue(secondarySort, b) || '';
      }

      // if a column sort type was not provided, try to get it manually
      sortType = sortType || typeof aVal;
      if (sortType === 'object') {
        sortType = Array.isArray(aVal) ? 'array' : 'object';
      }

      aVal = this.prepFieldForSort(sortType, aVal, column);
      bVal = this.prepFieldForSort(sortType, bVal, column);
      if (secondarySort) {
        let secondarySortType = typeof cVal;
        if (secondarySortType === 'object') {
          secondarySortType = Array.isArray(aVal) ? 'array' : 'object';
        }
        cVal = this.prepFieldForSort(secondarySortType, cVal, column);
        dVal = this.prepFieldForSort(secondarySortType, dVal, column);
      }

      const compareVals = (a, b) => {
        const c = cVal;
        const d = dVal;
        return secondarySort
          ? a < b
            ? 1
            : a > b
            ? -1
            : c < d
            ? 1
            : c > d
            ? -1
            : 0
          : a < b
          ? 1
          : a > b
          ? -1
          : 0;
      };

      return direction === 'DESC'
        ? compareVals(aVal, bVal)
        : compareVals(bVal, aVal);
    });

    this.setState(
      {
        sortedData,
        needToSort: false,
        shouldSendAnalytics: false,
      },
      () => {
        this.updateWidths();
      }
    );
  };

  handleColumnHeaderClick = dataKey => {
    this.setState({ shouldSendAnalytics: true }, () => {
      this.handleSort(dataKey);
    });
  };

  handlePageChange = page => {
    this.setState({ pageNumber: page });
  };

  handleQueryChange = (e, value) => {
    const searchQuery = typeof value === 'string' ? value.trim() : value;
    this.setState({ searchQuery }, this.filterData);
  };

  handleRowClick = row => {
    if (this.props.onRowClick && typeof this.props.onRowClick === 'function') {
      this.props.onRowClick(row);
    }
  };

  handleScroll = e => {
    this.topOffset = 60;
    const rootEl = document.getElementById('root');
    const rootClasses = rootEl && rootEl.classList;
    if (rootClasses.contains('hasNotification')) {
      this.topOffset += 60;
    }
    if (rootClasses.contains('hasStickyHeader')) {
      this.topOffset += 78;
    }
    if (this.tableRef && this.tableRef.current) {
      if (
        e.target.id === 'appContent' &&
        this.getPosition(this.tableRef.current) < this.topOffset - 40
      ) {
        this.handleFixed();
      } else {
        this.handleUnFixed();
      }
    }
  };

  getPosition = element => {
    if (!element) {
      return;
    }
    let yPosition = 0;

    while (element) {
      yPosition += element.offsetTop - element.scrollTop + element.clientTop;
      element = element.offsetParent;
    }

    return yPosition;
  };

  updateWidths = () => {
    const widths = [];
    let els = document.getElementsByClassName('first-table-row');
    els = els && els.length > 0 && els[0].children;
    const count = (els && els.length) || 0;
    for (let i = 0; i < count; i++) {
      widths.push(els[i].offsetWidth);
    }
    this.setState({ widths });
  };

  handleFixed = () => {
    this.updateWidths();
    this.setState({ isFixedHeader: true });
  };

  handleUnFixed = () => {
    this.setState({ isFixedHeader: false });
  };

  render() {
    const {
      sortedData,
      originalData,
      pageNumber,
      sortColumn,
      sortDirection,
      searchQuery,
      widths,
      isFixedHeader,
    } = this.state;
    const {
      columns,
      columnTotals,
      className,
      isLoading,
      emptyStateMessage,
      title,
      subtitle,
      additionalFilters,
      searchColumns,
      tableProps,
      rowProps,
      searchPlaceholder,
      isNotFixed,
    } = this.props;
    let displayData = [];
    let itemCount = 0;
    // let pageCount = 0;
    if (sortedData && sortedData.length > 0) {
      const startIndex = PAGE_SIZE * (pageNumber - 1);
      const endIndex = PAGE_SIZE * pageNumber;
      displayData =
        (sortedData &&
          sortedData.length > 0 &&
          sortedData.slice(startIndex, endIndex)) ||
        [];
      itemCount = sortedData.length;
      // pageCount = Math.ceil(sortedData.length / PAGE_SIZE);
    }

    // if a callback function was passed as a prop for the onRowClick,
    // then the rows are clickable, and apply any applicable styles/functionality
    const isRowClickable = typeof this.props.onRowClick === 'function';

    return (
      <Card
        className={`${className || ''}${title ? ' has-title' : ''}`}
        isLoading={isLoading}
        title={title}
        subtitle={subtitle}>
        <div className="DataTable" ref={this.tableRef}>
          {(!!additionalFilters ||
            (originalData && originalData.length > 0)) && (
            <div className="filterRow">
              {additionalFilters}
              {!!searchColumns && searchColumns.length > 0 && (
                <SearchInput
                  placeholder={searchPlaceholder || 'Search'}
                  onSearch={this.handleQueryChange}
                  value={searchQuery}
                />
              )}
            </div>
          )}
          {displayData.length ? (
            <div>
              <Table
                basic="very"
                sortable
                selectable
                fixed={!isNotFixed}
                style={isFixedHeader ? { paddingTop: '50px' } : null}
                {...tableProps}>
                <Table.Header>
                  <Table.Row
                    className="table-header-row"
                    style={
                      isFixedHeader
                        ? {
                            position: 'fixed',
                            top: `${this.topOffset}px`,
                            background: '#fff',
                            minWidth: '1060px',
                          }
                        : null
                    }>
                    {columns.map((d, index) => (
                      <Table.HeaderCell
                        className="table-header-cell"
                        key={index}
                        style={Object.assign(
                          isFixedHeader
                            ? {
                                textAlign: 'left',
                                width:
                                  widths && widths[index]
                                    ? `${widths[index]}px`
                                    : null,
                              }
                            : {
                                textAlign: 'left',
                              },
                          d.style,
                          d.headerStyle
                        )}
                        sorted={
                          sortColumn === d.dataKey
                            ? sortDirection === 'DESC'
                              ? 'descending'
                              : 'ascending'
                            : null
                        }
                        onClick={() => {
                          this.handleColumnHeaderClick(d.dataKey);
                        }}>
                        <HeaderInfoTooltip content={d.description}>
                          <span className="title">
                            {d.name}{' '}
                            {columnTotals &&
                            columnTotals.hasOwnProperty(d.dataKey)
                              ? `(${numberWithCommas(columnTotals[d.dataKey])})`
                              : null}
                          </span>
                        </HeaderInfoTooltip>
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {displayData.map((row, index) => (
                    <Table.Row
                      {...rowProps}
                      key={index}
                      className={index === 0 ? 'first-table-row' : null}
                      style={
                        typeof this.props.rowStyleFormatter === 'function'
                          ? this.props.rowStyleFormatter(row, index)
                          : {}
                      }
                      onClick={() => {
                        this.handleRowClick(row);
                      }}>
                      {columns.map((col, colIndex) => (
                        <Table.Cell
                          {...col.cellProps || {}}
                          key={colIndex}
                          style={Object.assign(
                            {
                              cursor: isRowClickable ? 'pointer' : 'initial',
                            },
                            col.style,
                            col.cellStyle
                          )}>
                          {typeof col.formatter === 'function'
                            ? col.formatter(
                                getNestedValue(col.dataKey, row),
                                row
                              )
                            : getNestedValue(col.dataKey, row)}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <TablePagination
                onPageChange={this.handlePageChange}
                itemCount={itemCount}
                perPage={PAGE_SIZE}
              />
            </div>
          ) : (
            <div
              className="well"
              style={{
                marginLeft: '50%',
                transform: 'translateX(-50%)',
              }}>
              {emptyStateMessage || 'No data found.'}
            </div>
          )}
        </div>
      </Card>
    );
  }
}

export default DataTable;

DataTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  defaultSort: PropTypes.string.isRequired,
  defaultSortDirection: PropTypes.oneOf(['ASC', 'DESC']).isRequired,
  columns: PropTypes.array.isRequired,
  tableName: PropTypes.string.isRequired,
  // not required
  searchPlaceholder: PropTypes.string,
  secondarySort: PropTypes.string,
  searchColumns: PropTypes.array,
  rowStyleFormatter: PropTypes.func,
  data: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  headerStyle: PropTypes.object,
  tableProps: PropTypes.object,
  rowProps: PropTypes.object,
  cellStyle: PropTypes.object,
  pageFilters: PropTypes.object,
  isEmpty: PropTypes.bool,
  emptyStateMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  // callback function
  onRowClick: PropTypes.func,
  additionalFilters: PropTypes.element,
};
